import { Box, Input, InputProps, TableCell, TextField, TextFieldProps, styled, Link, Button, ButtonTypeMap } from '@mui/material';
import { DefaultComponentProps } from '@mui/material/OverridableComponent';
import React, { useCallback, useEffect, useState } from 'react';

const NormalButton = styled(Button)((props) => ({
  color: props.theme.palette.common.white,
  textWrap: 'pretty'
}));

export const DebouncedButton: React.FC<DefaultComponentProps<ButtonTypeMap>> = (props) => {
  const [isClicked, setIsClicked] = useState(false);

  useEffect(() => {
    if (isClicked) {
      const timeout = setTimeout(() => setIsClicked(false), 75);
      return () => clearTimeout(timeout);
    }
  }, [isClicked]);

  const debouncedOnClick = useCallback(
    (event: React.MouseEvent<HTMLButtonElement>) => {
      if (!isClicked) {
        if (props.onClick) {
          props.onClick(event);
        }
        setIsClicked(true);
      }
    },
    [props.onClick, isClicked]
  );

  return <NormalButton variant='contained' {...props} disabled={isClicked ? true : props.disabled} onClick={debouncedOnClick} />;
};

export const StyledButton: React.FC<DefaultComponentProps<ButtonTypeMap>> = (props) => {
  return <NormalButton variant='contained' {...props} />;
};

export const CenteredBox = styled(Box)(({ theme }) => ({
  marginRight: 0,
  marginLeft: 0,
  height: '100vh',
  width: '100vw',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center'
}));

export const StyledTableHeaderCell = styled(TableCell)(({ theme }) => ({
  background: theme.palette.lightBg,
  color: '#9e9e9e',
  padding: '0px 5px'
}));

const TsStyledInput = styled(TextField)(({ theme }) => ({
  backgroundColor: theme.palette.lightBg,
  '&:-webkit-autofill': {
    WebkitBoxShadow: '0 0 0 1000px white inset'
  },
  width: '100%',
  fontSize: '1em'
}));

export const StyledInput: React.FC<TextFieldProps> = (props) => {
  return <TsStyledInput variant='outlined' {...props} />;
};

export const StyledLink = styled(Link)(({ theme }) => ({
  color: theme.palette.blue,
  textDecorationColor: theme.palette.blue,
  '&:hover': {
    color: theme.palette.info.dark
  }
}));

export const LightCardWrapper = styled(Box)(({ theme }) => ({
  display: 'inline-flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
  padding: '1em',
  paddingLeft: '2em',
  paddingRight: '2em',
  borderRadius: '1em',
  backgroundColor: theme.palette.lightBg
}));

export const LargeLightCardWrapper = styled(Box)(({ theme }) => ({
  display: 'inline-flex',
  flexDirection: 'column',
  padding: '1em',
  paddingLeft: '2em',
  paddingRight: '2em',
  borderRadius: '1em',
  backgroundColor: theme.palette.lightBg
}));
