import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useCqg } from './CqgContext';
import { DomRealTime, DomRealTimeType } from '@models/domRealTime';
import { OrderModel, OrderStatus, OrderType, PositionModel, SymbolMetadata } from '@api/userApi';
import { useOrders } from './OrdersContext';
import { roundToNearestTick } from '@/helpers/decimalHelper';
export type CqgCallbackId = number;

interface IDomContext {
  bids: Map<number, number>;
  asks: Map<number, number>;
  volumes: Map<number, number>;
  position?: PositionModel;
  lastPrice?: number;
  bestAsk?: number;
  bestBid?: number;
  high: number;
  low: number;
  orders: Map<number, OrderModel[]>;
  hasBidOrders: boolean;
  hasAskOrders: boolean;
  positionEntryPrice: number;
  maxVolume: number;
  maxAsk: number;
  maxBid: number;
  contractCost: number;
  tick: number;
  contract: SymbolMetadata;
  orderList: OrderModel[];
  orderAmount: number;
  maxDomPrices: number;
  centeredPrice?: number;
  autoCenter: boolean;
  highlightGroup: string | null;
  setAutoCenter: (value: boolean) => void;
  setHighlightGroup: (value: string | null) => void;
  recenter: () => void;
  updateOrderAmount: (amount: number) => void;
  linkedOrderMap: Map<number, string>;
}

function truncate(n, digits) {
  var step = Math.pow(10, digits || 0);
  var temp = Math.trunc(step * n);

  return temp / step;
}

export const DomContext = React.createContext<IDomContext>({} as any);
export const useDom = () => React.useContext<IDomContext>(DomContext);

interface DomContextProviderInterface {
  children: React.ReactNode;
  contract: SymbolMetadata;
  initialOrderSize: number;
}

interface IDomData {
  bids: Map<number, number>;
  asks: Map<number, number>;
  volumes: Map<number, number>;
  last: number | null;
  high: number | null;
  low: number | null;
  bestBid: number | null;
  bestAsk: number | null;
}

function DomContextProvider({ children, contract, initialOrderSize }: DomContextProviderInterface) {
  const { subscribeDom, unsubscribeDom } = useCqg();
  const { orders, activePositions, linkedOrders } = useOrders();
  const [orderAmount, setOrderAmount] = useState(initialOrderSize || 1);
  const [autoCenter, setAutoCenter] = useState(false);
  const [maxDomPrices, setMaxDomPrices] = useState(1000);
  const [centeredPrice, setCenteredPrice] = useState<number | null>(null);
  const lastPriceRef = useRef<number | null>(null);
  const [highlightGroup, setHighlightGroup] = useState<string | null>(null);

  const [domData, setDomData] = useState<IDomData>({
    bids: new Map(),
    asks: new Map(),
    volumes: new Map(),
    last: null,
    high: null,
    low: null,
    bestBid: null,
    bestAsk: null
  });

  const updateOrderAmount = useCallback(
    (amount: number) => {
      amount = Math.max(1, amount);
      setOrderAmount(amount);
    },
    [setOrderAmount]
  );

  useEffect(() => {
    if (!autoCenter) {
      const data = lastPriceRef.current;
      if (data !== null) {
        setCenteredPrice(data);
      }
    }
  }, [autoCenter, contract]);

  const recenter = useCallback(() => {
    const data = lastPriceRef.current;
    if (data !== null) {
      setCenteredPrice(data);
    }
  }, [contract]);

  const position = useMemo(() => {
    return activePositions.find((x) => x.symbolId === contract.symbol && x.positionSize != 0);
  }, [activePositions, contract]);

  const positionEntryPrice = useMemo(() => {
    if (!position) return 0;
    return roundToNearestTick(position.averagePrice, contract);
  }, [position?.averagePrice, contract]);


  const { orderMap, hasBidOrders, hasAskOrders, orderList, linkedOrderMap } = useMemo(() => {
    const m = new Map<number, OrderModel[]>();
    let hasAskOrders = false;
    let hasBidOrders = false;
    const orderList = [];
    orders.forEach((o) => {
      if (
        (o.type == OrderType.Limit || o.type == OrderType.Stop || o.type == OrderType.StopLimit || o.type == OrderType.TrailingStop) &&
        o.status == OrderStatus.Open &&
        o.symbolId == contract.symbol
      ) {
        orderList.push(o);
        if (o.positionSize > 0) {
          hasBidOrders = true;
        } else {
          hasAskOrders = true;
        }
        if (o.type == OrderType.StopLimit || o.type == OrderType.Limit) {
          if (m.has(o.limitPrice)) {
            m.get(o.limitPrice).push(o);
          } else {
            m.set(o.limitPrice, [o]);
          }
        } else if (o.type == OrderType.Stop || o.type == OrderType.TrailingStop) {
          if (m.has(o.stopPrice)) {
            m.get(o.stopPrice).push(o);
          } else {
            m.set(o.stopPrice, [o]);
          }
        }
      }
    });
    const linkedOrderMap = new Map<number, string>();
    linkedOrders.forEach((o) => {
      o.linkedOrderIds.forEach((id) => {
        linkedOrderMap.set(id, o.groupId);
      });
    });
    return { orderMap: m, hasAskOrders, hasBidOrders, orderList,  linkedOrderMap };
  }, [orders, contract, linkedOrders]);

  useEffect(() => {
    setDomData({ bids: new Map(), asks: new Map(), volumes: new Map(), last: null, high: null, low: null, bestBid: null, bestAsk: null });
    const id = subscribeDom(contract.symbol, (res: DomRealTime[]) => {
      setDomData((prev) => {
        for (const domData of res) {
          if (!domData) continue;
          switch (domData.type) {
            case DomRealTimeType.Reset:
              prev.bids.clear();
              prev.asks.clear();
              prev.volumes.clear();
              break;
            case DomRealTimeType.BestAsk:
              prev.asks.clear();
              prev.asks.set(domData.price, domData.volume);
              prev.bestAsk = domData.price;
              break;
            case DomRealTimeType.BestBid:
              prev.bids.clear();
              prev.bids.set(domData.price, domData.volume);
              prev.bestBid = domData.price;
              break;
            case DomRealTimeType.Level2BestAsk:
              prev.bestAsk = domData.price;
              break;
            case DomRealTimeType.Level2BestBid:
              prev.bestBid = domData.price;
              break;
            case DomRealTimeType.Ask:
              {
                if (domData.volume == 0) {
                  prev.asks.delete(domData.price);
                } else {
                  prev.asks.set(domData.price, domData.volume);
                }
              }
              break;
            case DomRealTimeType.Bid:
              {
                if (domData.volume == 0) {
                  prev.bids.delete(domData.price);
                } else {
                  prev.bids.set(domData.price, domData.volume);
                }
              }
              break;
            case DomRealTimeType.Trade:
              {
                if (domData.volume == 0) {
                  prev.volumes.delete(domData.price);
                } else {
                  prev.volumes.set(domData.price, domData.volume);
                }

                prev.last = domData.price;
                lastPriceRef.current = domData.price;
                if (domData.price > prev.high) {
                  prev.high = domData.price;
                }
                if (domData.price < prev.low) {
                  prev.low = domData.price;
                }
              }
              break;
            case DomRealTimeType.Level2BestAsk:
              prev.bestAsk = domData.price;
              break;
            case DomRealTimeType.Level2BestBid:
              prev.bestBid = domData.price;
              break;
            case DomRealTimeType.High:
              prev.high = domData.price;
              break;
            case DomRealTimeType.Low:
              prev.low = domData.price;
              break;
          }
        }

        return { ...prev };
      });
    });

    return () => {
      unsubscribeDom(contract.symbol, id);
    };
  }, [contract]);

  const { maxBid, maxAsk, maxVolume } = useMemo(() => {
    let maxBid = 0,
      maxAsk = 0,
      maxVolume = 0;

    for (const [price, value] of domData.asks) {
      if (value > maxAsk) {
        maxAsk = value;
      }
    }

    for (const [price, value] of domData.bids) {
      if (value > maxBid) {
        maxBid = value;
      }
    }

    for (const [price, value] of domData.volumes) {
      if (value > maxVolume) {
        maxVolume = value;
      }
    }

    return { maxBid, maxAsk, maxVolume };
  }, [domData]);

  useEffect(() => {
    setCenteredPrice(null);
  }, [contract]);

  const data = useMemo(() => {
    if (centeredPrice === null && domData.last !== null) {
      setCenteredPrice(domData.last);
    }
    const retn: IDomContext = {
      asks: domData.asks,
      bids: domData.bids,
      volumes: domData.volumes,
      high: domData.high,
      low: domData.low,
      bestAsk: domData.bestAsk,
      bestBid: domData.bestBid,
      lastPrice: domData.last,
      orders: orderMap,
      position: position,
      positionEntryPrice: positionEntryPrice,
      maxBid,
      maxAsk,
      maxVolume,
      contractCost: contract.contractCost,
      tick: contract.tickSize,
      hasAskOrders,
      hasBidOrders,
      contract,
      orderAmount,
      orderList,
      updateOrderAmount,
      maxDomPrices,
      autoCenter,
      setAutoCenter,
      recenter,
      linkedOrderMap,
      centeredPrice: autoCenter ? domData.last : centeredPrice ?? domData.last,
      highlightGroup,
      setHighlightGroup
    };
    return retn;
  }, [linkedOrderMap, highlightGroup, centeredPrice, maxDomPrices, autoCenter, domData, orderMap, position, positionEntryPrice, maxAsk, maxBid, maxVolume, contract, hasBidOrders, hasAskOrders, orderAmount, orderList]);

  return <DomContext.Provider value={data}>{children}</DomContext.Provider>;
}

export default DomContextProvider;
