import React from 'react';
import classNames from 'classnames';
import styles from './button.module.scss';
import { makeStyles } from '@mui/material';

export enum ButtonType {
  green = 'green',
  red = 'red',
  light = 'light',
  grey = 'grey',
  outline = 'outline',
  gold = 'gold'
}

export type ButtonProps = React.ButtonHTMLAttributes<HTMLButtonElement> & {
  children: React.ReactNode;
  className?: string;
  inactive?: boolean;
  buttonType?: ButtonType;
  selected?: boolean;
  fill?: boolean;
  small?: boolean;
};




const Button: React.FC<ButtonProps> = ({
  children,
  className,
  buttonType,
  selected,
  fill,
  small,
  ...props
}): JSX.Element => {
  return (
    <button
      className={classNames(
        styles.button,
        className,
        buttonType && styles[buttonType],
        fill && styles.fill,
        selected && styles.selected,
        small && styles.small
      )}
      {...props}
    >
      {children}
    </button>
  );
};

export default React.memo(Button);
