import { Field, Form, Formik } from 'formik';
import React, { useCallback, useEffect, useState } from 'react';
import { useLocation } from 'react-router';
import { useNavigate } from 'react-router-dom';
import ReactstrapFormikInput from '../../components/forms/ReactstrapFormikInput';
import { NavRoutes } from '../../data/NavRoutes';
import { useApi } from '../../contexts/ApiContext';
import { PasswordResetResult } from '../../api/userApi';
import Heading, { HeadingSize } from '../../components/topstep/heading';
import Button, { ButtonType } from '@components/topstep/button';
import UserPage from '@views/user/userPage';
import { Alert, Box } from '@mui/material';
import { logException } from '@/helpers/exceptionHelper';
import { StyledButton } from '@/components/styledComponents';

const ForgotPasswordView: React.FC = (): JSX.Element => {
  const [redirect, setRedirect] = useState('');
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  const { forgotPasswordApi } = useApi();

  const [loading, setLoading] = useState(false);
  const { state, pathname } = useLocation();

  useEffect(() => {
    if (redirect) {
      setRedirect('');
      navigate(redirect);
    }
  }, [redirect]);

  const attemptReset = useCallback(
    (values: any) => {
      setLoading(true);
      setError(null);
      forgotPasswordApi
        .get(values.userName)
        .then((res) => {
          switch (res) {
            case PasswordResetResult.InvalidUsername:
              setError('Invalid Username');
              break;
            case PasswordResetResult.Success:
              setRedirect(`${NavRoutes.ResetPassword}?username=${encodeURIComponent(values.userName)}`);
              break;
            case PasswordResetResult.Pending:
              setError('Please wait another minute before trying again.');
            default:
              setError('Unknown Error: ' + res);
              break;
          }
          setLoading(false);
        })
        .catch((err) => {
          logException(err, 'Failed to request forgot password');
          setError('API Error');
          setLoading(false);
        });
    },
    [state]
  );

  return (
    <UserPage>
      <div style={{ textAlign: 'center' }}>
        <Heading size={HeadingSize.H1}>Forgot Password</Heading>
      </div>
      <Formik initialValues={{ userName: '' }} onSubmit={(values) => attemptReset(values)}>
        {(props) => (
          <Form>
            <Box style={{ justifyContent: 'center' }}>
              <Heading size={HeadingSize.H4}>Username</Heading>
              <Field component={ReactstrapFormikInput} type='text' name='userName' required className='mb-2' />
              {error && (
                <Alert severity='error' style={{ border: '1px solid red', margin: '1em 0' }}>
                  {error}
                </Alert>
              )}
              <div style={{ display: 'flex' }}>
                <StyledButton type='submit' sx={{ flex: 1, marginTop: '1em' }} disabled={loading}>
                  Reset Password
                </StyledButton>
              </div>
            </Box>
          </Form>
        )}
      </Formik>
    </UserPage>
  );
};

export default ForgotPasswordView;
