import { SymbolMetadata } from '@/api/userApi';
import { combineFractions, getFractions, roundToNearestTick } from '@/helpers/decimalHelper';
import { Box, TextField } from '@mui/material';
import Decimal from 'decimal.js';
import React, { useCallback, useEffect, useMemo, useState } from 'react';

interface IFractionalPriceInputProps {
  value: number;
  onChange: (value: number) => void;
  contract: SymbolMetadata;
  onBlur?: () => void;
}

const FractionalInput: React.FC<IFractionalPriceInputProps> = (props) => {
  const [wholeNumber, setWholeNumber] = useState<number>(0);
  const [firstFraction, setFirstFraction] = useState<number>(0);
  const [secondFraction, setSecondFraction] = useState<number | null>(null);

  const [wholeNumberInput, setWholeNumberInput] = useState<string>('');
  const [firstFractionInput, setFirstFractionInput] = useState<string>('');
  const [secondFractionInput, setSecondFractionInput] = useState<string>('');

  const hasSecondFraction = useMemo(() => {
    return props.contract.minMove2 > 0;
  }, [props.contract.minMove2]);

  const onBlur = useCallback(() => {
    setWholeNumberInput(wholeNumber.toString());
    setFirstFractionInput(firstFraction.toString());
    if (secondFraction != null) {
      setSecondFractionInput(secondFraction.toString());
    }

    if (props.onBlur) {
      props.onBlur();
    }
  }, [wholeNumber, firstFraction, secondFraction]);

  useEffect(() => {
    if (!props.value) return;
    const fractions = getFractions(props.value, props.contract.priceScale, props.contract.minMove, props.contract.minMove2);

    setWholeNumber(fractions.whole);
    setFirstFraction(fractions.first);
    setSecondFraction(fractions.second);
  }, [props.value]);

  useEffect(() => {
    setWholeNumberInput(wholeNumber.toString());
  }, [wholeNumber]);

  useEffect(() => {
    setFirstFractionInput(firstFraction.toString());
  }, [firstFraction]);

  useEffect(() => {
    if (secondFraction != null) {
      setSecondFractionInput(secondFraction.toString());
    }
  }, [secondFraction]);

  const maxFirstFraction = useMemo(() => {
    if (hasSecondFraction) {
      return new Decimal(props.contract.priceScale).div(props.contract.minMove2).toNumber() - 1;
    } else {
      return props.contract.priceScale - 1;
    }
  }, [hasSecondFraction, props.contract]);

  const onWholeNumberChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setWholeNumberInput(e.target.value);
      var value = e.target.value;
      if (!value || isNaN(parseInt(value))) {
        return;
      }

      let newValue = combineFractions(parseInt(e.target.value), firstFraction, secondFraction, props.contract.priceScale, props.contract.minMove, props.contract.minMove2);
      newValue = roundToNearestTick(newValue, props.contract);
      props.onChange(newValue);
    },
    [firstFraction, secondFraction, props.contract]
  );

  const onFirstFractionChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setFirstFractionInput(e.target.value);
      var value = e.target.value;
      if (!value || isNaN(parseInt(value))) {
        return;
      }

      let intVal = parseInt(value);
      intVal = Math.max(0, Math.min(intVal, maxFirstFraction));

      let newValue = combineFractions(wholeNumber, intVal, secondFraction, props.contract.priceScale, props.contract.minMove, props.contract.minMove2);
      newValue = roundToNearestTick(newValue, props.contract);
      props.onChange(newValue);
    },
    [wholeNumber, secondFraction, props.contract, maxFirstFraction]
  );

  const onSecondFractionChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setSecondFractionInput(e.target.value);
      var value = e.target.value;
      if (!value || isNaN(parseInt(value))) {
        return;
      }

      let intVal = parseInt(value);
      intVal = Math.max(0, Math.min(intVal, 9));

      let newValue = combineFractions(wholeNumber, firstFraction, intVal, props.contract.priceScale, props.contract.minMove, props.contract.minMove2);
      newValue = roundToNearestTick(newValue, props.contract);
      props.onChange(newValue);
    },
    [wholeNumber, firstFraction, props.contract]
  );

  return (
    <Box sx={{ display: 'flex' }}>
      <TextField
        label='Price'
        inputMode='numeric'
        type='text'
        size='small'
        sx={{ minWidth: '6em', flex: 0 }}
        InputProps={{ sx: { borderTopRightRadius: 0, borderBottomRightRadius: 0 } }}
        onBlur={onBlur}
        value={wholeNumberInput}
        onChange={onWholeNumberChange}
      ></TextField>
      <TextField
        InputProps={{ sx: hasSecondFraction ? { borderRadius: 0 } : { borderTopLeftRadius: 0, borderBottomLeftRadius: 0 } }}
        onBlur={onBlur}
        inputMode='numeric'
        type='text'
        size='small'
        sx={{ minWidth: '6em', flex: hasSecondFraction ? 0 : 1 }}
        value={firstFractionInput}
        onChange={onFirstFractionChange}
      ></TextField>
      {hasSecondFraction && (
        <TextField
          InputProps={{ sx: { borderTopLeftRadius: 0, borderBottomLeftRadius: 0 } }}
          size='small'
          sx={{ minWidth: '6em', flex: 1 }}
          onBlur={onBlur}
          inputMode='numeric'
          type='text'
          value={secondFractionInput}
          onChange={onSecondFractionChange}
        ></TextField>
      )}
    </Box>
  );
};

export default React.memo(FractionalInput);
