import {
  ChartingLibraryWidgetOptions,
  CustomIndicator,
  IChartWidgetApi,
  IChartingLibraryWidget,
  IOrderLineAdapter,
  IPositionLineAdapter,
  ResolutionString,
  Timezone,
  widget
} from '@/charting_library';
import { QuotesProvider } from 'datafeeds2/lib/quotes-provider';
import { Requester } from 'datafeeds2/lib/requester';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { AuthorizationHeaders, useApi } from '../../contexts/ApiContext';
import { useAuth } from '../../contexts/AuthContext';
import { useCqg } from '../../contexts/CqgContext';
import { TopStepDataFeed } from '../../datafeed/datafeed';
import config from '../../config';
import { useLinked } from '@/contexts/LinkedContext';

const MobileChart: React.FC = (): JSX.Element => {
  const { token } = useApi();
  const containerRef = useRef(null);
  const [tvWidget, setTvWidget] = useState<IChartingLibraryWidget>(null);
  const { subscribeBars, unsubscribeBars } = useCqg();

  const [searchParams] = useSearchParams();
  const [symbolCqgName] = useState(searchParams.get('symbol'));

  const [activeChart, setActiveChart] = useState<IChartWidgetApi>(null);
  const widgetRef = useRef<IChartingLibraryWidget>(null);
  const [renderSymbol, setRenderSymbol] = useState<string>(null);
  const symbolRef = useRef<string>(searchParams.get('symbol'));

  const { links } = useLinked();
  const topStepFeed = useRef<TopStepDataFeed>(null);

  useEffect(() => {
    if (!topStepFeed.current) return;

    topStepFeed.current.setLinksMap(links);
  }, [links]);

  const unsubscribeBarsWrapper = useCallback(
    (guid: string, callbackId: number) => {
      console.log('Unsubscribing from bars', guid, callbackId);
      return unsubscribeBars(guid, callbackId);
    },
    [unsubscribeBars]
  );

  const subscribeBarsWrapper = useCallback(
    (symbolId: number, resolution: string, guid: string, callback: (data: any) => void) => {
      try {
        if (widgetRef.current != null) {
          setActiveChart(widgetRef.current.activeChart());
        }
      } catch (e) {
        console.log('No active chart', e);
      }
      console.log('Subscribing to bars', symbolId, resolution, guid, callback);
      return subscribeBars(symbolId, resolution, guid, callback);
    },
    [subscribeBars]
  );

  useEffect(() => {
    const requester = new Requester(AuthorizationHeaders);

    const quotesProvider = new QuotesProvider(config.chartApi, requester);

    //test
    var feed = new TopStepDataFeed(config.chartApi, quotesProvider, requester, subscribeBarsWrapper, unsubscribeBarsWrapper, links, undefined, true);

    if (!containerRef.current) return;
    
    const tz = Intl.DateTimeFormat().resolvedOptions().timeZone as Timezone;
    const interval = '15' as ResolutionString;
    const widgetOptions: ChartingLibraryWidgetOptions = {
      symbol: symbolRef.current,
      datafeed: feed,
      interval,
      //interval: 'H' as ResolutionString,
      container: containerRef.current,
      library_path: '/charting_library/',
      locale: 'en',
      theme: 'dark',

      disabled_features: [
        'use_localstorage_for_settings',
        'header_symbol_search',
        'symbol_search_hot_key',
        'header_compare',
        'header_undo_redo',
        'study_templates',
        'header_compare',
        'header_fullscreen_button',
        'header_indicators',
        'header_screenshot',
        'header_saveload',
        'header_settings',
        'header_symbol_search',
        'header_undo_redo',
        'left_toolbar',
        'main_series_scale_menu',
        'show_right_widgets_panel_by_default',
        'timeframes_toolbar'
      ],
      load_last_chart: true,
      charts_storage_url: config.userApi + '/charts',
      charts_storage_api_version: '1.1',
      client_id: 'topstep',
      user_id: token,
      timezone: tz,
      fullscreen: false,
      autosize: true,
      studies_overrides: {},

      //timeframe: { from: start, to: end},
      overrides: {
        'paneProperties.backgroundType': 'solid',
        'paneProperties.background': '#1c1e23'
      },
      settings_overrides: {
        'panelProperties.legendProperties.showSeriesTitle': false
      },
      // custom_css_url should be related to library_path
      custom_css_url: '/themed.css'
    };

    const newTvWidget = new widget(widgetOptions);
    widgetRef.current = newTvWidget;

    newTvWidget.onChartReady(() => {
      setTvWidget(newTvWidget);
      setRenderSymbol(symbolRef.current);
      newTvWidget.applyOverrides({
        'paneProperties.backgroundType': 'solid' as any,
        'paneProperties.background': '#1c1e23'
      });
    });

    return () => {
      console.log('Destroying component, deleting widget');
      setTvWidget(null);
    };
  }, [subscribeBarsWrapper]);

  useEffect(() => {
    symbolRef.current = symbolCqgName;
  }, [symbolCqgName]);

  useEffect(() => {
    if (!activeChart) return;
    const resolution = activeChart.resolution();
    tvWidget.setSymbol(symbolCqgName, resolution, () => {
      setRenderSymbol(symbolRef.current);
    });
  }, [symbolCqgName, tvWidget, activeChart]);

  return <div style={{ width: '100%', height: '100%' }} ref={containerRef}></div>;
};

export default MobileChart;
