import { Box } from '@mui/material';
import React, { useMemo } from 'react';
import AccountSelector from '@/components/topstep/accountSelector';
import { formatPrice } from '../helpers/formatter';
import { useTradingAccount } from '../contexts/TradingAccountContext';
import { useOrders } from '../contexts/OrdersContext';

import './mobileTopbar.css';
import StrapiAlert from '@/components/topstep/strapiAlert';

const MobileTopbar: React.FC = (): JSX.Element => {
  const { activeTradingAccount } = useTradingAccount();
  const { activePositions } = useOrders();

  const unRealized = useMemo(() => {
    return activePositions
      .map((y) => y.profitAndLoss)
      .reduce((acc, pos) => {
        return acc + pos;
      }, 0);
  }, [activePositions]);

  if (unRealized > 0) {
    var unrealizedStyle = '#43984d';
  } else if (unRealized < 0) {
    var unrealizedStyle = '#bd3f3b';
  } else {
    var unrealizedStyle = 'white';
  }

  if (activeTradingAccount?.realizedDayPnl > 0) {
    var realizedStyle = '#43984d';
  } else if (activeTradingAccount?.realizedDayPnl < 0) {
    var realizedStyle = '#bd3f3b';
  } else {
    var realizedStyle = 'white';
  }

  return (
    <div id='mobile-topbar'>
      <Box sx={{ width: '100vw', background: '#1c1e23' }} flexDirection='row' display='flex' alignItems='center' justifyContent='space-between' padding={1} gap={2}>
        <Box style={{ textAlign: 'center' }}>
          <span>RP&L: </span>
          <span style={{ color: realizedStyle, fontWeight: 600 }}>${formatPrice(activeTradingAccount?.realizedDayPnl || 0)}</span>
        </Box>
        <AccountSelector />
        <Box style={{ textAlign: 'center' }}>
          <span>UP&L: </span>
          <span style={{ color: unrealizedStyle, fontWeight: 600 }}>${formatPrice(unRealized)}</span>
        </Box>
      </Box>
    </div>
  );
};

export default MobileTopbar;
