import classNames from 'classnames';
import React, { ChangeEvent, useCallback, useMemo } from 'react';
import styles from './balance.module.scss';
import { formatNumber, formatPrice } from '../../helpers/formatter';
import { useAuth } from '../../contexts/AuthContext';
import { useOrders } from '../../contexts/OrdersContext';
import { useTradingAccount } from '../../contexts/TradingAccountContext';
import { Tooltip } from '@mui/material';
import { useSettings } from '@contexts/SettingsContext';
import { useDeviceContext } from '@/contexts/DeviceContext';

type BalanceProps = {
  padding?: number;
  fontSize?: number;
  variant?: 'default' | 'dark';
};

const RealizedPnlInfo: React.FC<BalanceProps> = (props: BalanceProps): JSX.Element => {
  const { activeTradingAccount } = useTradingAccount();
  const { isMobile } = useDeviceContext();
  const { customSettings } = useSettings();
  if (activeTradingAccount?.realizedDayPnl > 0) {
    var walletBalanceStyle = styles.positive;
  } else if (activeTradingAccount?.realizedDayPnl < 0) {
    var walletBalanceStyle = styles.negative;
  } else {
    var walletBalanceStyle = props.variant === 'dark' ? styles.dark : styles.light;
  }

  const renderFontSize = useMemo(() => {
    switch (customSettings.topNavTextSize) {
      case 0:
        return '12px';
      case 1:
        return '14px';
      case 2:
        return '16px';
      default:
        return '12px';
    }
  }, [customSettings.topNavTextSize]);

  return useMemo(
    () => (
      <div className={classNames(isMobile ? styles.balanceMobile : styles.balance, walletBalanceStyle)} style={{ padding: props.padding }}>
        <Tooltip title="Realized Day P&L: The actual profit or loss from positions you've closed for the trading day.">
          <div className={styles.balanceAmount} style={{ fontSize: props.fontSize ?? renderFontSize }}>
            <span>RP&L: </span>
            <span>{customSettings.streamerModeRPL ? '******' : `$${formatPrice(activeTradingAccount?.realizedDayPnl || 0)}`}</span>
          </div>
        </Tooltip>
      </div>
    ),
    [activeTradingAccount.realizedDayPnl, customSettings.streamerModeRPL, customSettings.topNavTextSize]
  );
};

export default React.memo(RealizedPnlInfo);
