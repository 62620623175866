import React, { useCallback, useEffect, useMemo, useState } from 'react';
import MarketDataOrder from '../../../../models/marketDataOrder';
import { DragType, useDragDrop } from '../../../../contexts/DragDropContext';
import { FeOrderResult, OrderPromptType, calculatePnl, useOrders } from '../../../../contexts/OrdersContext';
import styles from './dom.module.scss';
import { useDom } from '@contexts/DomContext';
import { formatNumber } from 'src/helpers/formatter';
import SltpDomOrder from './sltpDomOrder';

interface PnlCellProps {
    price: number;
}

const PnlCell: React.FC<PnlCellProps> = ({ price }): JSX.Element => {
    const { position, contractCost, positionEntryPrice } = useDom();
    const { editSltpSetting } = useOrders();
    const pricePnl = useMemo(() => {
        return position &&
            calculatePnl(position.positionSize, position.averagePrice, price, contractCost);
    }, [position?.positionSize, position?.averagePrice, price, contractCost]);

    const editStopLossDragDrop = useCallback(
        (stopLoss?: number, takeProfit?: number) => {
            editSltpSetting(position?.id, stopLoss, takeProfit);
        },
        [position?.id, editSltpSetting]
    );


    return useMemo(() => {
        return position && (
            <SltpDomOrder
                posSize={position.positionSize}
                pnl={pricePnl}
                key={price}
                price={price}
                stopLoss={position.stopLoss}
                takeProfit={position.takeProfit}
                editSltp={editStopLossDragDrop}
                posEntryPrice={positionEntryPrice}
            ></SltpDomOrder>
        )
    }, [price, position?.positionSize, pricePnl, positionEntryPrice, position?.stopLoss, position?.takeProfit]);
};

export default React.memo(PnlCell);
