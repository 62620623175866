import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import styles from './ModalContext.module.scss';
import { useApi } from '@/contexts/ApiContext';
import { useAuth } from '@/contexts/AuthContext';
import { NotificationModel, ReadNotificationRequest } from '@/api/userApi';
import { useModal } from '@/contexts/ModalContext';
import { Box } from '@mui/material';
import Button, { ButtonType } from '@/components/topstep/button';
import Heading, { HeadingSize } from '@/components/topstep/heading';
import { useTradingAccount } from '@/contexts/TradingAccountContext';
import { TradingEnvironment } from '@/data/tradingEnvironment';
import Modal, { ModalAction } from '@/components/topstep/modal';
import { useCqg } from '@/contexts/CqgContext';
import { RtcUpdate } from '@/models/rtcUpdate';

export interface IUserNotificationsContext {}
interface NotificationProps {
  notification: NotificationModel;
  dismiss: () => void;
  dismissAll: () => void;
}
export const UserNotificationsContext = React.createContext<IUserNotificationsContext>({} as any);
export const useUserNotifications = () => React.useContext<IUserNotificationsContext>(UserNotificationsContext);

const Notification: React.FC<NotificationProps> = ({ notification, dismiss, dismissAll }) => {
  const html = useMemo(() => {
    return { __html: notification.content };
  }, [notification.content]);

  const actions: ModalAction[] = useMemo(
    () => [
      {
        label: 'Dismiss',
        props: { buttonType: ButtonType.outline, className: styles.modalButton },
        onClick: dismiss
      },
      {
        label: `Dismiss All`,
        props: {
          buttonType: ButtonType.red
        },
        onClick: dismissAll
      }
    ],
    [dismiss, dismissAll]
  );

  return (
    <Modal header={<Heading>{notification.title}</Heading>} actions={actions}>
      <Box sx={{ display: 'flex', flexDirection: 'column' }}>
        <div dangerouslySetInnerHTML={html}></div>
      </Box>
    </Modal>
  );
};

function UserNotificationsContextProvider({ children }: any) {
  const { userNotificationApi } = useApi();
  const { showModal, hideModal } = useModal();
  const [notifications, setNotifications] = useState<NotificationModel[]>([]);
  const { tradingEnvironment } = useTradingAccount();
  const { subscribeNotifications, unsubscribeNotifications } = useCqg();

  useEffect(() => {
    let onNotification = (notification: RtcUpdate<NotificationModel>) => {
      setNotifications((prev) => [notification.data, ...prev.filter((y) => y.notificationId != notification.data.notificationId)]);
    };
    let subscribed = false;
    userNotificationApi.getUnreadNotifications().then((notifications) => {
      setNotifications(notifications);
      subscribeNotifications(onNotification);
      subscribed = true;
    });

    return () => {
      if (subscribed) {
        unsubscribeNotifications(onNotification);
      }
    };
  }, []);

  const markAsRead = useCallback(
    (notification: NotificationModel) => {
      userNotificationApi.readNotification(new ReadNotificationRequest({ notificationId: notification.notificationId })).then(() => {
        setNotifications((prev) => prev.filter((x) => x.notificationId !== notification.notificationId));
      });

      if (notifications.length === 1) {
        hideModal();
      }
    },
    [notifications]
  );

  const markAllAsRead = useCallback(() => {
    for (const notification of notifications) {
      userNotificationApi.readNotification(new ReadNotificationRequest({ notificationId: notification.notificationId }));
    }
    hideModal();
  }, [notifications]);

  const providerValue = useMemo(() => {
    return {};
  }, []);

  useEffect(() => {
    if (tradingEnvironment === TradingEnvironment.None) return;
    if (notifications.length > 0) {
      showModal(<Notification notification={notifications[0]} dismiss={() => markAsRead(notifications[0])} dismissAll={markAllAsRead}></Notification>);
    }
  }, [notifications, showModal, markAsRead, markAllAsRead, tradingEnvironment]);

  return <UserNotificationsContext.Provider value={providerValue}>{children}</UserNotificationsContext.Provider>;
}

export default UserNotificationsContextProvider;
