import React, { useCallback } from 'react';
import { SxProps, Theme } from '@mui/material';
import { StyledButton } from '../styledComponents';

interface QuantityButtonProps {
  quantity: number;
  currentSize: number;
  setSize: (size: number) => void;
  disabled: boolean;
}

export const QuantityButtonStyle: SxProps<Theme> = {
  minWidth: '2em',
  marginLeft: '0.25em',
  flex: 0,
  marginRight: '0.25em',
  borderRadius: '5em',
  padding: 0,
  width: '2em',
  height: '2em',
  backgroundColor: '#565656 !important',
  ':hover': {
    backgroundColor: '#bababa'
  }
};

const QuantityButtonActiveStyle: SxProps<Theme> = (theme: Theme) => {
  return { ...QuantityButtonStyle, backgroundColor: theme.palette.neutral.light };
};
const QuantityButton: React.FC<QuantityButtonProps> = React.memo(({ quantity, currentSize, setSize, disabled }) => {
  const onClick = useCallback(() => {
    setSize(quantity);
  }, [quantity, setSize]);

  return (
    <StyledButton disabled={disabled} color='neutral' sx={(theme) => (quantity === currentSize ? QuantityButtonActiveStyle(theme) : QuantityButtonStyle)} onClick={onClick}>
      {quantity}
    </StyledButton>
  );
});

export default QuantityButton;
