import { faWhistle } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box } from '@mui/material';
import React, { useMemo } from 'react';
import whistle from './coolwhistle.png';
import { TradingPlatforms as TradingPlatform, config } from '@/config';

const coachTips = [
  'Reflect back on recent trading days. What went well on the winning days? What did you struggle with on the losing days? Stay confident and keep working hard to become the best trader you can be. ',
  "Emotions can happen while you're trading. Make sure to understand, embrace, and use them to your advantage. Sometimes, they can even be helpful! ",
  'Always have reasonable expectations with your trading, and know that you can only get out of this what you put in. Risk management, patience, and discipline will bring success in your trading journey. ',
  'Great traders demand great trade locations. Make sure to look for opportunities where you can limit your risk and always place a stop on your trade. ',
  'Reflect on your previous trades beyond whether they were profitable or not. A losing trade can still be a great trade in the same way that a profitable trade can end up being a bad trade. ',
  "Risk Management is knowing that you can't take every opportunity, but that you understand which opportunity to take. ",
  'Emotions in trading will happen. Understand them, embrace them, and use them to your advantage. Sometimes, they can even be helpful! ',
  "By the time a trend has been established, it is often too late to reap the profits. Be mindful of your timing, and consider learning to trade ranges so you aren't inclined to join in on the trends. ",
  'Futures trading is a profession. To succeed, it takes time and concentration. There are no shortcuts, only hard work and discipline. ',
  'Indicators are great tools, but like anything, they can help or hurt your trading. Consider starting with a naked chart to learn price action, and then slowly add in indicators to advance your strategy. ',
  // "I’m always thinking about losing money as opposed to making money. Don’t focus on making money, focus on protecting what you have” Paul Tudor Jones ",
  "Big trades should come only after you've proved yourself with small trades. Consider starting your day off with small profits and then build them up so you can start to trade bigger. Always make sure to have a safety net when making larger trades.",
  "Trade the size that your mindset will support. If you find that your emotions kick in when you start adding size to a position, it's likely an indicator that you're trading too much size. Less is more!",
  "Trading can be a lonely profession when you're in front of a screen all day. Check out our Discord community to find a Trading Buddy to bounce strategies off of and help hold each other accountable.",
  "The only constant in trading is change. Once you think you've found the answer, the market will change the question. Stay open to change and embrace it as a skill!",
  "Take your trading one second at a time. Each trade is different, and every situation is different. Follow your trading plan and adjust accordingly to the market's conditions.",
  "Use timeframes to your advantage. If you're doubting the direction your instrument is moving in, move up a timeframe. If you still have doubts, continue to move up a timeframe. Higher timeframes are great to help you arrive at the market direction."
];

const CoachTip: React.FC = (): JSX.Element => {
  return useMemo(
    () => (
      <Box sx={{ display: 'flex', justifyContent: 'center', justifyItems: 'center', alignContent: 'center', alignItems: 'center' }}>
        {TradingPlatform.TopstepX === config.platform && (
          <Box
            sx={(theme) => {
              return {
                margin: '0 1em',
                whiteSpace: 'nowrap',
                background: theme.palette.white,
                color: theme.palette.darkBg,
                fontWeight: 'bold',
                borderRadius: '10em',
                display: 'flex',
                justifyContent: 'center',
                justifyItems: 'center',
                alignContent: 'center',
                alignItems: 'center',
                padding: '1.2em 0.5em',
                paddingLeft: '0.2em',
                height: '1.2em'
              };
            }}
          >
            <img style={{ height: '1.5em', width: 'auto' }} src={whistle}></img>
            <span>
              Tip from Coach T<span style={{ fontSize: '0.6em', verticalAlign: 'super' }}>&reg;</span>
            </span>
          </Box>
        )}
        <span>{coachTips[Math.floor(Math.random() * coachTips.length)]}</span>
      </Box>
    ),
    []
  );
};

export default React.memo(CoachTip);
