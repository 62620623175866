import React, { Suspense } from 'react';
import Loading from './components/Loading';
import * as serviceWorker from './serviceWorker';
import { createRoot } from 'react-dom/client';

import App from './App';
import { BrowserRouter } from 'react-router-dom';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezones from 'dayjs/plugin/timezone';
import DeviceContextProvider from './contexts/DeviceContext';

dayjs.extend(utc);
dayjs.extend(timezones);

const container = document.getElementById('root');
const root = createRoot(container);
root.render(
  <Suspense fallback={<Loading />}>
    <BrowserRouter>
      <DeviceContextProvider>
        <App />
      </DeviceContextProvider>
    </BrowserRouter>
  </Suspense>
);

serviceWorker.unregister();
