import React, { useCallback, useMemo } from 'react';
import { useOrders } from '../../../../contexts/OrdersContext';
import { IExtendedTradingAccountModel, useTradingAccount } from '../../../../contexts/TradingAccountContext';
import { CurrencySpan, DateSpan, NumberSpan, PercentSpan } from '@components/numbers';
import { Box, FormLabel } from '@mui/material';
import { tradingAccountStatusMap, violationTypeMap } from 'src/data/enumTypeMaps';
import { DataGridPro, GridColDef, GridValueGetterParams, useGridApiRef } from '@mui/x-data-grid-pro';
import styles from './accounts.module.scss';
import { GridInitialStatePro } from '@mui/x-data-grid-pro/models/gridStatePro';
import { useDeviceContext } from '@/contexts/DeviceContext';
import _ from 'lodash';
interface AccountsProps {
  changed: () => void;
  tabData: {
    gridState: GridInitialStatePro;
  };
}

const Accounts: React.FC<AccountsProps> = (props): JSX.Element => {
  const { allPositions } = useOrders();
  const { tradingAccounts } = useTradingAccount();

  const gridRef = useGridApiRef();

  const { isMobile } = useDeviceContext();

  const getPnl = useCallback(
    (accountId: number) => {
      return allPositions
        .filter((x) => x.accountId == accountId)
        .map((y) => y.profitAndLoss)
        .reduce((acc, pos) => {
          return acc + pos;
        }, 0);
    },
    [allPositions]
  );

  const renderStatus = useCallback((status: string, violation: any) => {
    if (status === 'Active') {
      return <span style={{ color: '#3fa54b' }}>{status}</span>;
    } else if (status === 'Ineligible') {
      return <span style={{ color: '#c11249' }}>{status}</span>;
    } else if (status === 'Temp. Liquidation') {
      return (
        <span style={{ color: '#c1b512' }}>
          {status} {violation && '(' + violationTypeMap[violation.type] + ')'}
        </span>
      );
    } else {
      return <span>{status}</span>;
    }
  }, []);

  const columns: GridColDef<IExtendedTradingAccountModel>[] = useMemo(() => {
    const gridColDef: GridColDef<IExtendedTradingAccountModel>[] = [
      {
        field: 'accountStatus',
        headerName: 'Status',
        sortable: true,
        filterable: true,
        flex: 0.3,
        headerClassName: styles.header,
        // renderCell: (params: GridValueGetterParams<TradingAccountModel>) => {
        //   return <span>{tradingAccountStatusMap[params.row.status]}</span>;
        // },
        renderCell: (params: GridValueGetterParams<IExtendedTradingAccountModel>) => {
          return renderStatus(tradingAccountStatusMap[params.row.status], params.row.activeViolation);
        },
        minWidth: isMobile ? 100 : undefined
      },
      {
        field: 'createdOn',
        headerName: 'Created On',
        sortable: true,
        type: 'number',
        flex: 0.6,
        headerClassName: styles.header,
        renderCell: (params: GridValueGetterParams<IExtendedTradingAccountModel>) => {
          return <DateSpan>{params.row.startDate.local()}</DateSpan>;
        },
        valueGetter: (params: GridValueGetterParams<IExtendedTradingAccountModel>) => {
          return params.row.startDate.local();
        },
        align: 'left',
        headerAlign: 'left',
        minWidth: isMobile ? 150 : undefined
      },
      {
        field: 'accountName',
        headerName: 'Account Name',
        sortable: true,
        filterable: true,
        flex: 0.5,
        headerClassName: styles.header,
        renderCell: (params: GridValueGetterParams<IExtendedTradingAccountModel>) => {
          if (params.row.nickname) {
            return (
              <span>
                {params.row.nickname} ({params.row.accountName})
              </span>
            );
          }
          return <span>{params.row.accountName}</span>;
        },
        align: 'center',
        headerAlign: 'center',
        minWidth: isMobile ? 150 : undefined
      },
      {
        field: 'winRate',
        headerName: 'Win Rate',
        sortable: true,
        filterable: true,
        flex: 0.3,
        type: 'number',
        headerClassName: styles.header,
        renderCell: (params: GridValueGetterParams<IExtendedTradingAccountModel>) => {
          return <PercentSpan>{params.row.winRate}</PercentSpan>;
        },
        align: 'center',
        headerAlign: 'center',
        minWidth: isMobile ? 100 : undefined
      },
      {
        field: 'totalTrades',
        headerName: 'Total Lots Traded',
        filterable: true,
        sortable: true,
        flex: 0.5,
        type: 'number',
        headerClassName: styles.header,
        renderCell: (params: GridValueGetterParams<IExtendedTradingAccountModel>) => {
          return <NumberSpan>{params.row.dailyTrades}</NumberSpan>;
        },
        align: 'center',
        headerAlign: 'center',
        minWidth: isMobile ? 140 : undefined
      },
      {
        field: 'dayPnl',
        headerName: 'Day P&L',
        sortable: true,
        flex: 0.7,
        type: 'number',
        headerClassName: styles.header,
        renderCell: (params: GridValueGetterParams<IExtendedTradingAccountModel>) => {
          return <CurrencySpan>{params.row.realizedDayPnl}</CurrencySpan>;
        },
        valueGetter: (params: GridValueGetterParams<IExtendedTradingAccountModel>) => {
          return params.row.realizedDayPnl;
        },
        align: 'center',
        headerAlign: 'center',
        minWidth: isMobile ? 100 : undefined
      },
      {
        field: 'pnl',
        headerName: 'Open P&L',
        sortable: true,
        type: 'number',
        flex: 0.5,
        headerClassName: styles.header,
        renderCell: (params: GridValueGetterParams<IExtendedTradingAccountModel>) => {
          const pnl = getPnl(params.row.accountId);
          return <CurrencySpan>{pnl}</CurrencySpan>;
        },
        valueGetter: (params: GridValueGetterParams<IExtendedTradingAccountModel>) => {
          const pnl = getPnl(params.row.accountId);
          return pnl;
        },
        align: 'center',
        headerAlign: 'center',
        minWidth: isMobile ? 120 : undefined
      },
      {
        field: 'accountBalance',
        headerName: 'Account Balance',
        sortable: true,
        flex: 0.7,
        type: 'number',
        headerClassName: styles.header,
        renderCell: (params: GridValueGetterParams<IExtendedTradingAccountModel>) => {
          const pnl = getPnl(params.row.accountId);
          return <CurrencySpan>{params.row.balance + pnl}</CurrencySpan>;
        },
        valueGetter: (params: GridValueGetterParams<IExtendedTradingAccountModel>) => {
          const pnl = getPnl(params.row.accountId);
          return params.row.balance + pnl;
        },
        align: 'center',
        headerAlign: 'center',
        minWidth: isMobile ? 150 : undefined
      }
    ];

    return gridColDef;
  }, [tradingAccounts, getPnl, isMobile]);

  const noRows = useMemo(() => {
    return (
      <Box height='100%' width='100%' display='flex' alignItems='center' justifyContent='center'>
        No Accounts
      </Box>
    );
  }, []);

  const initialState: GridInitialStatePro = useMemo(() => {
    return (
      props?.tabData?.gridState ?? {
        sorting: {
          sortModel: [
            {
              field: 'accountName',
              sort: 'desc'
            }
          ]
        },
        pagination: {
          paginationModel: { page: 0, pageSize: 20 }
        }
      }
    );
  }, []);

  return (
    <Box style={{ height: '100%', width: '100%', minWidth: '100px', minHeight: '100px', maxHeight: isMobile ? 'calc(100% - 40px)' : undefined }}>
      {isMobile && (
        <div style={{ padding: '0.75em', textAlign: 'center' }}>
          <FormLabel sx={{ fontSize: '1.35em' }}>Accounts</FormLabel>
        </div>
      )}
      <DataGridPro
        apiRef={gridRef}
        getRowId={(x) => x.accountId}
        rowHeight={32}
        columnHeaderHeight={32}
        rows={tradingAccounts}
        density='compact'
        columns={columns}
        slots={{
          noRowsOverlay: () => noRows
        }}
        initialState={initialState}
        onStateChange={() => {
          const newState = gridRef.current.exportState();
          if (_.isEqual(newState, props.tabData.gridState)) return;

          props.tabData.gridState = newState;
          props.changed();
        }}
        pageSizeOptions={[20, 50]}
      ></DataGridPro>
    </Box>
  );
};

export default Accounts;
