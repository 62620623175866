import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { SessionValidationResult, SignAgreementRequest, UserAgreementModel, UserModel } from '../api/userApi';
import { useApi } from './ApiContext';
import * as Sentry from '@sentry/react';
import { logException } from '@/helpers/exceptionHelper';
export interface IAuthContext {
  loggedIn: boolean;
  logOut: () => void;
  refresh: () => void;
  requiredAgreements: UserAgreementModel[];
  signAgreements: (agreements: number[], name: string) => Promise<void>;
  userId?: number;
  userInfo: UserModel;
  token: string;
  sessionId?: string;
}

export const AuthContext = React.createContext<IAuthContext>({} as any);
export const useAuth = () => React.useContext<IAuthContext>(AuthContext);

function AuthContextProvider({ children }: any) {
  const [loggedIn, setLoggedIn] = useState<boolean>(false);
  const [loaded, setLoaded] = useState<boolean>(false);
  const [userInfo, setUserInfo] = useState<UserModel>(null);
  const { userApi, agreementsApi, token, setToken, sessionApi } = useApi();
  const [requiredAgreements, setRequiredAgreements] = useState<UserAgreementModel[]>([]);
  const [lastValidation, setLastValidation] = useState<number>(0);
  const [validated, setValidated] = useState<boolean>(false);
  const [sessionId, setSessionId] = useState<string>(null);

  const signAgreements = useCallback(
    (agreements: number[], name: string) => {
      return agreementsApi.sign(new SignAgreementRequest({ agreementIds: agreements, name })).then((data) => {
        setRequiredAgreements(data);
      });
    },
    [agreementsApi]
  );

  const refresh = useCallback(() => {
    agreementsApi
      .getRequired()
      .then((data) => {
        setRequiredAgreements(data);
      })
      .catch((err) => {
        logException(err, 'Unable to get agreements');
        console.warn('Unable to get agreements');
      });
    userApi
      .get()
      .then((data) => {
        setUserInfo(data);
        setLoggedIn(true);
        setLoaded(true);
      })
      .catch((err) => {
        logException(err, 'Unable to get user info');
        // setToken(null);
        setLoggedIn(false);
        setLoaded(true);
      });
  }, []);

  useEffect(() => {
    if (!token) {
      setValidated(false);
      return;
    }

    if (validated) {
      return;
    }

    sessionApi.validate().then((data) => {
      if (data.result == SessionValidationResult.Success) {
        setValidated(true);
        setToken(data.token);
        setSessionId(data.sessionId);
      } else {
        logOut();
      }
    });
  }, [token, validated]);

  useEffect(() => {
    if (!validated) return;

    //revalidate once an hour
    const timeout = setTimeout(() => {
      sessionApi.validate().then((data) => {
        if (data.result == SessionValidationResult.Success) {
          setValidated(true);
          setToken(data.token);
          setSessionId(data.sessionId);
        } else {
          logOut();
        }
      });
    }, 1000 * 60 * 60);

    return () => {
      clearTimeout(timeout);
    };
  }, [token, validated]);

  useEffect(() => {
    if (token) {
      if (!validated) return;

      Promise.all([agreementsApi.getRequired(), userApi.get()])
        .then(([agreements, user]) => {
          setRequiredAgreements(agreements);
          setUserInfo(user);
          setLoggedIn(true);
          setLoaded(true);
          Sentry.setUser({
            email: user.email,
            username: user.userName,
            id: user.userId + ''
          });

          var wnd = window as any;
          if (wnd.Intercom) {
            //update intercom email;
            wnd.Intercom('update', {
              name: user.firstName + ' ' + user.lastName,
              email: user.email,
              user_id: user.userId
            });
          }
        })
        .catch((err) => {
          Sentry.setUser(null);
          setLoggedIn(false);
          setLoaded(true);
        });
    } else {
      setLoaded(true);
      return () => setLoaded(false);
    }
  }, [token, validated]);

  const logOut = useCallback(() => {
    setToken(null);
    setLoggedIn(false);
    Sentry.setUser(null);
  }, []);

  const values = useMemo(() => {
    return {
      loggedIn,
      logOut,
      token,
      refresh,
      userId: userInfo?.userId,
      userInfo,
      requiredAgreements,
      signAgreements,
      sessionId
    };
  }, [token, userInfo, loggedIn, requiredAgreements, sessionId]);

  return <AuthContext.Provider value={values}>{loaded && children}</AuthContext.Provider>;
}

export default AuthContextProvider;
