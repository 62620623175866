import React, { useCallback, useEffect, useMemo, useState } from 'react';
import MarketDataOrder from '../../../../models/marketDataOrder';
import { DragType, useDragDrop } from '../../../../contexts/DragDropContext';
import { FeOrderResult, OrderPromptType } from '../../../../contexts/OrdersContext';
import styles from './dom.module.scss';
import { OrderModel } from '@/api/userApi';

interface DomEmptyOrderProps {
  price: number;
  changeOrderPrice: (id: number, price: number) => Promise<void>;
  sellBracket?: boolean;
  buyBracket?: boolean;
  type: OrderPromptType;
}

const DomEmptyOrder: React.FC<DomEmptyOrderProps> = ({ price, changeOrderPrice }): JSX.Element => {
  const { dragData, dragType, setDragData } = useDragDrop();
  const [allowDrop, setAllowDrop] = useState(false);

  useEffect(() => {
    if (allowDrop && dragType == DragType.None) {
      setAllowDrop(false);
    }
  }, [dragType, allowDrop]);

  const onDragEnter = useCallback(
    (e: React.DragEvent) => {
      if (dragType == DragType.DomSellOrder || dragType == DragType.DomBuyOrder) {
        e.preventDefault();
        e.dataTransfer.dropEffect = 'move';
        setAllowDrop(true);
      }
    },
    [dragType]
  );

  const onDragLeave = useCallback(
    (e: React.DragEvent) => {
      setAllowDrop(false);
    },
    [dragType]
  );

  const onDragOver = useCallback(
    (e: React.DragEvent) => {
      if (dragType == DragType.DomSellOrder || dragType == DragType.DomBuyOrder) {
        e.preventDefault();
        e.dataTransfer.dropEffect = 'move';
      }
    },
    [price, dragType]
  );

  const onDrop = useCallback(
    async (e: React.DragEvent) => {
      const data = dragData as OrderModel[];
      for (const o of data) {
        const res = await changeOrderPrice(o.id, price);
      }
      setDragData();
    },
    [price, dragData]
  );

  return (
    <div
      className={styles.domOrderEntryBox}
      onDragOver={onDragOver}
      onDragEnter={onDragEnter}
      onDragLeave={onDragLeave}
      onDrop={onDrop}
      style={{
        borderWidth: '2px',
        borderStyle: 'solid',
        borderColor: allowDrop ? 'green' : 'transparent',
        width: '100%',
        height: '100%',
        zIndex: 1000
      }}
    >
      &nbsp;
    </div>
  );
};

export default React.memo(DomEmptyOrder);
