import { ScopedCssBaselineTypeMap, Theme, styled } from '@mui/material';
import React, { useCallback } from 'react';
import { formatContractPrice, formatNumber, formatPrice } from 'src/helpers/formatter';
import dayjs from 'dayjs';
import { SymbolMetadata } from '@/api/userApi';
import { DefaultComponentProps } from '@mui/material/OverridableComponent';

const StyledSpan = styled('span')((props) => ({
  fontFamily: "'Roboto Condensed', sans-serif" // "Roboto Mono",
}));
const GreenStyledSpan = styled(StyledSpan)(({ theme }) => ({
  color: theme.palette.green
}));

const RedStyledSpan = styled(StyledSpan)(({ theme }) => ({
  color: theme.palette.red
}));

const NormalButton = styled(StyledSpan)((props) => ({
  color: props.theme.palette.common.white
}));

export enum NumberSpanColor {
  Green = 'green',
  Red = 'red'
}

export const StyledNumberSpan: React.FC<DefaultComponentProps<ScopedCssBaselineTypeMap<{ numberColor?: NumberSpanColor }>>> = (props) => {
  const sx = useCallback(
    (theme: Theme) => {
      if (!!props) {
        switch (props.numberColor) {
          case NumberSpanColor.Green:
            return { color: theme.palette.green };
          case NumberSpanColor.Red:
            return { color: theme.palette.red };
        }
      }
      return {};
    },
    [props.numberColor]
  );

  return <StyledSpan sx={sx} {...props} />;
};

interface ICurrencyProps {
  children: number;
  showColors?: boolean;
  decimalPlaces?: number;
}

interface IRedGreenSpanProps {
  children: React.ReactNode;
  colorCheck?: number;
}

interface IRedGreenCurrencyProps {
  children: number;
  colorCheck?: number;
  decimalPlaces?: number;
}

interface IContractPrice {
  children: number;
  showRed?: boolean;
  showGreen?: boolean;
  contract?: SymbolMetadata;
}

interface INumberProps {
  children: number;
}

interface IDateProps {
  children: dayjs.Dayjs;
}

export const ContractPriceSpan: React.FC<IContractPrice> = ({ children, showGreen, showRed, contract }) => {
  return <StyledNumberSpan numberColor={showGreen ? NumberSpanColor.Green : showRed ? NumberSpanColor.Red : undefined}>{formatContractPrice(children, contract)}</StyledNumberSpan>;
};

export const RedGreenCurrencySpan: React.FC<IRedGreenCurrencyProps> = ({ children, colorCheck, decimalPlaces }) => {
  if (colorCheck < 0) {
    return <RedStyledSpan>${formatPrice(children, decimalPlaces)}</RedStyledSpan>;
  } else {
    return <GreenStyledSpan>${formatPrice(children, decimalPlaces)}</GreenStyledSpan>;
  }
};

export const CurrencySpan: React.FC<ICurrencyProps> = ({ children, showColors, decimalPlaces }) => {
  if (showColors) {
    if (children > 0) {
      return <GreenStyledSpan>${formatPrice(children, decimalPlaces)}</GreenStyledSpan>;
    } else {
      return <RedStyledSpan>${formatPrice(children, decimalPlaces)}</RedStyledSpan>;
    }
  }
  return <StyledSpan>${formatPrice(children, decimalPlaces)}</StyledSpan>;
};

export const RedGreenSpan: React.FC<IRedGreenSpanProps> = ({ children, colorCheck }) => {
  if (colorCheck < 0) {
    return <RedStyledSpan>{children}</RedStyledSpan>;
  } else {
    return <GreenStyledSpan>{children}</GreenStyledSpan>;
  }
};

export const RedGreenPriceSpan: React.FC<IRedGreenCurrencyProps> = ({ children, decimalPlaces, colorCheck }) => {
  if (colorCheck < 0) {
    return <RedStyledSpan>{formatPrice(children, decimalPlaces)}</RedStyledSpan>;
  } else {
    return <GreenStyledSpan>{formatPrice(children, decimalPlaces)}</GreenStyledSpan>;
  }
};

export const RedGreenPercentSpan: React.FC<IRedGreenCurrencyProps> = ({ children, colorCheck }) => {
  if (colorCheck < 0) {
    return <RedStyledSpan>{formatPrice(children * 100)}%</RedStyledSpan>;
  } else {
    return <GreenStyledSpan>{formatPrice(children * 100)}%</GreenStyledSpan>;
  }
};

export const NumberSpan: React.FC<INumberProps> = ({ children }) => {
  return <StyledSpan>{formatNumber(children)}</StyledSpan>;
};

export const PercentSpan: React.FC<INumberProps> = ({ children }) => {
  return <StyledSpan>{formatPrice(children * 100)}%</StyledSpan>;
};

export const DateSpan: React.FC<IDateProps> = ({ children }) => {
  return <StyledSpan>{children.format('YYYY-MM-DD HH:mm:ss.SSS')}</StyledSpan>;
};

export const DateSpanHumanFormat: React.FC<IDateProps> = ({ children }) => {
  return <StyledSpan>{children.format('MMMM D YYYY @ h:mm:ss a')}</StyledSpan>;
};
