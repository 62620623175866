import React, { useState, useEffect, useMemo } from 'react';
import { Checkbox, FormControlLabel, TextField, Button, Link, Box, styled } from '@mui/material';
import { useAuth } from '@contexts/AuthContext';
import { StyledButton, StyledLink } from '@components/styledComponents';
import Panel from '@components/topstep/panel';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { NavRoutes } from 'src/data/NavRoutes';
import { useNavigate } from 'react-router-dom';
import { setSyntheticLeadingComments } from 'typescript';
import { faSpinner } from '@fortawesome/pro-solid-svg-icons';

interface AgreementsViewProps {
  onSubmit: (name: string) => void;
}

const AgreementsView: React.FC = () => {
  const { signAgreements, requiredAgreements } = useAuth();
  const [name, setName] = useState('');
  const [accepted, setAccepted] = useState(false);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (requiredAgreements.length == 0) {
      navigate(NavRoutes.Trade);
    }
  }, [requiredAgreements]);

  const agreement = useMemo(() => {
    if (requiredAgreements.length == 0) {
      return null;
    }

    return requiredAgreements[0];
  }, [requiredAgreements]);

  const agreementText = useMemo(() => {
    return agreement?.customAcceptText || 'I have fully read, understand and declare my Market Data Self Certification';
  }, []);

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (accepted && name.trim() !== '') {
      setLoading(true);
      signAgreements([agreement.id], name).then(() => {
        setLoading(false);
      });
    }
  };

  return (
    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
      <Panel title='Sign Agreements'>
        <Box sx={{ padding: '0.5em' }}>
          <p>Please read the following documents, and if you agree, type your full name in, click accept and submit.</p>
          <form onSubmit={handleSubmit}>
            <Box sx={{ display: 'flex', flexDirection: 'column' }}>
              {agreement && (
                <StyledLink target='_blank' sx={{ marginTop: '0.5em', marginLeft: '1em' }} key={agreement.id} href={agreement.url}>
                  {agreement.title}
                </StyledLink>
              )}

              <TextField label='Type Your Name' value={name} name='Full Name' onChange={(event) => setName(event.target.value)} fullWidth margin='normal' variant='outlined' />
              <FormControlLabel control={<Checkbox checked={accepted} onChange={(event) => setAccepted(event.target.checked)} />} label={agreementText} />
              <StyledButton type='submit' variant='contained' color='primary' disabled={!accepted || name.trim() === '' || loading}>
                {loading && <FontAwesomeIcon icon={faSpinner} spin />}
                Submit
              </StyledButton>
            </Box>
          </form>
        </Box>
      </Panel>
    </div>
  );
};

export default AgreementsView;
