export enum DomRealTimeType
{
    Unknown,
    Ask,
    Bid,
    BestAsk,
    BestBid,
    Trade,
    Reset,
    Low,
    High,
    Level2BestAsk,
    Level2BestBid,
}
export interface DomRealTime {
    price: number;
    volume: number;
    type: DomRealTimeType;
}