import React, { useCallback, useEffect, useMemo, useState, useTransition } from 'react';
import { Box, Button, Modal, Table, Tooltip, Typography } from '@mui/material';
import { TsModal } from '@/components/modal';
import { useOrders } from '@/contexts/OrdersContext';
import { OrderModel, OrderStatus } from '@/api/userApi';
import { DataGridPro, GridCloseIcon, GridColDef, GridInputRowSelectionModel, GridValueGetterParams, renderActionsCell, useGridApiRef } from '@mui/x-data-grid-pro';
import { GridInitialStatePro } from '@mui/x-data-grid-pro/models/gridStatePro';
import styles from './linkOrdersModal.module.scss';
import { ContractPriceSpan, DateSpan, NumberSpan, RedGreenSpan } from '@/components/numbers';
import { orderStatusMap, orderTypeMap } from '@/data/enumTypeMaps';
import { useSymbol } from '@/contexts/SymbolContext';
import { StyledButton } from '@/components/styledComponents';
import Heading, { HeadingSize } from '@/components/topstep/heading';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChain } from '@fortawesome/pro-regular-svg-icons';
import { useTradingAccount } from '@/contexts/TradingAccountContext';
import { toast } from 'react-toastify';
import { showLoader } from '@/components/toastLoader';
import { logException } from '@/helpers/exceptionHelper';
import { useDeviceContext } from '@/contexts/DeviceContext';

interface ILinkedOrderModalProps {
  open: boolean;
  onClose: () => void;
}
const LinkOrdersModal: React.FC<ILinkedOrderModalProps> = ({ open, onClose }) => {
  const { nonSltpOrders, linkedOrders, linkOrders, unlinkOrders } = useOrders();
  const { activeTradingAccount } = useTradingAccount();
  const gridRef = useGridApiRef();
  const { getContractById } = useSymbol();
  const [working, setWorking] = useState(false);
  const [rowSelectionModel, setRowSelectionModel] = useState<GridInputRowSelectionModel>([]);
  const { isMobile } = useDeviceContext();

  const onUnlinkClicked = useCallback(
    (groupId: string) => {
      const resolve = showLoader('Unlinking Orders...', isMobile);
      setWorking(true);
      unlinkOrders(activeTradingAccount.accountId, groupId)
        .then((res) => {
          setWorking(false);
          resolve(res.success ? 'Orders Unlinked' : 'Failed to unlink orders: ' + res.errorMessage, !res.success);
        })
        .catch((err) => {
          setWorking(false);
          logException(err, 'Failed to unlink orders');
          resolve('Failed to unlink orders: ' + err.message, true);
        });
    },
    [activeTradingAccount?.accountId, isMobile]
  );

  const initialState: GridInitialStatePro = useMemo(() => {
    return {
      sorting: {
        sortModel: [
          {
            field: 'symbol',
            sort: 'desc'
          }
        ]
      },
      pagination: {
        paginationModel: { page: 0, pageSize: 20 }
      }
    };
  }, []);

  const columns: GridColDef<OrderModel>[] = useMemo(() => {
    const c: GridColDef<OrderModel>[] = [
      {
        field: 'id',
        headerName: 'ID',
        sortable: false,
        filterable: false,
        minWidth: 75,
        flex: 1,
        headerClassName: styles.header,
        renderCell: (params: GridValueGetterParams<OrderModel>) => {
          if (linkedOrders.some((y) => y.linkedOrderIds.includes(params.row.id))) {
            return (
              <Tooltip title='This order is already linked.'>
                <Typography
                  sx={(theme) => {
                    return {
                      color: theme.palette.error.main
                    };
                  }}
                >
                  <FontAwesomeIcon icon={faChain} /> {params.row.id}
                </Typography>
              </Tooltip>
            );
          }
          return <Typography>{params.row.id}</Typography>;
        }
      },
      {
        field: 'symbolName',
        headerName: 'Symbol',
        sortable: false,
        filterable: false,
        flex: 1,
        headerClassName: styles.header,
        renderCell: (params: GridValueGetterParams<OrderModel>) => {
          return <span>{params.row.symbolName}</span>;
        }
      },
      {
        field: 'positionSize',
        headerName: 'Size',
        sortable: false,
        filterable: false,
        type: 'number',
        flex: 1,
        headerClassName: styles.header,
        renderCell: (params: GridValueGetterParams<OrderModel>) => {
          return <NumberSpan>{Math.abs(params.row.positionSize)}</NumberSpan>;
        },
        valueGetter: (params: GridValueGetterParams<OrderModel>) => {
          return Math.abs(params.row.positionSize);
        }
      },
      {
        field: 'positionType',
        headerName: 'Order Action',
        sortable: false,
        filterable: false,
        flex: 1,
        headerClassName: styles.header,
        valueGetter: (params: GridValueGetterParams<OrderModel>) => {
          return params.row.positionSize < 0 ? 'Sell' : 'Buy';
        },
        renderCell: (params: GridValueGetterParams<OrderModel>) => {
          return <RedGreenSpan colorCheck={params.row.positionSize}>{params.row.positionSize < 0 ? 'Sell' : 'Buy'}</RedGreenSpan>;
        }
      },
      {
        field: 'type',
        headerName: 'Order Type',
        sortable: false,
        filterable: false,
        minWidth: 75,
        flex: 1,
        headerClassName: styles.header,
        valueGetter: (params: GridValueGetterParams<OrderModel>) => {
          return orderTypeMap[params.row.type];
        }
      },
      {
        field: 'limitPrice',
        headerName: 'Limit Price',
        minWidth: 100,
        sortable: false,
        filterable: false,
        type: 'number',
        flex: 1,
        headerClassName: styles.header,
        renderCell: (params: GridValueGetterParams<OrderModel>) => {
          const metadata = getContractById(params.row.symbolId);
          return params.row.limitPrice != null ? <ContractPriceSpan contract={metadata}>{params.row.limitPrice}</ContractPriceSpan> : <></>;
        }
      },

      {
        field: 'stopPrice',
        headerName: 'Stop Price',
        minWidth: 100,
        sortable: false,
        filterable: false,
        type: 'number',
        flex: 1,
        headerClassName: styles.header,
        renderCell: (params: GridValueGetterParams<OrderModel>) => {
          const metadata = getContractById(params.row.symbolId);
          return params.row.stopPrice != null ? <ContractPriceSpan contract={metadata}>{params.row.stopPrice}</ContractPriceSpan> : <></>;
        }
      },
      {
        field: 'status',
        headerName: 'Unlink',
        minWidth: 100,
        sortable: false,
        filterable: false,
        type: 'actions',
        flex: 1,
        headerClassName: styles.header,
        renderCell: (params: GridValueGetterParams<OrderModel>) => {
          if (linkedOrders.some((y) => y.linkedOrderIds.includes(params.row.id))) {
            const group = linkedOrders.find((y) => y.linkedOrderIds.includes(params.row.id));
            return (
              <StyledButton color='error' sx={{ padding: 0 }} size='small' disabled={working} onClick={() => onUnlinkClicked(group.groupId)}>
                Unlink
              </StyledButton>
            );
          }
          return <></>;
        }
      }
    ];

    return c;
  }, [linkedOrders, onUnlinkClicked, working]);

  const onLinkClicked = useCallback(() => {
    const resolve = showLoader('Linking Orders...', isMobile);
    setWorking(true);
    linkOrders(activeTradingAccount.accountId, rowSelectionModel as number[])
      .then((res) => {
        setWorking(false);
        resolve(res.success ? 'Orders Linked' : 'Failed to link orders: ' + res.errorMessage, !res.success);
      })
      .catch((err) => {
        setWorking(false);
        logException(err, 'Failed to link orders');
        resolve('Failed to link orders: ' + err.message, true);
      });
  }, [linkedOrders, rowSelectionModel, activeTradingAccount?.accountId, isMobile]);

  useEffect(() => {
    setRowSelectionModel((prev) => {
      if (!Array.isArray(prev) || prev.length == 0) return prev;

      return prev.filter((x) => !linkedOrders.some((y) => y.linkedOrderIds.includes(x as number)));
    });
  }, [linkedOrders, rowSelectionModel]);

  const openOrders = useMemo(() => {
    return nonSltpOrders.filter((order) => order.status == OrderStatus.Open);
  }, [nonSltpOrders]);

  const noRows = useMemo(() => {
    return (
      <Box height='100%' width='100%' display='flex' alignItems='center' justifyContent='center'>
        No Open Orders
      </Box>
    );
  }, []);

  return useMemo(
    () => (
      <TsModal open={open} onClose={onClose}>
        <Box sx={{ height: '33vh', width: '33vw', minWidth: '60em', minHeight: '30em', display: 'flex', flexDirection: 'column' }}>
          <Heading style={{ textAlign: 'center' }} size={HeadingSize.H2}>
            Link Orders
          </Heading>
          <DataGridPro
            sx={{ height: '100%', width: '100%', maxHeight: '100%', maxWidth: '100%', flex: 1 }}
            apiRef={gridRef}
            getRowId={(row) => row.id}
            isRowSelectable={(params) => !linkedOrders.some((y) => y.linkedOrderIds.includes(params.row.id))}
            rowSelection
            checkboxSelection
            rowSelectionModel={rowSelectionModel}
            onRowSelectionModelChange={setRowSelectionModel}
            rowHeight={32}
            columnHeaderHeight={32}
            rows={openOrders}
            density='compact'
            columns={columns}
            slots={{
              noRowsOverlay: () => noRows
            }}
            initialState={initialState}
            pageSizeOptions={[20, 50]}
          ></DataGridPro>
          <Box sx={{ display: 'flex', justifyContent: 'center' }}>
            <StyledButton color='error' sx={{ margin: 1 }} onClick={onClose}>
              Cancel
            </StyledButton>
            <StyledButton color='success' sx={{ margin: 1 }} disabled={working || !Array.isArray(rowSelectionModel) || (rowSelectionModel as []).length < 2} onClick={onLinkClicked}>
              Link {Array.isArray(rowSelectionModel) ? `(${rowSelectionModel.length})` : ''} Orders
            </StyledButton>
          </Box>
        </Box>
      </TsModal>
    ),
    [openOrders, rowSelectionModel, onLinkClicked, working, linkedOrders, open]
  );
};

export default LinkOrdersModal;
