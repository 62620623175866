import { useMarketStatus } from '@contexts/MarketStatusContext';
import { SoundType, useSoundNotifications } from '@hooks/useSoundNotifications';
import React, { useEffect, useRef } from 'react';
import { toast } from 'react-toastify';
import { useSettings } from '@contexts/SettingsContext';

export interface CurrentStatus {
  open: boolean | null | undefined;
  symbol: string;
}

const symbolCqgName = 'F.US.EP';
const symbol = '/ES';

const MarketStatusNotifier: React.FC = () => {
  const { isOpen } = useMarketStatus();
  const { play } = useSoundNotifications();
  const { customSettings } = useSettings();

  const currentMarketStatus = useRef<CurrentStatus>({ open: undefined, symbol: '' });

  useEffect(() => {
    const marketIsOpen = isOpen('F.US.EP');

    if (currentMarketStatus.current.symbol != symbolCqgName) {
      currentMarketStatus.current.symbol = symbolCqgName;
      currentMarketStatus.current.open = isOpen(symbolCqgName);
      return;
    }

    if (currentMarketStatus.current.open != marketIsOpen && !customSettings.supressAlerts) {
      //only notify if we are changing states from actually being open or closed
      if (currentMarketStatus.current.open === false || currentMarketStatus.current.open === true) {
        play(marketIsOpen ? SoundType.MarketsOpen : SoundType.MarketsClosed);

        toast(marketIsOpen ? `${symbol} market is now open.` : `${symbol} market is now closed`, {
          icon: marketIsOpen ? '🟢' : '🔴',
          type: marketIsOpen ? 'success' : 'error',
          pauseOnFocusLoss: false,
          hideProgressBar: true
        });
      }
      currentMarketStatus.current.open = marketIsOpen;
    }
  }, [symbolCqgName, isOpen, symbol]);

  return <></>;
};

export default React.memo(MarketStatusNotifier);
