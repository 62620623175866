import { Box, FormLabel, MenuItem, Select } from '@mui/material';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useSettings } from '@contexts/SettingsContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/pro-solid-svg-icons';
import { salesFontSize } from 'src/data/enumTypeMaps';
import { StyledButton } from '@/components/styledComponents';

interface SalesSettingsProps {
  onSaved?: () => void;
  onCancelled?: () => void;
  showButtons?: boolean;
}

const SalesSettings: React.FC<SalesSettingsProps> = ({ onSaved, onCancelled, showButtons }) => {
  const settings = useSettings();
  const [fontSize, setFontSize] = useState<salesFontSize>(settings.customSettings.salesFontSize);
  const [isLoading, setIsLoading] = useState(false);

  const updateSalesFontSize = useCallback((input: string) => {
    setFontSize(parseInt(input) as salesFontSize);
  }, []);

  useEffect(() => {
    setFontSize(settings.customSettings.salesFontSize);
  }, [settings]);

  const cancel = useCallback(() => {
    setFontSize(settings.customSettings.salesFontSize);
    onCancelled && onCancelled();
  }, [settings]);

  const onSave = useCallback(() => {
    settings.customSettings.salesFontSize = fontSize;

    setIsLoading(true);

    Promise.all([settings.saveCustomSettings(settings.customSettings)]).then(() => {
      setIsLoading(false);
      onSaved && onSaved();
    });
  }, [settings.customSettings, fontSize, settings.saveCustomSettings]);

  const isChanged = useMemo(() => {
    return fontSize !== settings.customSettings.salesFontSize;
  }, [settings, fontSize]);

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        overflowY: 'hidden',
        maxHeight: '100%'
      }}
    >
      <Box sx={{ textAlign: 'center', paddingBottom: '1.5em' }}>
        <FormLabel sx={{ fontSize: '1.35em' }}>Time and Sales Settings</FormLabel>
      </Box>
      <Box
        sx={{
          paddingTop: '2em',
          display: 'grid',
          gridAutoFlow: 'row',
          gridTemplateColumns: '1fr 1fr',
          gridGap: '1em'
        }}
      >
        <Box>
          <Box>
            <FormLabel>Time and Sales Font Size</FormLabel>
          </Box>
          <Select
            variant='outlined'
            type='number'
            value={fontSize === undefined ? salesFontSize.Regular : fontSize}
            style={{ margin: '1em 0' }}
            onChange={(t) => updateSalesFontSize(t.target.value + '')}
          >
            <MenuItem value={salesFontSize.Regular} key={salesFontSize.Regular}>
              Regular
            </MenuItem>
            <MenuItem value={salesFontSize.Large} key={salesFontSize.Large}>
              Large
            </MenuItem>
            <MenuItem value={salesFontSize.XLarge} key={salesFontSize.XLarge}>
              XLarge
            </MenuItem>
          </Select>
        </Box>
      </Box>

      <Box sx={{ display: 'flex', justifyContent: 'center' }}>
        <StyledButton color='error' disabled={isLoading} onClick={cancel} sx={{ margin: '1em', marginBottom: 0 }}>
          {isChanged ? 'Cancel' : 'Close'}
        </StyledButton>
        {isChanged && (
          <StyledButton color='success' disabled={isLoading} onClick={onSave} sx={{ margin: '1em', marginBottom: 0 }}>
            Save {isLoading && <FontAwesomeIcon spin spinPulse icon={faSpinner} />}
          </StyledButton>
        )}
      </Box>
    </Box>
  );
};

export default SalesSettings;
