import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { FeOrderResult, OrderPromptType } from '../../../../contexts/OrdersContext';
import styles from './dom.module.scss';
import { useDom } from '@contexts/DomContext';
import { formatNumber } from 'src/helpers/formatter';
import { OrderType } from '@api/userApi';
import { DomLayoutType } from '@/contexts/SettingsContext';
import { roundToTickSize } from '@/helpers/decimalHelper';
import { useDeviceContext } from '@/contexts/DeviceContext';

interface AskCellProps {
  price: number;
  orderType: OrderPromptType;
  placeOrder: (price: number, orderType: OrderPromptType, order: OrderType) => void;
  domLayoutType: DomLayoutType;
  bgColor?: string;
}

const AskCell: React.FC<AskCellProps> = ({ price, orderType, placeOrder, domLayoutType, bgColor }): JSX.Element => {
  const [hsla, setHsla] = useState<string>((bgColor && `hsla(${bgColor},`) || 'hsla(360, 100%, 24%, ');

  const { asks, lastPrice, maxAsk, bestAsk } = useDom();
  const { isMobile } = useDeviceContext();

  useEffect(() => {
    if (bgColor) {
      setHsla(`hsla(${bgColor}, `);
    }
  }, [bgColor]);

  const onMouseClick = useCallback(
    (e: React.MouseEvent) => {
      if (isMobile) {
        console.log(`price: ${price}, maxAsk: ${bestAsk}`);

        if (price < bestAsk) {
          placeOrder(price, OrderPromptType.Sell, OrderType.Stop);
          e.preventDefault();
        } else {
          placeOrder(price, OrderPromptType.Sell, OrderType.Limit);
        }
      } else if (e.button == 2) {
        placeOrder(price, OrderPromptType.Sell, OrderType.Stop);
        e.preventDefault();
      } else {
        placeOrder(price, OrderPromptType.Sell, OrderType.Limit);
      }
    },
    [price, lastPrice, placeOrder, bestAsk, isMobile]
  );

  const volume = useMemo(() => {
    // if (lastPrice > price) {
    //   return 0;
    // }
    return asks.get(price) || 0;
  }, [asks.get(price), price, lastPrice]);

  return useMemo(() => {
    if (domLayoutType === DomLayoutType.Modern) {
      return (
        <div className={styles.askCell} onClick={onMouseClick} onContextMenu={onMouseClick}>
          {volume > 0 && (
            <div className={styles.ask} style={{ width: ((volume / maxAsk) * 100).toFixed(2) + '%', background: `${hsla}100)` }}>
              &nbsp;
            </div>
          )}
          {volume > 0 && <span className={styles.bidAmount}> {formatNumber(volume)}</span>}
        </div>
      );
    } else {
      const opac = Math.log10(volume + 1) / Math.log10(maxAsk + 1);

      return (
        <div style={{ width: '100%', display: 'flex', justifyContent: 'center', cursor: 'pointer' }} onClick={onMouseClick} onContextMenu={onMouseClick}>
          <div style={{ width: 'fit-content', minWidth: '3em', textAlign: 'center', background: volume > 0 ? `${hsla}${opac})` : 'transparent' }}>
            {volume > 0 && <span style={{ color: 'white', width: '100%', textShadow: '0 0 2px black' }}>{volume}</span>}
          </div>
        </div>
      );
    }
  }, [volume, maxAsk, bestAsk, onMouseClick, domLayoutType, price, bgColor]);
};

export default React.memo(AskCell);
