import React, {  } from 'react';
import AutoSizer, { Size } from 'react-virtualized-auto-sizer';
import { Box } from '@mui/material';
import { config } from '@/config';

const Trade: React.FC = (): JSX.Element => {
  return (
    <Box height='100%' width='100' display='flex'>
      <AutoSizer id='financialjuice-eco-widget-container'>
        {(size: Size) => {
          return (
            <iframe
              scrolling='no'
              src={`https://feed.financialjuice.com/widgets/ecocal.aspx?wtype=ECOCAL&mode=table&container=financialjuice-eco-widget-container&width=${
                size.width
              }px&height=${size.height + 45}px&backC=${config.themes.dark.lightBg.replace("#","")}&fontC=b2b5be`}
              height={size.height || 0}
              width={size.width || 0}
              style={{ border: 'none', height: size.height + 45, width: size.width }}
              frameBorder='0'
            ></iframe>
          );
        }}
      </AutoSizer>
    </Box>
  );
};

export default React.memo(Trade);
