import { TradingPlatforms, config } from '@/config';
import { useAuth } from '@/contexts/AuthContext';
import { useSettings } from '@/contexts/SettingsContext';
import React, { useEffect } from 'react';
import dayjs from 'dayjs';
const IntercomTour: React.FC = () => {
  const { customSettings, saveCustomSettings } = useSettings();
  const { userInfo } = useAuth();

  useEffect(() => { 
    if (config.platform == TradingPlatforms.TopstepX && customSettings.shownIntercom !== true) {
      const cutoff = dayjs("2024-05-01");
      if(userInfo?.createdAt < cutoff) {
        return;
      }
      customSettings.shownIntercom = true;
      saveCustomSettings(customSettings);

      var wnd = window as any;
      if (wnd.Intercom) {
        wnd.Intercom('startTour', 516877);
      }
    }
  }, [userInfo.createdAt, customSettings.shownIntercom, saveCustomSettings]);

  return null;
};

export default React.memo(IntercomTour);