import React, { useCallback } from 'react';
import QuantityButton, { QuantityButtonStyle } from './quantityButton';
import { Box } from '@mui/material';
import { StyledButton } from '../styledComponents';

type QuantityButtonProps = {
  currentSize: number;
  setSize: (size: number) => void;
  disabled: boolean;
};

const QuantityButtons = ({ currentSize, setSize, disabled }: QuantityButtonProps) => {
  
  const addOne = useCallback(() => {
    setSize(currentSize + 1);
  }, [currentSize]);

  const removeOne = useCallback(() => {
    if (currentSize > 1) {
      setSize(currentSize - 1);
    }
  }, [currentSize]);

  return (
    <Box
      sx={{
        justifyContent: 'center',
        display: 'flex',
        alignItems: 'center',
        marginBottom: '0.5em'
      }}
    >
      <StyledButton disabled={disabled} color='neutral' sx={QuantityButtonStyle} onClick={removeOne} style={{ marginRight: '1.5em' }}>
        -
      </StyledButton>
      <Box
        sx={{
          justifyContent: 'center',
          display: 'flex',
          flexWrap: 'wrap',
          alignItems: 'center'
        }}
      >
        <QuantityButton quantity={1} currentSize={currentSize} setSize={setSize} disabled={disabled} />
        <QuantityButton quantity={3} currentSize={currentSize} setSize={setSize} disabled={disabled} />
        <QuantityButton quantity={5} currentSize={currentSize} setSize={setSize} disabled={disabled} />
        <QuantityButton quantity={10} currentSize={currentSize} setSize={setSize} disabled={disabled} />
        <QuantityButton quantity={15} currentSize={currentSize} setSize={setSize} disabled={disabled} />
      </Box>
      <StyledButton disabled={disabled} color='neutral' sx={QuantityButtonStyle} style={{ marginLeft: '1.5em' }} onClick={addOne}>
        +
      </StyledButton>
    </Box>
  );
};

export default QuantityButtons;
