import React, { useMemo } from 'react';
import classNames from 'classnames';
import styles from './panel.module.scss';
import Heading, { HeadingSize } from './heading';

type PanelProps = React.HTMLAttributes<HTMLDivElement> & {
  className?: string;
  title?: React.ReactNode | string;
  subTitle?: React.ReactNode | string;
  children?: React.ReactNode;
  innerClassName?: string;
  noPadding?: boolean;
};

const Panel: React.FC<PanelProps> = ({
  title,
  className,
  children,
  innerClassName,
  subTitle,
  noPadding,
  ...props
}): JSX.Element => {
  return (
    <div className={classNames(styles.panelWrapper, className)}>
      <div className={classNames(styles.panel, innerClassName)} {...props}>
        {title && (
          <Heading size={HeadingSize.H3} className={styles.title} style={{ padding: 10}}>
            {title} {subTitle && <span className={styles.subTitle}> | {subTitle}</span>}
          </Heading>
        )}
        <div className={classNames(styles.panelContent, !noPadding && styles.pad)}>{children}</div>
      </div>
    </div>
  );
};

export default React.memo(Panel);
