import classNames from 'classnames';
import React, { useMemo } from 'react';
import styles from './balance.module.scss';
import { formatPrice } from '../../helpers/formatter';
import { useTradingAccount } from '../../contexts/TradingAccountContext';
import { Tooltip } from '@mui/material';
import { useSettings } from '@contexts/SettingsContext';
import { TradingAccountType } from '@/api/userApi';
import { useDeviceContext } from '@/contexts/DeviceContext';

type BalanceProps = {
  padding?: number;
  fontSize?: number;
  variant?: 'default' | 'dark';
};

const MaxLossInfo: React.FC<BalanceProps> = (props: BalanceProps): JSX.Element => {
  const { isMobile } = useDeviceContext();
  const { activeTradingAccount } = useTradingAccount();
  const { customSettings } = useSettings();

  const maxLoss = useMemo(() => {
    return activeTradingAccount?.maximumLoss || 0;
  }, [activeTradingAccount]);

  const renderFontSize = useMemo(() => {
    switch (customSettings.topNavTextSize) {
      case 0:
        return '12px';
      case 1:
        return '14px';
      case 2:
        return '16px';
      default:
        return '12px';
    }
  }, [customSettings.topNavTextSize]);

  const backgroundColor = useMemo(() => {
    switch (props.variant) {
      case 'dark':
        return '#1c1e23';
      default:
        return '#2a292f';
    }
  }, [props.variant]);

  return useMemo(
    () =>
      activeTradingAccount?.type !== TradingAccountType.Live && (
        <div className={isMobile ? styles.balanceMobile : styles.balance} style={{ padding: props.padding, backgroundColor: backgroundColor }}>
          <Tooltip title='Maximum Loss Limit: Do not let your account balance drop below this value.'>
            <div className={styles.balanceAmount} style={{ fontSize: props.fontSize ?? renderFontSize }}>
              <span>MLL: </span>
              {customSettings.streamerModeMLL ? '******' : <span className={classNames(styles.balanceAmount)}>${formatPrice(maxLoss)}</span>}
            </div>
          </Tooltip>
        </div>
      ),
    [maxLoss, customSettings.streamerModeMLL, customSettings.topNavTextSize, isMobile]
  );
};

export default React.memo(MaxLossInfo);
