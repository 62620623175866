// notification modal

import React, { useMemo } from 'react';
import classNames from 'classnames';
import styles from './modal.module.scss';
import { DefaultTsModal, TsModal } from '@/components/modal';
import { Box, ButtonTypeMap } from '@mui/material';
import { StyledButton } from '@/components/styledComponents';
import { DefaultComponentProps } from '@mui/material/OverridableComponent';
import { config } from '@/config';

export interface NotificationModalAction {
  label: string;
  className?: string;
  props?: Partial<DefaultComponentProps<ButtonTypeMap>>;
  onClick: () => void;
}

//
// New UI Modals
//

type NotificationModalWithTipProps = {
  className?: string;
  buttonLabelOutline: string;
  buttonLabelBlue?: string;
  platform?: string;
  onClickBlue?: () => void;
  onClickOutline?: () => void;
  children: React.ReactElement;
  header: React.ReactNode;
  footer?: React.ReactNode;
};

export const NotificationModalWithTip: React.FC<NotificationModalWithTipProps> = ({
  className,
  header,
  children,
  footer,
  buttonLabelOutline,
  buttonLabelBlue,
  platform,
  onClickOutline,
  onClickBlue,
  ...props
}): JSX.Element => {
  const actions = useMemo(() => {
    const actions: NotificationModalAction[] = [{ label: buttonLabelOutline, props: { variant: 'outlined' }, onClick: onClickOutline }];
    if (buttonLabelBlue && onClickBlue && platform === 'TopstepX') {
      actions.push({ label: buttonLabelBlue, onClick: onClickBlue });
    }
    return actions;
  }, []);
  return (
    <NotificationModal header={header} className={className} footer={footer} {...props} actions={actions}>
      <div>{children}</div>
    </NotificationModal>
  );
};

export interface NotificationModalAction {
  label: string;
  className?: string;
  props?: Partial<DefaultComponentProps<ButtonTypeMap>>;
  onClick: () => void;
}
type NotificationModalProps = React.HTMLAttributes<HTMLDivElement> & {
  header: React.ReactNode;
  children: React.ReactNode;
  footer?: React.ReactNode;
  className?: string;
  actions: NotificationModalAction[];
};

const NotificationModal: React.FC<NotificationModalProps> = ({ header, children, footer, actions, className, ...props }): JSX.Element => {
  const actionsUi = useMemo(() => {
    return actions.map((action, index) => {
      return (
        <StyledButton key={index} onClick={action.onClick} sx={{ margin: '0 0.3em' }} className={action.className} {...action.props}>
          {action.label}
        </StyledButton>
      );
    });
  }, [actions]);
  return (
    <DefaultTsModal open={true} sx={{ padding: 0, margin: 0, fontSize: '1.5em' }}>
      <Box sx={{ maxWidth: '60vw' }}>
        <div className={styles.notificationHeader}>{header}</div>
        <div className={classNames(styles.notificationModal, className)} {...props}>
          <div className={styles.content}>{children}</div>
          <div className={styles.notificationButtons}>{actionsUi}</div>
        </div>
        {(footer && config.enableNotificationFooter) && <div className={styles.notificationFooter}>{footer}</div>}
      </Box>
    </DefaultTsModal>
  );
};

export default React.memo(NotificationModal);
