import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import styles from './ModalContext.module.scss';

export interface IModalContext {
  showModal(content: React.ReactNode): void;
  hideModal(): void;
}

export const ModalContext = React.createContext<IModalContext>({} as any);
export const useModal = () => React.useContext<IModalContext>(ModalContext);

function ModalContextProvider({ children }: any) {
  const [modal, setModal] = useState<React.ReactNode>(null);

  const showModal = useCallback((content: React.ReactNode) => {
    setModal(content);
  }, []);

  const hideModal = useCallback(() => {
    setModal(null);
  }, []);

  const providerValues = useMemo(() => {
    return { showModal, hideModal };
  }, []);

  const modalUi = useMemo(() => {
    if (!modal) return null;
    return (
      <div className={styles.modalWrapper}>
        <div className={styles.modal}>{modal}</div>
      </div>
    );
  }, [modal]);

  return (
    <ModalContext.Provider value={providerValues}>
      <>
        {children}
        {modalUi}
      </>
    </ModalContext.Provider>
  );
}

export default ModalContextProvider;
