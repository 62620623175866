import { getErrorMessage, logMessage } from 'datafeeds2/lib/helpers';
import dayjs from 'dayjs';
export class DataPulseProvider {
  private _subscribers: any;
  private _subscribeFunc: (symbolInfo: number, resolution: string, guid: string, callback: (data: any) => void) => number;
  private _unsubscribeFunc: (guid: string, callbackId: number) => void;
  private _isMobile: boolean;

  constructor(subscribeBars: (symbolInfo, resolution, guid, callback: (data: any) => void) => number, unsubscribeBars: (guid: string, callbackId: number) => void, mobile: boolean) {
    this._subscribeFunc = subscribeBars;
    this._unsubscribeFunc = unsubscribeBars;
    this._isMobile = mobile;
    this._subscribers = {};
  }

  subscribeBars(symbolInfo, resolution, newDataCallback, listenerGuid) {
    if (this._subscribers.hasOwnProperty(listenerGuid)) {
      logMessage(`DataPulseProvider: already has subscriber with id=${listenerGuid}`);
      return;
    }

    //console.log('Subscribing', listenerGuid);

    this._subscribers[listenerGuid] = {
      lastBarTime: null,
      listener: newDataCallback,
      resolution: resolution,
      symbolInfo: symbolInfo
    };
    logMessage(`DataPulseProvider: subscribed for #${listenerGuid} - {${symbolInfo.name}, ${resolution}}`);
    this._subscribers[listenerGuid].callbackId = this._subscribeFunc(symbolInfo.symbolId, resolution, listenerGuid, (data) => this._updateDataForSubscriber(listenerGuid, data));
  }
  unsubscribeBars(listenerGuid) {
    if (this._subscribers.hasOwnProperty(listenerGuid)) {
      const subInfo = this._subscribers[listenerGuid];

      // console.log('unsubscribing', listenerGuid);
      delete this._subscribers[listenerGuid];
      this._unsubscribeFunc(listenerGuid, subInfo.callbackId);
      logMessage(`DataPulseProvider: unsubscribed for #${listenerGuid}`);
    }
  }

  randomValue(min, max) {
    min = Math.ceil(min);
    max = Math.floor(max);
    return Math.floor(Math.random() * (max - min) + min); // The maximum is exclusive and the minimum is inclusive
  }

  _updateDataForSubscriber(listenerGuid, data) {
    const subscriptionRecord = this._subscribers[listenerGuid];
    if (!subscriptionRecord) return;

    // console.log('Bar Update', data);

    const barData = data;
    const barValue = {
      time: dayjs(data.timestamp).unix() * 1000,
      close: barData.close,
      open: barData.open,
      high: barData.high,
      low: barData.low,
      volume: barData.volume,
      isClosed: barData.isClosed || false
    };

    if (data.isQuote && !subscriptionRecord.lastBarTime) {
      return;
    }

    // if(this._isMobile !== true && !data.isQuote && subscriptionRecord.lastBarTime && !barValue.isClosed) {
    //   barValue.close = subscriptionRecord.lastBar.close;
    // }

    const isQuoteUpdate = data.isQuote === true && subscriptionRecord.lastBarTime && subscriptionRecord.lastBar;

    if (isQuoteUpdate) {
      barValue.time = subscriptionRecord.lastBarTime;
      barValue.open = subscriptionRecord.lastBar.open;
      barValue.high = Math.max(barValue.close, subscriptionRecord.lastBar.high);
      barValue.low = Math.min(barValue.close, subscriptionRecord.lastBar.low);
      barValue.close = barValue.close;
      barValue.volume = subscriptionRecord.lastBar.volume;
    }

    if (barValue.time < subscriptionRecord.lastBarTime) {
      //console.log("Ignoring this bar. It's older than the last one in the subscriptionRecord");
      return; // ignore this bar, it's older than the last one in the subscriptionRecord
    }

    subscriptionRecord.lastBar = barValue;

    //console.log('Received bar', dayjs.utc(barValue.time).local().toISOString(), barValue);

    if (subscriptionRecord.lastBarTime === barValue.time) {
      if (subscriptionRecord.isNewBar) {
        if (barValue.open) {
          //console.log('New Bar is fully formed, calling listener');
          subscriptionRecord.isNewBar = false;
          subscriptionRecord.listener(barValue);
        }
      } else {
        subscriptionRecord.listener(barValue);
      }
    } else {
      //console.log('New Bar, checking open status');
      subscriptionRecord.isNewBar = true;
      subscriptionRecord.lastBarTime = barValue.time;
      if (barValue.open) {
        //console.log('New Bar is fully formed, calling listener');
        subscriptionRecord.listener(barValue);
        subscriptionRecord.isNewBar = false;
      } else {
        //console.log('Bar is not fully formed yet');
      }
    }

    if (barValue.isClosed) {
      subscriptionRecord.lastBarTime = null;
    }
  }
}
