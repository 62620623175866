import { useApi } from '@contexts/ApiContext';
import { Box, Modal, ModalProps, Theme, styled } from '@mui/material';
import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
interface CustomModalProps extends ModalProps {
  childSx?: React.CSSProperties;
}
export const DefaultTsModal: React.FC<ModalProps> = (props) => {
  return (
    <StyledModal {...props} aria-labelledby='parent-modal-title' aria-describedby='parent-modal-description' slots={{ backdrop: StyledBackdrop }}>
      <Box sx={defaultStyle}>{props.children}</Box>
    </StyledModal>
  );
};
export const TsModal: React.FC<CustomModalProps> = (props) => {
  return (
    <StyledModal {...props} aria-labelledby='parent-modal-title' aria-describedby='parent-modal-description' slots={{ backdrop: StyledBackdrop }}>
      <Box
        sx={(theme) => {
          return { ...style(theme), ...(props.childSx ?? {}) };
        }}
      >
        {props.children}
      </Box>
    </StyledModal>
  );
};

const Backdrop = React.forwardRef<HTMLDivElement, { open?: boolean; className: string }>((props, ref) => {
  const { open, className, ...other } = props;
  return <div className={clsx({ 'MuiBackdrop-open': open }, className)} ref={ref} {...other} />;
});

const blue = {
  200: '#99CCF3',
  400: '#3399FF',
  500: '#007FFF'
};

const grey = {
  50: '#f6f8fa',
  100: '#eaeef2',
  200: '#d0d7de',
  300: '#afb8c1',
  400: '#8c959f',
  500: '#6e7781',
  600: '#57606a',
  700: '#424a53',
  800: '#32383f',
  900: '#24292f'
};

export const StyledModal = styled(Modal)`
  position: fixed;
  z-index: 1300;
  inset: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  outline: none;
`;

export const StyledBackdrop = styled(Backdrop)`
  z-index: -1;
  position: fixed;
  inset: 0;
  background-color: rgb(0 0 0 / 0.5);
  -webkit-tap-highlight-color: transparent;
`;
const defaultStyle = (theme: Theme) => ({
  width: 'auto',
  borderRadius: '12px',
  backgroundColor: theme.palette.mode === 'dark' ? theme.palette.darkBg : 'white',
  boxShadow: `0px 2px 24px ${theme.palette.mode === 'dark' ? '#000' : '#383838'}`,
  outline: 'none'
});

const style = (theme: Theme) => ({
  width: 'auto',
  borderRadius: '12px',
  padding: '16px 32px 24px 32px',
  backgroundColor: theme.palette.mode === 'dark' ? theme.palette.darkBg : 'white',
  boxShadow: `0px 2px 24px ${theme.palette.mode === 'dark' ? '#000' : '#383838'}`,
  outline: 'none'
});

const TriggerButton = styled('button')(
  ({ theme }) => `
  font-family: IBM Plex Sans, sans-serif;
  font-size: 0.875rem;
  font-weight: 600;
  box-sizing: border-box;
  min-height: calc(1.5em + 22px);
  border-radius: 12px;
  padding: 6px 12px;
  line-height: 1.5;
  background: transparent;
  border: 1px solid ${theme.palette.mode === 'dark' ? grey[800] : grey[200]};
  color: ${theme.palette.mode === 'dark' ? grey[100] : grey[900]};

  &:hover {
    background: ${theme.palette.mode === 'dark' ? grey[800] : grey[50]};
    border-color: ${theme.palette.mode === 'dark' ? grey[600] : grey[300]};
  }

  &:focus-visible {
    border-color: ${blue[400]};
    outline: 3px solid ${theme.palette.mode === 'dark' ? blue[500] : blue[200]};
  }
  `
);
