import classNames from 'classnames';
import React, { useCallback, useMemo, useState } from 'react';
import styles from './layoutSelector.module.scss';
import { IExtendedTradingAccountModel, useTradingAccount } from '../../contexts/TradingAccountContext';
import { useApi } from '../../contexts/ApiContext';
import { ITradingAccountModel, SetAccountNicknameRequest, SetAccountNicknameResultCode, TradingAccountStatus, TradingAccountType } from '../../api/userApi';
import { Box, Button, IconButton, MenuItem, Select, SelectChangeEvent, TextField, Typography } from '@mui/material';
import { tradingAccountStatusMap, violationTypeMap } from 'src/data/enumTypeMaps';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPersonWalkingArrowRight } from '@fortawesome/pro-solid-svg-icons';
import { faPersonWalkingArrowLoopLeft } from '@fortawesome/pro-solid-svg-icons';
import copyTradingStyles from './copyTrading.module.scss';
import { useDeviceContext } from '@/contexts/DeviceContext';
import { config } from '@/config';
import { useSettings } from '@contexts/SettingsContext';
import EditIcon from '@mui/icons-material/Edit';
import { TsModal } from '../modal';
import { toast, UpdateOptions } from 'react-toastify';

interface AccountSelectorProps {
  style?: React.CSSProperties;
}
const AccountSelector: React.FC<AccountSelectorProps> = (props): JSX.Element => {
  const { activeTradingAccount, tradingAccounts, setActiveTradingAccount } = useTradingAccount();
  const { isMobile } = useDeviceContext();
  const { customSettings } = useSettings();
  const [nicknameModalAccount, setNicknameModalAccount] = useState<ITradingAccountModel>(null);
  const [newNickname, setNewNickname] = useState('');
  const { tradingAccountApi } = useApi();

  const handleOnChange = useCallback(
    (e: SelectChangeEvent) => {
      const account = tradingAccounts.find((x) => x.accountId.toString() === e.target.value.toString());
      if (account) {
        setActiveTradingAccount(account);
      }
    },
    [tradingAccounts, setActiveTradingAccount]
  );

  const getAccountType = useCallback((tradingAccount: ITradingAccountModel) => {
    switch (tradingAccount.type) {
      case TradingAccountType.Express:
        return `$${tradingAccount.combineName} ${config.accountTypes.funded}`;
      case TradingAccountType.Live:
        return config.accountTypes.live;
      case TradingAccountType.None:
      case TradingAccountType.Sim:
        return `$${tradingAccount.combineName} ${config.accountTypes.sim}`;
      case TradingAccountType.Practice:
        return `$${tradingAccount.combineName} ${config.accountTypes.practice}`;
    }
  }, []);

  const accountColor = useCallback((tradingAccount: ITradingAccountModel) => {
    switch (tradingAccount.type) {
      case TradingAccountType.Express:
        return '#FFCB05';
      case TradingAccountType.Live:
        return '#4CB548';
      case TradingAccountType.None:
      case TradingAccountType.Sim:
        return '#E6E8EF';
      case TradingAccountType.Practice:
        return '#0092DB';
    }
  }, []);

  const handleClickNickname = (e: any, account: ITradingAccountModel) => {
    e.stopPropagation();
    setNicknameModalAccount(account);
    setNewNickname(account.nickname ?? '');
  };

  const handleSaveNickname = () => {
    setNicknameModalAccount(null);
    setNewNickname('');

    const promise = new Promise<void>((resolve, reject) => {
      tradingAccountApi.setAccountNickname(new SetAccountNicknameRequest({
        accountId: nicknameModalAccount.accountId,
        nickname: newNickname
      })).then((res) => {
        if (res === SetAccountNicknameResultCode.Success) {
          const updatedAccount = tradingAccounts.find((x) => x.accountId === nicknameModalAccount.accountId);
          updatedAccount.nickname = newNickname;

          resolve();
        }

        if (res === SetAccountNicknameResultCode.NameAlreadyUsed) {
          reject('Nickname already used');
        }

        if (res === SetAccountNicknameResultCode.NameTooLong) {
          reject('Nickname too long');
        }

        reject('Error saving nickname');
      });
    });

    toast.promise(promise, {
      pending: 'Saving nickname...',
      success: 'Nickname saved',
      error : {render({data}){
        // When the promise reject, data will contains the error
        return <span>{data.toString()}</span>
      }}
    });
  };

  const handleDeleteNickname = () => {
    setNicknameModalAccount(null);
    setNewNickname('');

    tradingAccountApi.setAccountNickname(new SetAccountNicknameRequest({
      accountId: nicknameModalAccount.accountId,
      nickname: null
    })).then((res) => {
      if (res === SetAccountNicknameResultCode.Success) {
        const updatedAccount = tradingAccounts.find((x) => x.accountId === nicknameModalAccount.accountId);
        updatedAccount.nickname = '';
      }
    });
  };

  const handleCancelNickname = () => {
    setNicknameModalAccount(null);
    setNewNickname('');
  };

  const renderTradingAccount = useCallback((x: IExtendedTradingAccountModel, selected: boolean = false) => {
    let sx = {};
    if (selected) {
      sx = { padding: 0 };
    }
    return (
      <MenuItem value={x.accountId} key={x.accountId} sx={sx}>
        <Box sx={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>
          
          {!selected && (
            <IconButton 
              style={{ padding: 0, margin: '0 0.5em 0.1em 0', color: 'gray', cursor: 'pointer' }} 
              onClick={(e) => handleClickNickname(e, x)}
            >
              <EditIcon sx={{ fontSize: '0.8em' }} />
            </IconButton>
          )}
          

          <span style={{ color: accountColor(x), textTransform: 'uppercase', minWidth: '7em', textAlign: 'right', fontWeight: 'bold' }}>{getAccountType(x)}</span>
          <span style={{ padding: '0 0.4em' }}>|</span>
          {x.nickname ? (
            <span>{x.nickname} </span>
          ) : (
            <span>{customSettings.streamerModeAccountName ? '******' :x.accountName}</span>
          )}
          
          {x.status != TradingAccountStatus.Active && !x.activeViolation && <span style={{ marginLeft: '0.3em' }}>({tradingAccountStatusMap[x.status]}) </span>}

          {x.nickname ? <span>({customSettings.streamerModeAccountName ? '******' :x.accountName})</span> : null}

          {x.activeViolation && <span style={{ marginLeft: '0.3em' }}>({violationTypeMap[x.activeViolation.type]})</span>}
          {x.isFollower && !selected && (
            <div style={{ display: 'inline-block' }}>
              <span className={copyTradingStyles.follower} style={{ width: '3em', marginLeft: '0.5em' }}>
                <FontAwesomeIcon icon={faPersonWalkingArrowLoopLeft} style={{ marginRight: 3 }} /> F
              </span>
            </div>
          )}
          {x.isLeader && !selected && (
            <div style={{ display: 'inline-block' }}>
              <span className={copyTradingStyles.leader} style={{ width: '3em', marginLeft: '0.5em' }}>
                <FontAwesomeIcon icon={faPersonWalkingArrowRight} style={{ marginRight: 3 }} /> L
              </span>
            </div>
          )}
        </Box>
      </MenuItem>
    );
  }, [customSettings.streamerModeAccountName]);

  const renderAccounts = useMemo(() => {
    const menuitems = [];

    for (const acc of tradingAccounts) {
      if (acc.isLeader) {
        menuitems.push(renderTradingAccount(acc));
        for (const acc of tradingAccounts) {
          if (acc.isFollower) {
            menuitems.push(renderTradingAccount(acc));
          }
        }
      } else if (acc.isFollower) {
        continue;
      } else {
        menuitems.push(renderTradingAccount(acc));
      }
    }
    return menuitems;
  }, [tradingAccounts, renderTradingAccount]);

  return useMemo(
    () => (
      <>
        <div className={classNames(styles.wrapper)} style={props.style}>
          <Select
            className={classNames(isMobile && styles.mobileSelect)}
            // SelectDisplayProps={{ style: { padding: '0.5em' } }}
            style={{ width: '100%' }}
            size='small'
            onChange={handleOnChange}
            value={activeTradingAccount?.accountId + ''}
            renderValue={(value) => {
              const acc = tradingAccounts.find((y) => y.accountId.toString() == value);
              if (!acc) return <span>None</span>;
              return renderTradingAccount(acc, true);
            }}
            MenuProps={
              isMobile
                ? {
                    style: {
                      maxHeight: '50vh'
                    }
                  }
                : undefined
            }
          >
            {renderAccounts}
          </Select>
        </div>
        <TsModal
          sx={{ position: 'fixed' }}
          open={nicknameModalAccount != null}
          onClose={() => setNicknameModalAccount(null)}
        >
          <>
            <Typography variant='h5' color='white'>Edit Account Nickname for {nicknameModalAccount?.accountName}</Typography>
            <TextField
              sx={{ width: '100%', padding: '0.1em', margin: '1em 0' }}
              value={newNickname}
              onChange={(e) => setNewNickname(e.target.value)}
              variant='outlined'
              size='small'
            />
            <Box display='flex' flexDirection='row' gap='1em'>
              {/* Save, cancel, delete buttons. Only show save if it's been changed. Only show delete if it's not null. */}
              {newNickname !== nicknameModalAccount?.nickname && newNickname.length > 0 && (
                <Button 
                  color='primary'
                  variant='contained'
                  onClick={handleSaveNickname}
                >
                  Save
                </Button>
              )}
              {nicknameModalAccount?.nickname && (
                <Button
                  color='secondary'
                  variant='contained'
                  onClick={handleDeleteNickname}
                >
                  Delete
                </Button>
              )}
              <Button 
                color='secondary'
                variant='outlined'
                onClick={handleCancelNickname}
              >
                Cancel
              </Button>
            </Box>
          </>

        </TsModal>
      </>
    ),
    [isMobile, tradingAccounts, activeTradingAccount, handleOnChange, props.style, renderAccounts, nicknameModalAccount, newNickname, renderTradingAccount]
  );
};

export default React.memo(AccountSelector);
