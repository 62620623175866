import React, { useEffect, useState, useRef } from 'react';
import dayjs, { Dayjs } from 'dayjs';
import { useAuth } from '@/contexts/AuthContext';
import { toast } from 'react-toastify';
import { useVisibility } from '@/contexts/VisibilityContext';

const AutoLogout = () => {
  const [hideTime, setHideTime] = useState<Dayjs>(null);
  const hideTimeRef = useRef<Dayjs>(null);
  const { logOut } = useAuth();
  const { visible } = useVisibility();

  const onShow = () => {
    if (hideTimeRef.current === null) return;

    if (dayjs().diff(hideTimeRef.current, 'minutes') >= 120) {
      toast.error('Logged out due to inactivity.', {
        autoClose: false
      });
      logOut();
    }

    hideTimeRef.current = null;
  };

  const onHide = () => {
    const currentHideTime = dayjs();
    setHideTime(currentHideTime);
    hideTimeRef.current = currentHideTime;
  };

  useEffect(() => {
    if (visible) {
      onShow();
    } else {
      onHide();
    }
  }, [visible]);

  return <div></div>;
};

export default AutoLogout;
