import {
  IChartWidgetApi,
  IChartingLibraryWidget,
  IOrderLineAdapter,
  ResolutionString,
  Timezone,
  widget,
  TradingTerminalWidgetOptions,
  IActionVariant,
  ActionsFactory,
  ActionId,
  CustomIndicator,
  WatermarkContentData,
  IPositionLineAdapter
} from '@/charting_library';
import { QuotesProvider } from 'datafeeds2/lib/quotes-provider';
import { Requester } from 'datafeeds2/lib/requester';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Box, MenuItem, Stack, TextField } from '@mui/material';
import { TopStepDataFeed } from '../../../../datafeed/datafeed';
import { useCqg } from '../../../../contexts/CqgContext';
import { useSymbol } from '../../../../contexts/SymbolContext';
import { OrderPromptType, calculatePnl, useOrders } from '../../../../contexts/OrdersContext';
import { AuthorizationHeaders, useApi } from '../../../../contexts/ApiContext'
import config, { TradingPlatforms } from '../../../../config';
import { IPositionModel, OrderModel, OrderStatus, OrderType, TradingAccountStatus, SymbolMetadata } from '../../../../api/userApi';
import { useTradingAccount } from '@/contexts/TradingAccountContext';
import { useLinked } from '@/contexts/LinkedContext';
import { TsModal } from '@/components/modal';
import { StyledButton } from '@/components/styledComponents';
import ChartSettings from '@/components/topstep/chartSettings';
import { ChartGroup, ChartInstanceData } from '@/views/trader/components/charts/chartTypes';
import ChartPosition from '@/views/trader/components/charts/chartPosition';
import ChartOrders from '@/views/trader/components/charts/chartOrders';
import ChartTradeExecutions from '@/views/trader/components/charts/chartTradeExecutions';
import styles from './mobileChart.module.scss';
import { QuoteData } from '@/models/quoteData';
import { ContractPriceSpan } from '@/components/numbers';
import CommonOrderOptions from '@/components/topstep/commonOrderOptions';
import QuantityButtons from '@/components/topstep/quantityButtons';
import { SaveLoadAdater } from '@/views/trader/components/charts/saveLoadAdapter';
import MobileWebChartTrade from './mobileWebChartTrade';
import { roundToNearestTick, roundToTickSize } from 'src/helpers/decimalHelper';
import { set } from 'lodash';
import { useSettings } from '@/contexts/SettingsContext';
import HoagsIndicator, { HoagsLevels } from './hoagsIndicator';
import { StrapiHoagsModel } from '@/api/dataApi';
import { useModal } from '@/contexts/ModalContext';
import ConfirmOrderModal from '@/components/topstep/confirmOrderModal';

interface SubInfoCallback {
  symbolId: string;
  callbackId: number;
  guid: string;
  currentQuote?: number;
  lastQuote?: number;
  timer: NodeJS.Timeout;
  callback: (data: any) => void;
}

interface ChartState {
  name: string;
  layout: string;
  charts: object[];
  symbolLock: number;
  intervalLock: number;
  trackTimeLock: number;
  dateRangeLock: number;
  crosshairLock: number;
  layoutsSizes: object;
}

interface SubInfo {
  symbolId: string;
  callback: string;
  subId: number;
}

interface ChartProps {
  tabData: {
    layoutName: string;
    onChanged: () => void;
  };

  layoutName?: string;
}

const tradeAmounts = [1, 2, 3, 4, 5, 10, 15];

const MobileWebChart: React.FC<ChartProps> = (props): JSX.Element => {
  const { tabData, layoutName } = props;
  const apiContext = useApi();
  const { getContractByFriendlyName, getContractById, contract, setContract: setMobileContract } = useSymbol();
  const { activePositions, editSltpSetting } = useOrders();
  const containerRef = useRef(null);
  const [tvWidget, setTvWidget] = useState<IChartingLibraryWidget>(null);
  const { activeTradingAccount } = useTradingAccount();
  const { subscribeBars, unsubscribeBars, subscribeQuotesForSymbol, unsubscribeQuotesForSymbol, subscribeOnChartReconnect, unsubscribeOnChartReconnect } = useCqg();
  const { changeOrderPrice, cancelOrder, nonSltpOrders: orders, placeOrderWithSymbol: placeOrder } = useOrders();
  const isMounted = useRef(true);
  const canTrade = useRef(true);
  const [showChartSettings, setShowChartSettings] = useState(false);
  const [quoteData, setQuoteData] = useState<QuoteData>(undefined);
  const [showFlash, setShowFlash] = useState<string>();
  const [prevLastPrice, setPrevLastPrice] = useState<number | null>(null); // Keep track of previous price
  const [showSlideOut, setShowSlideOut] = useState<boolean>(false);
  const [chartReady, setChartReady] = useState<boolean>(false);
  const { showConfirmations: confirmation } = useSettings();
  const { hideModal, showModal } = useModal();

  useEffect(() => {
    if (!contract) {
      return;
    }

    let initialQuote = true;
    const id = subscribeQuotesForSymbol(contract.symbol, (quote) => {
      setQuoteData((prev) => {
        if (initialQuote) {
          initialQuote = false;
        }
        return {
          ...prev,
          ...quote
        };
      });
    });

    /*
    if (chartReady) {
      const chart = tvWidget.activeChart();
      if (chart) {
        chart.setSymbol(contract?.friendlyName);
      }
    }
    */

    return () => {
      setQuoteData(undefined);
      unsubscribeQuotesForSymbol(contract.symbol, id);
    };
  }, [contract]);

  const { customSettings, saveCustomSettings } = useSettings();
  const [hideEconomicEvents, setHideEconomicEvents] = useState<boolean>(customSettings.hideEconomicEvents);

  useEffect(() => {
    setHideEconomicEvents(customSettings.hideEconomicEvents);
}, [customSettings.hideEconomicEvents]);

  useEffect(() => {
    if (!topStepFeed.current) return;

    if (customSettings.hideEconomicEvents === true) {
      topStepFeed.current.disableMarks();
    } else {
      topStepFeed.current.enableMarks();
    }

    if (!tvWidget) return;

    var chartCount = tvWidget.chartsCount();

    for (let i = 0; i < chartCount; i++) {
      var chart = tvWidget.chart(i);
      if (hideEconomicEvents === true) {
        chart.clearMarks();
      } else {
        chart.refreshMarks();
      }
    }
  }, [tvWidget, hideEconomicEvents]);

  useEffect(() => {
    console.log('Reconnected to chart data. Requesting fresh bars.');

    const onReconnectCallback = () => {
      if (widgetRef.current) {
        for (let i = 0; i < widgetRef.current.chartsCount(); i++) {
          const chart = widgetRef.current.chart(i);
          topStepFeed.current.resetCache(chart.symbol());
        }
        for (let i = 0; i < widgetRef.current.chartsCount(); i++) {
          const chart = widgetRef.current.chart(i);
          chart.resetData();
        }
      }
    };
    subscribeOnChartReconnect(onReconnectCallback);

    return () => {
      unsubscribeOnChartReconnect(onReconnectCallback);
    };
  }, []);

  const widgetRef = useRef<IChartingLibraryWidget>(null);
  const subscribeBarsCallbackRef = useRef<Map<string, SubInfoCallback[]>>(new Map());
  const subscriptions = useRef<Map<string, SubInfo>>(new Map());

  const [chartGroups, setChartGroups] = useState<ChartGroup[]>([]);
  const linkedContext = useLinked();
  const { links } = useLinked();
  const linksRef = useRef(links);

  const topStepFeed = useRef<TopStepDataFeed>(null);

  useEffect(() => {
    canTrade.current = activeTradingAccount.isFollower !== true;
  }, [activeTradingAccount]);

  useEffect(() => {
    return () => {
      console.log('Unmounting');
      isMounted.current = false;
    };
  }, []);

  const unsubscribeBarsWrapper = useCallback(
    (guid: string, callbackId: number) => {
      const subscriptionSymbol = subscriptions.current.get(guid);
      if (subscriptionSymbol) {
        let existingSubsBySymbol = subscribeBarsCallbackRef.current.get(subscriptionSymbol.symbolId);
        const subInfoCallback = existingSubsBySymbol.find((x) => x.callbackId == callbackId);
        if (subInfoCallback) {
          clearInterval(subInfoCallback.timer);
        }
        existingSubsBySymbol = existingSubsBySymbol.filter((x) => x.guid != guid);
        subscribeBarsCallbackRef.current.set(subscriptionSymbol.symbolId, existingSubsBySymbol);
      }

      return unsubscribeBars(guid, callbackId);
    },
    [unsubscribeBars]
  );

  const subscribeBarsWrapper = useCallback((symbolId: number, resolution: string, guid: string, callback: (data: any) => void) => {
    const callbackId = subscribeBars(symbolId, resolution, guid, callback);

    let existingSubs = subscribeBarsCallbackRef.current.get(symbolId + '');
    if (!existingSubs) {
      existingSubs = [];
    }

    const obj: SubInfoCallback = {
      callback: callback,
      callbackId: callbackId,
      guid: guid,
      symbolId: symbolId + '',
      lastQuote: null,
      currentQuote: null,
      timer: setInterval(() => {
        if (obj.currentQuote != obj.lastQuote) {
          obj.lastQuote = obj.currentQuote;
          callback({ isQuote: true, close: obj.lastQuote });
        }
      }, 500)
    };
    existingSubs.push(obj);
    subscriptions.current.set(guid, {
      callback: callbackId + '',
      subId: symbolId,
      symbolId: symbolId + ''
    });
    subscribeBarsCallbackRef.current.set(symbolId + '', existingSubs);
    return callbackId;
  }, []);

  const appliedLastChart = useRef(false);

  const updateCharts = useCallback(() => {
    const newTvWidget = widgetRef.current;

    const charts: ChartGroup[] = [];
    const activeCharts: IChartWidgetApi[] = [];
    for (let i = 0; i < newTvWidget.chartsCount(); i++) {
      const chart = newTvWidget.chart(i);
      activeCharts.push(chart);

      chart.onSymbolChanged().subscribe(chart, () => {
        chart.onSymbolChanged().unsubscribeAll(chart);

        if (chart === newTvWidget.activeChart()) {
          setMobileContract(getContractByFriendlyName(chart.symbol()));
        }

        chart.dataReady(() => {
          updateCharts();
        });
      });

      if (!appliedLastChart.current) {
        // if this is the last active mobile chart, set the symbol to the last active contract
        const lastActiveMobileChart = localStorage.getItem('lastActiveMobileChart');
        if (lastActiveMobileChart && parseInt(lastActiveMobileChart) === i) {
          newTvWidget.setActiveChart(i);
          appliedLastChart.current = true;
          chart.setSymbol(contract?.friendlyName);

          const clean = getContractByFriendlyName(contract?.friendlyName);
          setMobileContract(clean);
        }
      }
    }

    const chartsBySymbol = activeCharts.reduce((acc, chart) => {
      let symbol = chart.symbol();
      let symbolId = getContractByFriendlyName(symbol)?.symbol;
      if (!symbolId) {
        symbolId = symbol;
      }
      if (!acc[symbolId]) {
        acc[symbolId] = [];
      }
      acc[symbolId].push(chart);
      return acc;
    }, {});

    for (const symbol in chartsBySymbol) {
      const metadata = getContractById(symbol);
      const inst: ChartGroup = {
        symbol,
        actualSymbol: symbol,
        metadata,
        eventHandlers: [],
        updateTimer: setInterval(() => {
          if (inst.lastPriceForPnl != inst.lastPrice) {
            inst.lastPriceForPnl = inst.lastPrice;
            for (const handler of inst.eventHandlers) {
              handler(inst);
            }
          }
        }, 500),
        charts: chartsBySymbol[symbol].map((x) => {
          const chartInst: ChartInstanceData = {
            chart: x,
            positionId: null,
            positionLine: null,
            stopLossLine: null,
            takeProfitLine: null,
            lastPnl: null,
            lastSize: null,
            lastAvgPrice: null,
            lastStoploss: null,
            lastTakeProfit: null,
            lastRisk: null,
            lastToMake: null
          };
          return chartInst;
        }),
        tickSize: metadata?.tickSize,
        lastPrice: null,
        lastPriceForPnl: null
      };

      for (const i of inst.charts) {
        (i.chart as any).chartGrp = inst;
      }

      charts.push(inst);
    }

    setChartGroups(charts);
  }, []);

  useEffect(() => {
    if (tvWidget) {
      updateCharts();
    }
  }, [tvWidget]);

  const hoagsData = useRef<StrapiHoagsModel>(null);

  const getHoagsData = () => {
    apiContext.strapiApi.getHoags().then((data) => {
      hoagsData.current = data;
    });
  };

  // Load hoags data every 5 minutes
  useEffect(() => {
    getHoagsData();
    const interval = setInterval(() => {
      getHoagsData();
    }, 1000 * 60 * 300);
    return () => clearInterval(interval);
  }, []);

  const getHoagsLevels = (symbol: string) => {
    console.log('Hoags data', hoagsData);

    const data = hoagsData?.current?.data?.find((x) => x.attributes.symbol === symbol)?.attributes;

    console.log('Hoags data for symbol ' + symbol, data);

    const hoagsLevels: HoagsLevels = {
      LWH: data?.lastWeekHigh,
      YH: data?.yesterdayHigh,
      YVAH: data?.yesterdayVAH,
      VPOC: data?.yesterdayVPOC,
      S: data?.settlement,
      YVAL: data?.yesterdayVAL,
      YL: data?.yesterdayLow,
      WKOL: data?.wkoLow,
      WKOH: data?.wkoHigh,
      LWL: data?.lastWeekLow,
    };

    return hoagsLevels;
  };

  //creates the chart widget
  useEffect(() => {
    const requester = new Requester(AuthorizationHeaders);
    const saveLoadAdapter = new SaveLoadAdater(apiContext, linkedContext, true);

    const quotesProvider = new QuotesProvider(config.chartApi, requester);
    //test
    var feed = new TopStepDataFeed(config.chartApi, quotesProvider, requester, subscribeBarsWrapper, unsubscribeBarsWrapper, links, undefined, true);
    topStepFeed.current = feed;
    if (!containerRef.current) return;
    let libraryUrl = '/';

    if (process.env.NODE_ENV !== 'development') {
      libraryUrl = '/charting_library/';
    }

    const tz = Intl.DateTimeFormat().resolvedOptions().timeZone as Timezone;

    const widgetOptions: TradingTerminalWidgetOptions = {
      symbol: contract?.friendlyName ?? '/ES',
      datafeed: feed,
      interval: '15' as ResolutionString,
      save_load_adapter: saveLoadAdapter,
      //interval: 'H' as ResolutionString,
      time_frames: [
        {
          text: '1y',
          resolution: '1D' as any,
          description: '1 Year',
          title: '1yr'
        },
        {
          text: '3m',
          resolution: '1D' as any,
          description: '3 Month'
        },
        {
          text: '1m',
          resolution: '1D' as any,
          description: '1 Month'
        },
        {
          text: '5d',
          resolution: '30',
          description: '5 Days'
        },
        {
          text: '3d',
          resolution: '15',
          description: '3 Day'
        },
        {
          text: '1d',
          resolution: '30',
          description: '1 Day'
        }
      ],
      container: containerRef.current,
      library_path: libraryUrl,
      auto_save_delay: 3,
      load_last_chart: true,
      locale: 'en',
      theme: 'dark',
      custom_indicators_getter(PineJS) {
        return new Promise<CustomIndicator[]>(async (resolve) => {

          let indicators = [];

          if (config.platform === TradingPlatforms.TopstepX){
            indicators.push(HoagsIndicator(PineJS, 1000 * 60 * 5, getHoagsLevels));
          }

          resolve(indicators);
        });
      },
      disabled_features: [
        'popup_hints',
        'order_panel',
        'open_account_manager',
        'trading_account_manager',
        // 'object_tree_legend_mode',
        // 'show_object_tree',
        'timeframes_toolbar',
        'header_compare',
        'header_fullscreen_button',
        'header_undo_redo',
        'header_screenshot',
        'header_settings',
        'symbol_info',
        'use_localstorage_for_settings'
      ],
      custom_font_family: "'Roboto Condensed', sans-serif",
      client_id: 'topstep',
      user_id: apiContext.token,
      timezone: tz,
      fullscreen: false,
      autosize: true,

      enabled_features: ['saveload_separate_drawings_storage', 'hide_left_toolbar_by_default', 'tick_resolution', 'seconds_resolution', 'create_volume_indicator_by_default', 'countdown', 'buy_sell_buttons'],
      studies_overrides: {},
      favorites: {
        indicators: ['Moving Average', 'VWAP', 'Volume Profile Fixed Range'],
        chartTypes: ['Bars', 'Candles']
      },
      studies_access: {
        type: 'black',
        tools: [
          {
            name: 'Pivot Points Standard'
          },
          {
            name: '52 Week High/Low'
          },
          {
            name: 'Link'
          }
        ]
      },

      overrides: {
        // Long Position
        'linetoolriskrewardlong.lotSize': 1,
        'linetoolriskrewardlong.compact': true,
        'linetoolriskrewardlong.alwaysShowStats': false,
        'linetoolriskrewardlong.showPriceLabels': false,

        // Short Position
        'linetoolriskrewardshort.lotSize': 1,
        'linetoolriskrewardshort.compact': true,
        'linetoolriskrewardshort.alwaysShowStats': false,
        'linetoolriskrewardshort.showPriceLabels': false
      },
      settings_overrides: {
        'panelProperties.legendProperties.showSeriesTitle': false
      },

      custom_css_url: '/mobileChartTheme.css'
    };

    const newTvWidget = new widget(widgetOptions);

    widgetRef.current = newTvWidget;
    let setWatermark = false;

    newTvWidget.subscribe('layout_about_to_be_changed', () => {
      try {
        if (!setWatermark) {
          newTvWidget.watermark().setContentProvider((data: WatermarkContentData) => {
            const info = data.symbolInfo as any;
            return [
              { text: `${info.watermarkTitle}, ${data.interval}`, fontSize: 84, lineHeight: 84, vertOffset: 0 },
              { text: info.watermarkDescription, fontSize: 84, lineHeight: 84, vertOffset: 0 }
            ];
          });
          setWatermark = true;
        }

        if (localStorage.getItem('initWatermark') !== 'true') {
          newTvWidget.watermark().visibility().setValue(true);
          localStorage.setItem('initWatermark', 'true');
        }
      } catch (e) {
        console.log('Error setting watermark', e);
      }
    });

    newTvWidget.subscribe('layout_changed', () => {
      updateCharts();
    });

    // when clicking / activating a chart, update the order card with the correct symbol
    // port this to desktop too eventually
    newTvWidget.subscribe('activeChartChanged', (chartIndex) => {
      const activeChart = newTvWidget.chart(chartIndex);
      const symbolName = activeChart.symbol();
      const clean = getContractByFriendlyName(symbolName);
      setMobileContract(clean);

      // set last active mobile chart index in local storage
      localStorage.setItem('lastActiveMobileChart', chartIndex.toString());
    });

    newTvWidget.subscribe('onAutoSaveNeeded', (...args) => {
      const currentLayoutName = newTvWidget.layoutName();
      if (!currentLayoutName) {
        newTvWidget.saveChartToServer(
          () => {},
          () => {},
          { defaultChartName: 'default' }
        );
      } else {
        newTvWidget.saveChartToServer();
      }
    });

    newTvWidget.headerReady().then(function () {
      newTvWidget.createButton({
        useTradingViewStyle: true,
        align: 'left',
        text: '⚙ Preferences',
        onClick: function () {
          setShowChartSettings(true);
        }
      });
    });

    // newTvWidget.chart
    newTvWidget.onChartReady(() => {
      setChartReady(true);

      try {
        if (localStorage.getItem('initWatermark') !== 'true') {
          localStorage.setItem('initWatermark', 'true');
          newTvWidget.watermark().visibility().setValue(true);
        }
      } catch (e) {
        console.log('Error setting watermark', e);
      }

      newTvWidget.activeChart().setSymbol(contract.friendlyName ?? '/ES');

      newTvWidget
        .activeChart()
        .onSymbolChanged()
        .subscribe(null, () => {
          const clean = getContractByFriendlyName(newTvWidget.activeChart().symbol());
          setMobileContract(clean);
        });

      if (!setWatermark) {
        newTvWidget.watermark().setContentProvider((data: WatermarkContentData) => {
          const info = data.symbolInfo as any;
          return [
            { text: `${info.watermarkTitle}, ${data.interval}`, fontSize: 84, lineHeight: 84, vertOffset: 0 },
            { text: info.watermarkDescription, fontSize: 84, lineHeight: 84, vertOffset: 0 }
          ];
        });
        setWatermark = true;
      }

      setTvWidget(newTvWidget);
      newTvWidget.symbolSync().setValue(false, true);
      newTvWidget.symbolSync().subscribe((data) => {
        if (data) newTvWidget.symbolSync().setValue(false, true);
      });
      newTvWidget.applyOverrides({
        'mainSeriesProperties.statusViewStyle.showExchange': false,
        'mainSeriesProperties.haStyle.showRealLastPrice': true,
        'paneProperties.canvasBackground': '#1c1e23'
      });
    });

    return () => {
      console.log('Destroying component, deleting widget');
      setTvWidget(null);
      newTvWidget.remove();
    };
  }, [layoutName]);

  useEffect(() => {
    const subscriptions = chartGroups.map((y) => {
      const symbol = y.actualSymbol;
      const id = subscribeQuotesForSymbol(symbol, (quote) => {
        if (quote.lastPrice) {
          y.lastPrice = quote.lastPrice;
          const subs = subscribeBarsCallbackRef.current.get(symbol);
          if (subs !== undefined) {
            subs.forEach((x) => {
              x.currentQuote = quote.lastPrice;
            });
          }
        }
      });

      return { symbol, id };
    });
    return () => {
      for (const sub of subscriptions) {
        unsubscribeQuotesForSymbol(sub.symbol, sub.id);
      }
    };
  }, [chartGroups]);

  const chart = useMemo(() => {
    return <div ref={containerRef} style={{ display: 'flex', flexDirection: 'column', flex: 1 }}></div>;
  }, []);

  const chartSettingsModal = useMemo(() => {
    return (
      <TsModal open={showChartSettings} onClose={() => setShowChartSettings(false)} title='Chart Settings'>
        <ChartSettings onSaved={() => setShowChartSettings(false)} onClosed={() => setShowChartSettings(false)} showClose />
      </TsModal>
    );
  }, [showChartSettings]);

  const chartPositions = useMemo(() => {
    return <ChartPosition charts={chartGroups} widget={tvWidget} />;
  }, [chartGroups, tvWidget]);

  const chartOrders = useMemo(() => {
    return <ChartOrders charts={chartGroups} widget={tvWidget} />;
  }, [chartGroups, tvWidget]);

  const chartExecutions = useMemo(() => {
    return <ChartTradeExecutions charts={chartGroups} widget={tvWidget} />;
  }, [chartGroups, tvWidget]);

  const tradeEnabled = useMemo(() => {
    return activeTradingAccount.isFollower !== true && (activeTradingAccount?.status === TradingAccountStatus.Active || activeTradingAccount?.status == TradingAccountStatus.Ineligible);
  }, [activeTradingAccount.isFollower, activeTradingAccount?.status]);

  const { placeOrderWithSymbol } = useOrders();

  const [orderAmount, setOrderAmount] = useState<number>(1);

  // Retrieve order amount from local storage
  useEffect(() => {
    const savedOrderAmount = localStorage.getItem('mobile-order-amount');
    if (savedOrderAmount) {
      setOrderAmount(parseInt(savedOrderAmount));
    }
  }, []);

  // Set order amount to local storage for persistence
  useEffect(() => {
    localStorage.setItem('mobile-order-amount', orderAmount.toString());
  }, [orderAmount]);

  const quickBuy = async () => {
    const buyCallback = async () => {
      hideModal();
      await placeOrderWithSymbol({
        amount: orderAmount,
        symbol: contract.symbol,
        orderType: OrderPromptType.Buy,
        type: OrderType.Market
      });
    };
    
    if (confirmation) {
      showModal(<ConfirmOrderModal contract={contract} limitPrice={undefined} stopPrice={undefined} trailDistance={undefined} orderAmount={orderAmount} type={OrderType.Market} side={OrderPromptType.Buy} onConfirm={buyCallback} />);
    } else {
      await buyCallback();
    }
  };

  const quickSell = async () => {
    const sellCallback = async () => {
      hideModal();
      await placeOrderWithSymbol({
        amount: orderAmount,
        symbol: contract.symbol,
        orderType: OrderPromptType.Sell,
        type: OrderType.Market
      });
    };
    
    if (confirmation) {
      showModal(<ConfirmOrderModal contract={contract} limitPrice={undefined} stopPrice={undefined} trailDistance={undefined} orderAmount={orderAmount} type={OrderType.Market} side={OrderPromptType.Sell} onConfirm={sellCallback} />);
    } else {
      await sellCallback();
    }
  };

  const flashStyle = {
    transition: 'color 0.1s ease-in-out'
  };

  const handleSlideOut = () => {
    setShowSlideOut((prev) => !prev);
  };

  // Compare askPrice with the previous value and trigger flashing effect
  useEffect(() => {
    let timerId;

    if (prevLastPrice !== null && quoteData?.bestAsk !== null && quoteData?.lastPrice != null) {
      if (quoteData.lastPrice > prevLastPrice) {
        timerId = setTimeout(() => {
          setShowFlash('#43984d');
          setTimeout(() => {
            setShowFlash('');
          }, 1000);
        }, 0);
      } else if (quoteData.lastPrice < prevLastPrice) {
        timerId = setTimeout(() => {
          setShowFlash('#bd3f3b');
          setTimeout(() => {
            setShowFlash('');
          }, 1000);
        }, 0);
      }
    }
    if (quoteData?.lastPrice) setPrevLastPrice(quoteData.lastPrice);
    return () => clearTimeout(timerId);
  }, [quoteData?.lastPrice, quoteData?.bestAsk]);

  const activePosition = useMemo(() => {
    const activePosList = activePositions.filter((y) => y.symbolId == contract.symbol);
    return activePosList.length > 0 ? activePosList[0] : null;
  }, [activePositions, contract]);

  const showPnl = () => {
    if (quoteData?.lastPrice === undefined) return 0;
    return calculatePnl(activePosition.positionSize, activePosition.averagePrice, quoteData.lastPrice, contract.contractCost);
  };

  const positionEntryPrice = useMemo(() => {
    if (!activePosition) return 0;
    return roundToNearestTick(activePosition.averagePrice, contract);
  }, [activePosition?.averagePrice, contract]);

  const breakEven = useCallback(() => {
    if (activePosition) {
      editSltpSetting(activePosition.id, positionEntryPrice, activePosition.takeProfit);
    }
  }, [activePosition, positionEntryPrice, activePosition?.id, activePosition?.takeProfit]);

  const handleSetContract = (symbol: SymbolMetadata) => {
    if (tvWidget) {
      const chart = tvWidget.activeChart();
      if (chart) {
        chart.setSymbol(symbol?.friendlyName);
      }
    }
  };

  return useMemo(() => {
    return (
      <Box display='flex' flexDirection='column' flex='1' height='100%'>
        {chartOrders}
        {chartPositions}
        {chartExecutions}
        {chartSettingsModal}
        {chart}
        {!showSlideOut && (
          <div className={styles.slideOutToggle} onClick={handleSlideOut}>
            +
          </div>
        )}
        {
          <TsModal sx={{zIndex: 100}} open={showSlideOut} onClose={() => setShowSlideOut(false)} childSx={{ padding: '2em' }}>
            <MobileWebChartTrade orderAmount={orderAmount} minimize={handleSlideOut} contract={contract} onSetContract={handleSetContract} onSetOrderAmount={setOrderAmount} />
          </TsModal>
        }
        {!showSlideOut && (
          <Box display='flex' flexDirection='column' marginTop={0}>
            <Box
              style={{
                justifyContent: 'center',
                display: 'flex',
                alignItems: 'center'
              }}
            >
              <div
                style={{
                  justifyContent: 'center',
                  display: 'block',
                  flexWrap: 'wrap',
                  alignItems: 'center',
                  textAlign: 'center',
                  margin: '0.25em 0'
                }}
              >
                {activePosition && activePosition.positionSize != 0 && (
                  <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    <div style={{ marginRight: 10 }}>
                      {activePosition.positionSize > 0 ? '+' + activePosition.positionSize : activePosition.positionSize} @ <ContractPriceSpan contract={contract}>{activePosition.averagePrice}</ContractPriceSpan>
                    </div>
                    <div style={{ marginRight: 10 }}>
                      <span style={{ color: showPnl() < 0 ? '#bd3f3b' : '#43984d' }}>UP&L ${showPnl().toFixed(2)}</span>
                    </div>
                    <div style={{ marginRight: 10 }}>
                      <StyledButton color='neutral' disabled={showPnl() < 0} onClick={breakEven} sx={{ padding: '2px 4px', margin: '4px', fontSize: '.9em', minWidth: 20 }}>
                        B/E
                      </StyledButton>
                    </div>
                  </div>
                )}
                <Stack>
                  <Stack style={{ textAlign: 'center' }}>
                    <div>
                      <div>{contract.friendlyName}</div>
                      <div style={{ fontWeight: 300 }}> {contract.description}</div>
                    </div>
                  </Stack>
                  {(!activePosition || activePosition.positionSize == 0) && (
                    <Stack>
                      <div>No Active Position</div>
                    </Stack>
                  )}
                </Stack>
              </div>
            </Box>
            <QuantityButtons disabled={!tradeEnabled} currentSize={orderAmount} setSize={(size) => setOrderAmount(size)} />
            <div className={styles.quickButtonContainer}>
              <StyledButton disabled={!tradeEnabled} color='success' onClick={() => quickBuy()}>
                BUY +{orderAmount}
              </StyledButton>
              <Box>
                <div className={styles.marketOrderPriceContainer}>
                  <Stack style={{ alignItems: 'center', flexDirection: 'row' }}>
                    <div className={styles.marketOrderPriceContainerBB}>Bid: {contract && <ContractPriceSpan contract={contract}>{quoteData?.bestBid ?? 0}</ContractPriceSpan>}</div>

                    <div style={{ display: 'block', marginLeft: 5, marginRight: 5 }}>
                      <div className={styles.marketOrderPriceContainerLP} style={{ color: showFlash, ...flashStyle }}>
                        {contract && <ContractPriceSpan contract={contract}>{quoteData?.lastPrice ?? 0}</ContractPriceSpan>}
                      </div>
                      <div className={styles.marketOrderPriceContainerSpread}>{contract && <ContractPriceSpan contract={contract}>{(quoteData?.bestAsk ?? 0) - (quoteData?.bestBid ?? 0)}</ContractPriceSpan>}</div>
                    </div>

                    <div className={styles.marketOrderPriceContainerBA}>Ask: {contract && <ContractPriceSpan contract={contract}>{quoteData?.bestAsk ?? 0}</ContractPriceSpan>}</div>
                  </Stack>
                </div>
              </Box>

              <StyledButton disabled={!tradeEnabled} color='error' onClick={() => quickSell()}>
                SELL -{orderAmount}
              </StyledButton>
            </div>
          </Box>
        )}
      </Box>
    );
  }, [chart, chartSettingsModal, chartPositions, chartOrders, chartExecutions, contract, quoteData?.lastPrice, quoteData?.bestAsk, orderAmount, showSlideOut, activePosition]);
};

export default React.memo(MobileWebChart);
