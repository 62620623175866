import classNames from 'classnames';
import React, { useMemo } from 'react';
import styles from './balance.module.scss';
import { formatPrice } from '../../helpers/formatter';
import { useOrders } from '../../contexts/OrdersContext';
import { useTradingAccount } from '../../contexts/TradingAccountContext';
import { Tooltip } from '@mui/material';
import { useSettings } from '@contexts/SettingsContext';
import { useDeviceContext } from '@/contexts/DeviceContext';

type BalanceProps = {
  padding?: number;
  fontSize?: number;
  variant?: 'default' | 'dark';
};

const BalanceInfo: React.FC<BalanceProps> = (props: BalanceProps): JSX.Element => {
  const { isMobile } = useDeviceContext();
  const { activeTradingAccount } = useTradingAccount();
  const { activePositions } = useOrders();
  const { customSettings } = useSettings();

  const totalPnl = useMemo(() => {
    return activePositions
      .map((y) => y.profitAndLoss)
      .reduce((acc, pos) => {
        return acc + pos;
      }, 0);
  }, [activePositions]);

  const walletBalance = useMemo(() => {
    return (activeTradingAccount.balance || 0) + totalPnl;
  }, [activeTradingAccount.balance, activePositions, totalPnl]);

  const renderFontSize = useMemo(() => {
    switch (customSettings.topNavTextSize) {
      case 0:
        return '12px';
      case 1:
        return '14px';
      case 2:
        return '16px';
      default:
        return '12px';
    }
  }, [customSettings.topNavTextSize]);

  const backgroundColor = useMemo(() => {
    switch (props.variant) {
      case 'dark':
        return '#1c1e23';
      default:
        return '#2a292f';
    }
  }, [props.variant]);

  return useMemo(
    () => (
      <div className={classNames(isMobile ? styles.balanceMobile : styles.balance)} style={{ padding: props.padding, backgroundColor: backgroundColor }}>
        <Tooltip title='Current Account Balance (Net Equity + Unrealized P&L)'>
          <div className={styles.balanceAmount} style={{ fontSize: props.fontSize ?? renderFontSize }}>
            <span>BAL: </span>
            {customSettings.streamerModeBalance ? '******' : <span>${formatPrice(walletBalance)}</span>}
          </div>
        </Tooltip>
      </div>
    ),
    [isMobile, walletBalance, customSettings.streamerModeBalance, customSettings.topNavTextSize]
  );
};

export default React.memo(BalanceInfo);
