import react, { useCallback, useEffect, useRef } from 'react';
import { Howl } from 'howler';
import marketsClosed from '@assets/sounds/markets_closed.mp3';
import marketsOpen from '@assets/sounds/markets_open.mp3';
import orderFilled from '@assets/sounds/order_filled.mp3';
import orderPlaced from '@assets/sounds/order_placed.mp3';
import orderRejected from '@assets/sounds/order_rejected.mp3';
import { useSettings } from '@contexts/SettingsContext';
import { useDeviceContext } from '@/contexts/DeviceContext';

export enum SoundType {
  OrderFilled,
  OrderRejected,
  MarketsClosed,
  MarketsOpen,
  OrderPlaced
}

const sources = {
  [SoundType.OrderFilled]: orderFilled,
  [SoundType.OrderRejected]: orderRejected,
  [SoundType.MarketsClosed]: marketsClosed,
  [SoundType.MarketsOpen]: marketsOpen,
  [SoundType.OrderPlaced]: orderPlaced
};
const soundMap = new Map<SoundType, number>();

export const useSoundNotifications = () => {
  const settings = useSettings();
  const { isMobile } = useDeviceContext();

  const hasSoundNotifications = useRef(settings.soundNotifications == null || settings.soundNotifications == undefined ? true : settings.soundNotifications);

  useEffect(() => {
    if (isMobile) {
      hasSoundNotifications.current = false;
      return;
    }
    if (settings.customSettings?.audioAlerts == undefined) {
      hasSoundNotifications.current = settings.soundNotifications == null || settings.soundNotifications == undefined ? true : settings.soundNotifications;
    } else {
      hasSoundNotifications.current = settings.customSettings.audioAlerts;
    }
  }, [settings.soundNotifications, settings.customSettings?.audioAlerts, isMobile]);

  const play = useCallback((soundType: SoundType) => {
    if (!hasSoundNotifications.current) return;

    const now = Date.now();
    const lastSound = soundMap.get(soundType);
    if (lastSound && now - lastSound < 1000) {
      return;
    }
    soundMap.set(soundType, now);
    const sound = new Howl({
      src: [sources[soundType]],
      volume: 0.5
    });
    sound.play();
  }, []);

  return { play };
};
