import * as Sentry from '@sentry/react';
const logException = (error: Error, message: string) => {
  console.error(message, error);
  try {
    Sentry.withScope(function (scope) {
      scope.setExtra('customMessage', message);

      // The exception has the event level set by the scope (info).
      Sentry.captureException(error);
    });
  } catch (e) {
    console.error('Failed to log exception to Sentry', e);
  }
};

export { logException };
