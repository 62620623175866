import Button from '@/components/topstep/button';
import { NavRoutes } from '@/data/NavRoutes';
import { faChartBar, faChartCandlestick, faCog, faUser, faMoneyBillSimple, faGripDots, faEllipsis } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, ButtonGroup, Typography, styled } from '@mui/material';
import { localStorageAvailable } from '@mui/x-data-grid/utils/utils';
import React, { useCallback, useEffect, useMemo } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import DrawerComponent from './Drawer';

const getButtonStyle = (active) => ({
  backgroundColor: 'transparent',
  width: '100%',
  height: '100%',
  color: active ? '#000' : 'inherit'
});

const MobileMenu = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-around',
  alignItems: 'center',
  background: theme.palette.darkBg,
  margin: '1em 0',
}));

const MobileMenuItem = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'active'
})<{ active?: boolean }>(({ active, theme }: { active?: boolean; theme: any }) => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center',
  alignItems: 'center',
  height: '100%',
  padding: '0.5em 0',
  background: active ? '#fff' : undefined,
  borderRadius: '0.5em'
}));

const MobileFooter = () => {
  const nav = useNavigate();
  const location = useLocation();
  const [showDrawer, setShowDrawer] = React.useState(false)
  const [activeItem, setActiveItem] = React.useState('')

  const handleAccountClick = () => {
    nav('/' + NavRoutes.MobileAccount + '/info')
    setActiveItem('account')
  };

  const handleTradeClick = () => {
    nav('/' + NavRoutes.MobileTrading + '/dom');
    setActiveItem('trade')
  };

  const handleChartClick = () => {
    nav('/' + NavRoutes.MobileChart);
    setActiveItem('chart')
  };

  const handleShowDrawer = () => {
    setShowDrawer(prev => !prev)
  }

  useEffect(() => {
    if (location.pathname.includes('/account/info')) {
      setActiveItem('account');
    } else if (location.pathname.includes('/trading/dom')) {
      setActiveItem('trade');
    } else if (location.pathname.includes('/mobile-chart')) {
      setActiveItem('chart');
    } else {
      setActiveItem('other');
    }
  }, [location.pathname]);

  return (
    <>
      <MobileMenu>
        <MobileMenuItem active={activeItem == 'account'}>
          <Button onClick={handleAccountClick} style={getButtonStyle(activeItem == 'account')}>
            <FontAwesomeIcon style={{ marginRight: '0.5em' }} icon={faUser} /> Account
          </Button>
        </MobileMenuItem>
        <MobileMenuItem active={activeItem == 'trade'}>
          <Button onClick={handleTradeClick} style={getButtonStyle(activeItem == 'trade')}>
            <FontAwesomeIcon style={{ marginRight: '0.5em' }} icon={faMoneyBillSimple} /> Trade
          </Button>
        </MobileMenuItem>
        <MobileMenuItem active={activeItem == 'chart'}>
          <Button onClick={handleChartClick} style={getButtonStyle(activeItem == 'chart')}>
            <FontAwesomeIcon style={{ marginRight: '0.5em' }} icon={faChartCandlestick} /> Chart
          </Button>
        </MobileMenuItem>
        <MobileMenuItem active={activeItem == 'other'}>
          <Button style={getButtonStyle(activeItem == 'other')} onClick={handleShowDrawer}>
            <FontAwesomeIcon style={{ marginRight: '0.5em' }} icon={faEllipsis} />
          </Button>
        </MobileMenuItem>
      </MobileMenu>
      {showDrawer && (
        <DrawerComponent closeDrawer={handleShowDrawer} />
      )}
    </>
  );
};

export default MobileFooter;
