import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useApi } from './ApiContext';
import { useCqg } from './CqgContext';
import { MarketStatus } from '@api/dataApi';
import { logException } from '@/helpers/exceptionHelper';
export interface IMarketStatusContext {
  getStatus: (symbolId: string) => MarketStatus;
  isOpen: (symbolId: string) => boolean | null | undefined;
}

export const MarketStatusContext = React.createContext<IMarketStatusContext>({} as any);

export const useMarketStatus = () => React.useContext<IMarketStatusContext>(MarketStatusContext);

function MarketStatusContextProvider({ children }: any) {
  const { marketStatusApi } = useApi();
  const { subscribeMarketStatus, unsubscribeMarketStatus, subscribeOnUserReconnect, unsubscribeOnUserReconnect } = useCqg();
  const [marketStatus, setMarketStatus] = useState<MarketStatus[]>([]);

  const statusMap = useMemo(() => {
    return marketStatus?.reduce((map, status) => {
      map[status.symbolId] = status;
      return map;
    }, {});
  }, [marketStatus]);

  const isOpen = useCallback(
    (symbolId: string) => {
      return statusMap[symbolId]?.isOpen;
    },
    [statusMap]
  );

  const getStatus = useCallback(
    (symbolId: string) => {
      return statusMap[symbolId];
    },
    [statusMap]
  );

  useEffect(() => {
    const onReconnect = () => {
      marketStatusApi
        .getMarkets()
        .then((data) => {
          setMarketStatus(data);
        })
        .catch((e) => {
          console.log('Failed to get market status', e);
        });
    };

    subscribeOnUserReconnect(onReconnect);

    return () => unsubscribeOnUserReconnect(onReconnect);
  }, []);

  useEffect(() => {
    const id = subscribeMarketStatus((data) => {
      setMarketStatus((existing) => {
        let found = false;
        for (const status of existing) {
          if (status.symbolId === data.symbolId) {
            found = true;
            status.status = data.status;
            status.autoLiquidate = data.autoLiquidate;
            status.isOpen = data.isOpen;
            status.simStatus = data.simStatus;
            break;
          }
        }

        if (!found) {
          existing.push(data);
        }
        return [...existing];
      });
    });

    marketStatusApi
      .getMarkets()
      .then((data) => {
        setMarketStatus(data);
      })
      .catch((e) => {
        console.log('Failed to get market status', e);
      });

    return () => unsubscribeMarketStatus(id);
  }, []);

  const values = useMemo(() => {
    const res: IMarketStatusContext = {
      getStatus,
      isOpen
    };

    return res;
  }, [getStatus, isOpen]);

  return <MarketStatusContext.Provider value={values}>{children}</MarketStatusContext.Provider>;
}

export default MarketStatusContextProvider;
