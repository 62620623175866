import React, { useMemo } from 'react';
import { LightCardWrapper } from '@/components/styledComponents';
import { Typography, Box } from '@mui/material';
import HelpMessage from '@/components/helpMessage';
import dayjs from 'dayjs';
import styles from './performanceView.module.scss';
import { TradeModel, DayStatistic, ProfitFactorData, AverageWinLossData } from '@/api/userApi';
import { useSymbol } from '@/contexts/SymbolContext';
import { config } from '@/config';

interface CoachTipProps {
  trades: TradeModel[];
  dayStatistics: DayStatistic[];
  profitFactor: ProfitFactorData;
  winLossStatistics: AverageWinLossData;
  totalPnl: number;
  winRate: number;
}

const priceFormatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
  minimumFractionDigits: 2
});

const percentFormatter = new Intl.NumberFormat('en-US', {
  style: 'percent',
  minimumFractionDigits: 2
});

const formatDollarPrice = (value?: number): string => {
  if (isNaN(value)) return '';
  return priceFormatter.format(value);
};

const formatPercent = (value?: number): string => {
  if (isNaN(value)) return '';
  return percentFormatter.format(value);
};

const PersonalizedTip: React.FC<CoachTipProps> = ({ totalPnl, winRate, trades, dayStatistics, profitFactor, winLossStatistics }) => {
  const { getContractById } = useSymbol();

  const tips = useMemo(() => {
    if (!trades || trades.length === 0) {
      return ['No trades available. Start trading to get personalized tips!'];
    }

    // Calculate statistics
    const winCount = trades.filter((trade) => (trade.pnL || 0) > 0).length;
    const lossCount = trades.filter((trade) => (trade.pnL || 0) < 0).length;

    const calculateProfitFactor = () => {
      if (profitFactor && typeof profitFactor.totalProfit === 'number' && typeof profitFactor.totalLoss === 'number') {
        return profitFactor.totalLoss !== 0 ? Math.abs(profitFactor.totalProfit / profitFactor.totalLoss) : 0;
      }
      return 0;
    };

    const profitFactorValue = calculateProfitFactor();

    const timeOfDayPerformance = trades.reduce(
      (acc, trade) => {
        const hour = dayjs(trade.createdAt).hour();
        const timeSlot = Math.floor(hour / 4); // Divide day into 6 4-hour slots
        acc[timeSlot] = (acc[timeSlot] || 0) + (trade.pnL || 0);
        return acc;
      },
      {} as { [key: number]: number }
    );

    const bestTimeSlot = Object.entries(timeOfDayPerformance).reduce((a, b) => (a[1] > b[1] ? a : b))[0];
    const worstTimeSlot = Object.entries(timeOfDayPerformance).reduce((a, b) => (a[1] < b[1] ? a : b))[0];

    const dayOfWeekPerformance = trades.reduce(
      (acc, trade) => {
        const dayOfWeek = dayjs(trade.createdAt).day();
        acc[dayOfWeek] = (acc[dayOfWeek] || 0) + (trade.pnL || 0);
        return acc;
      },
      {} as { [key: number]: number }
    );

    const bestDayOfWeek = Object.entries(dayOfWeekPerformance).reduce((a, b) => (a[1] > b[1] ? a : b))[0];
    const worstDayOfWeek = Object.entries(dayOfWeekPerformance).reduce((a, b) => (a[1] < b[1] ? a : b))[0];

    const daysOfWeek = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
    const timeSlots = ['12am-4am', '4am-8am', '8am-12pm', '12pm-4pm', '4pm-8pm', '8pm-12am'];

    const symbolCounts = trades.reduce(
      (acc, trade) => {
        acc[trade.symbolId] = (acc[trade.symbolId] || 0) + 1;
        return acc;
      },
      {} as { [key: string]: number }
    );

    const mostTradedSymbol = Object.entries(symbolCounts).sort((a: [string, number], b: [string, number]) => b[1] - a[1])[0]?.[0] || 'N/A';
    const [largestWin, largestLoss] = trades.reduce(([max, min], trade) => [(trade.pnL || 0) > (max.pnL || 0) ? trade : max, (trade.pnL || 0) < (min.pnL || 0) ? trade : min], [trades[0], trades[0]]);

    const avgHoldingTime = trades.reduce((acc, trade) => acc + dayjs(trade.exitedAt).diff(dayjs(trade.createdAt), 'second'), 0) / trades.length;

    const avgWinSize = winCount > 0 ? trades.filter((trade) => (trade.pnL - trade.fees || 0) > 0).reduce((acc, trade) => acc + (trade.pnL - trade.fees || 0), 0) / winCount : 0;
    const avgLossSize = lossCount > 0 ? Math.abs(trades.filter((trade) => (trade.pnL - trade.fees || 0) < 0).reduce((acc, trade) => acc + (trade.pnL - trade.fees || 0), 0) / lossCount) : 0;
    const riskRewardRatio = avgLossSize !== 0 ? avgWinSize / avgLossSize : 0;

    const renderSymbol = (symbolId: string) => {
      const symbolData = getContractById(symbolId);
      return symbolData.friendlyName;
    };

    const formatDuration = (seconds: number) => {
      const minutes = Math.floor(seconds / 60);
      const remainingSeconds = Math.round(seconds % 60);

      if (minutes === 0) {
        return `${remainingSeconds} sec`;
      } else if (remainingSeconds === 0) {
        return minutes === 1 ? `${minutes} min` : `${minutes} mins`;
      } else {
        return `${minutes} min ${remainingSeconds} sec`;
      }
    };

    return [
      `Your overall P&L is ${formatDollarPrice(totalPnl)}. ${totalPnl > 0 ? 'Keep up the good work!' : 'Consider reviewing your strategy.'}`,
      `Your win rate is ${formatPercent(winRate / 100)}. Aim for a win rate above 50% for consistent profitability.`,
      `Your most traded symbol is ${renderSymbol(mostTradedSymbol)}. Consider learning additional symbols to help diversify your trading.`,
      `Your largest win was ${formatDollarPrice(largestWin.pnL - largestWin.fees || 0)} and largest loss was ${formatDollarPrice(largestLoss.pnL - largestLoss.fees || 0)}. Aim to keep your largest loss smaller than your largest win.`,
      `Your average holding time is ${formatDuration(avgHoldingTime)}. ${avgHoldingTime < 300 ? 'Consider giving trades more time to develop if needed.' : 'Your patience seems good.'}`,
      `Your risk-reward ratio is ${riskRewardRatio.toFixed(2)}. ${riskRewardRatio < 1 ? 'Try to improve this ratio by setting wider profit targets or tighter stop losses.' : 'Great job on maintaining a favorable risk-reward ratio!'}`,
      `You've made ${trades.length} trades${dayStatistics.length > 0 ? ` over ${dayStatistics.length} days` : ''}. ${trades.length / (dayStatistics.length || 1) > 20 ? 'Consider focusing on quality over quantity to avoid overtrading.' : "Keep monitoring your trade frequency to ensure you're not overtrading."}`,
      `Your profit factor is ${profitFactorValue.toFixed(2)}. ${profitFactorValue < 1.5 ? 'Try to increase this by focusing on trades with a higher probability of success.' : 'Great job on maintaining a solid profit factor!'}`,
      `Your most successful trading time appears to be ${timeSlots[Number(bestTimeSlot)]}. Consider focusing more of your trading activity during this time slot.`,
      `Your least successful trading time is ${timeSlots[Number(worstTimeSlot)]}. You might want to be more cautious or avoid trading during this period.`,
      `${daysOfWeek[Number(bestDayOfWeek)]} seems to be your best trading day. Try to understand what makes this day particularly successful for you.`,
      `${daysOfWeek[Number(worstDayOfWeek)]} appears to be your most challenging trading day. Consider adjusting your strategy or being more selective with trades on this day.`
    ];
  }, [trades, dayStatistics, profitFactor, winLossStatistics, getContractById]);

  const randomTip = useMemo(() => tips[Math.floor(Math.random() * tips.length)], [tips]);

  return (
    <Box sx={{ display: 'flex', justifyContent: 'center', justifyItems: 'center', alignContent: 'center', alignItems: 'center' }} className={styles.coach}>
      <span style={{ fontStyle: 'italic' }}>"{randomTip}"</span>
      <Box
        sx={(theme) => {
          return {
            margin: '0 1em',
            whiteSpace: 'nowrap',
            background: theme.palette.white,
            color: theme.palette.darkBg,
            fontWeight: 'bold',
            borderRadius: '10em',
            display: 'flex',
            justifyContent: 'center',
            justifyItems: 'center',
            alignContent: 'center',
            alignItems: 'center',
            padding: '1.2em 0.5em',
            paddingLeft: '0.2em',
            height: '1.2em'
          };
        }}
      >
        <span style={{ padding: 16 }}>- from {config.coachName}</span>
      </Box>
    </Box>
  );
};

export default PersonalizedTip;
