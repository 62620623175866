import React, { useMemo } from 'react';
import { NotificationModalWithTip } from '@/components/topstep/notificationModal';
import { useSettings } from '@/contexts/SettingsContext';
import { openInNewTab } from '@/helpers/urlHelper';
import CoachTip from '@/views/trader/components/notifications/CoachTip';
import { ActiveViolationModel } from '@/api/userApi';
import { AccountTable } from '@/views/trader/components/notifications/AccountTable';
import { useOrders } from '@/contexts/OrdersContext';
import { useNavigate } from 'react-router-dom';
import { NavRoutes } from '@/data/NavRoutes';
import { StyledLink } from '@/components/styledComponents';
import { TradingPlatforms as TradingPlatform, config } from '@/config';

interface PersonalDailyLossLimitModalProps {
  violation: ActiveViolationModel;
  accountName: string;
  onHide: () => void;
}
const PersonalDailyLossLimitModal: React.FC<PersonalDailyLossLimitModalProps> = (props) => {
  const navigate = useNavigate();

  return useMemo(() => {
    const header = 'Account ' + props.accountName + ': Manually Locked Out';
    const buttonLabelOutline = 'DISMISS THIS NOTIFICATION';
    const buttonLabelBlue = 'PERSONAL DAILY LOSS LIMIT SETTINGS';
    return (
      <NotificationModalWithTip
        header={header}
        footer={<CoachTip />}
        onClickBlue={() => {
          navigate(NavRoutes.Settings);
          props.onHide();
        }}
        onClickOutline={props.onHide}
        buttonLabelOutline={buttonLabelOutline}
      >
        <div>
          <p>This account has been manually locked out. Trading will not be available until the next market open (5pm CT).</p>
          {config.platform === TradingPlatform.TopstepX && (
            <p>
              <b>Free resources</b>: We're here for you every step of your trading journey. Join us for{' '}
              <StyledLink target='_blank' href='https://www.topstep.tv/'>
                TopstepTV™
              </StyledLink>{' '}
              Monday-Friday, from 8:00am - 3:00pm CT, and join our{' '}
              <StyledLink target='_blank' href='https://discord.com/invite/topstep'>
                Discord
              </StyledLink>{' '}
              channel to find a trading buddy or chat with a Topstep coach!
            </p>)}
        </div>
      </NotificationModalWithTip>
    );
  }, [props.accountName, props.onHide]);
};

export default React.memo(PersonalDailyLossLimitModal);
