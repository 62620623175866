import React, { useMemo } from 'react';
import styles from './balance.module.scss';
import { formatPrice } from '../../helpers/formatter';
import { useOrders } from '../../contexts/OrdersContext';
import { useTradingAccount } from '../../contexts/TradingAccountContext';
import { LinearProgress, Tooltip, styled, linearProgressClasses } from '@mui/material';
import { useSettings } from '@contexts/SettingsContext';
import { useDeviceContext } from '@/contexts/DeviceContext';

type BalanceProps = {
  padding?: number;
  fontSize?: number;
  variant?: 'default' | 'dark';
};

const DailyProfitTargetBar: React.FC<BalanceProps> = (props: BalanceProps): JSX.Element => {
  const { isMobile } = useDeviceContext();
  const { activeTradingAccount } = useTradingAccount();
  const { activePositions } = useOrders();
  const { customSettings } = useSettings();

  const target = useMemo(() => {
    return activeTradingAccount.personalDailyProfitTarget;
  }, [activeTradingAccount.personalDailyProfitTarget]);

  const unrealizedPnl = useMemo(() => {
    return activePositions.reduce((acc, pos) => {
      return acc + pos.profitAndLoss;
    }, 0);
  }, [activeTradingAccount, activePositions]);

  const currentAmount = useMemo(() => {
    return (activeTradingAccount.realizedDayPnl || 0) + unrealizedPnl;
  }, [activeTradingAccount.realizedDayPnl, unrealizedPnl]);

  const currentPercent = useMemo(() => {
    if (currentAmount == 0) return 0;
    if (target == 0) return 0;

    return Math.min(100, (currentAmount / target) * 100);
  }, [target, currentAmount]);

  const dllOrPdll = 'PDPT';

  const renderFontSize = useMemo(() => {
    switch (customSettings.topNavTextSize) {
      case 0:
        return '12px';
      case 1:
        return '14px';
      case 2:
        return '16px';
      default:
        return '12px';
    }
  }, [customSettings.topNavTextSize]);

  const backgroundColor = useMemo(() => {
    switch (props.variant) {
      case 'dark':
        return '#1c1e23';
      default:
        return '#2a292f';
    }
  }, [props.variant]);

  return useMemo(
    () =>
      (target > 0 && (
        <div className={isMobile ? styles.dllMobile : styles.dll} style={{ padding: props.padding, backgroundColor: backgroundColor }}>
          <Tooltip title='Personal Daily Profit Target: Your custom daily profit target.'>
            <div className={styles.balanceAmount} style={{ display: 'flex', alignItems: 'center', fontSize: props.fontSize ?? renderFontSize }}>
              <span>{dllOrPdll}:</span>
              {!customSettings.streamerModeDLL ? (
                <>
                  <span className={styles.balanceAmount}>${formatPrice(currentAmount)}</span>
                  <BorderLinearProgress variant='determinate' value={currentPercent} />
                  <span className={styles.balanceAmount}>${formatPrice(target)}</span>
                </>
              ) : (
                <span> ******</span>
              )}
            </div>
          </Tooltip>
        </div>
      )) ||
      null,
    [currentAmount, target, currentPercent, customSettings.streamerModeDLL, customSettings.topNavTextSize, isMobile]
  );
};

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 10,
  width: '6em',
  borderRadius: 5,
  marginLeft: '0.5em',
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: theme.palette.grey[theme.palette.mode === 'light' ? 200 : 800]
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: theme.palette.green
  }
}));

export default React.memo(DailyProfitTargetBar);
