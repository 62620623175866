import { OrderType, SymbolMetadata } from "@/api/userApi";
import { OrderPromptType } from "@/contexts/OrdersContext";
import { useCallback, useMemo } from "react";
import Modal, { ModalAction } from "./modal";
import { ButtonType } from "./button";
import { useModal } from "@/contexts/ModalContext";
import styles from './confirmOrderModal.module.scss';
import Heading from "./heading";
import { orderTypeMap } from '@/data/enumTypeMaps';
import React from "react";
import { useSymbol } from "@/contexts/SymbolContext";
import { formatContractPrice } from "@/helpers/formatter";

type ConfirmOrderModalProps = {
    type: OrderType;
    side: OrderPromptType;
    onConfirm: () => void;
    orderAmount: number;
    contract: SymbolMetadata | undefined;
    limitPrice?: number | undefined;
    stopPrice?: number | undefined;
    trailDistance?: number | undefined;
};

const ConfirmOrderModal = ({ orderAmount, contract, type, side, limitPrice, stopPrice, trailDistance, onConfirm }: ConfirmOrderModalProps) => {

    const { showModal, hideModal } = useModal();

    const { symbols } = useSymbol();
    
    const symbolMap = useMemo(() => {
        return new Map(symbols.map((x) => [x.symbol, x]));
    }, [symbols]);

    const isSell = side == OrderPromptType.Sell;
    const actions: ModalAction[] = [
        {
            label: 'Cancel',
            props: { buttonType: ButtonType.outline, className: styles.modalButton },
            onClick: hideModal
        },
        {
            label: `Confirm ${isSell ? 'Sell' : 'Buy'}`,
            props: {
            buttonType: isSell ? ButtonType.red : ButtonType.green,
            className: styles.modalButton
            },
            onClick: () => {
            hideModal();
            onConfirm();
            }
        }
    ];

    const getPriceText = useCallback(() => {
        if (type === OrderType.Market) {
            return 'at market price';
        } else if (type === OrderType.Limit) {
            return 'at limit price ' + formatContractPrice(limitPrice, contract)
        } else if (type === OrderType.TrailingStop) {
            return 'at ' + trailDistance + ' ticks'
        } else if (type === OrderType.Stop) {
            return 'at ' + formatContractPrice(stopPrice, contract)
            
        }
    }, [contract]);

    return (
        <Modal header={<Heading className={isSell ? styles.redHeader : styles.greenHeader}>Confirm Order</Heading>} actions={actions}>
            <div>
            I want to place a {isSell ? '-' : '+'}
            {orderAmount} {orderTypeMap[type]} order{' '}
            <span className={styles.bold}>
                {(contract?.friendlyName || ' ').substring(1)}
                {contract?.maturityMonthYear} ({contract?.fullName})
            </span>{' '}
                {getPriceText()}
            </div>
        </Modal>
    );
};

export default ConfirmOrderModal;