import { faFloppyDisk, faPlus, faRotateLeft, faTrashCan, faWindowRestore, faArrowsMaximize } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { ChangeEvent, useCallback, useMemo, useState } from 'react';
import styles from './layoutSelector.module.scss';
import Select from './select';
import { useLayouts } from '../../contexts/LayoutsContext';
import { Box, Dialog, TextField, Tooltip } from '@mui/material';
import { StyledButton } from '@/components/styledComponents';

interface LayoutSelectorProps {
  style?: any;
  className?: string;
}

const LayoutSelector: React.FC<LayoutSelectorProps> = ({ style, className }): JSX.Element => {
  const { setActiveLayout, activeLayout, layouts, deleteLayout, saveCurrentLayout } = useLayouts();
  const [showModal, setShowModal] = useState(false);
  const [layoutName, setLayoutName] = useState('');

  const options = useMemo(() => {
    return layouts.map((y) => (
      <option key={y.id} value={y.id} className={styles.option}>
        LAYOUT | {y.name || 'default'} {y.draft && '(draft)'}
      </option>
    ));
  }, [layouts]);

  const handleOnChange = useCallback(
    (e: ChangeEvent<HTMLSelectElement>) => {
      if (!e.target.value) return;
      const id = parseInt(e.target.value);
      const layout = layouts.find((x) => x.id === id);
      setActiveLayout(layout, false);
    },
    [layouts]
  );

  const onNewWindowClick = useCallback(() => {
    window.open(window.location.href, '_blank');
  }, []);

  const showNewLayoutModal = useCallback(() => {
    setShowModal(true);
    setLayoutName('');
  }, []);

  const saveAsNewLayout = () => {
    setShowModal(false);
    saveCurrentLayout(layoutName);
  };

  const resetLayout = useCallback(() => {
    setActiveLayout({ name: 'default', layout: null, draft: true, id: 0 }, true);
  }, [activeLayout]);

  const saveActiveLayout = useCallback(() => {
    saveCurrentLayout(activeLayout?.name || 'default');
  }, [activeLayout, activeLayout, saveCurrentLayout]);

  const deleteActiveLayout = useCallback(() => {
    const res = confirm('Are you sure you want to delete this layout?');
    if (res) {
      deleteLayout(activeLayout?.id);
    }
  }, [activeLayout, deleteLayout]);

  const toggleFullscreen = () => {
    if (!document.fullscreenElement) {
      if (document.documentElement.requestFullscreen) {
        document.documentElement.requestFullscreen();
      }
    } else {
      if (document.exitFullscreen) {
        document.exitFullscreen();
      }
    }
  }


  const isDefaultLayout = useMemo(() => {
    return activeLayout?.name === 'default';
  }, [activeLayout]);

  const hasExistingName = useMemo(() => {
    return layouts.filter((y) => y.name == layoutName).length > 0;
  }, [layoutName, layouts]);

  return (
    <Box display='flex' alignItems='center' gap='.5em' data-intercom-target="layout-selector">
      <Select onChange={handleOnChange} value={activeLayout?.id} className={styles.layoutSelector}>
        {options.length === 0 && <option>LAYOUT | Default</option>}
        {options}
      </Select>
      <Tooltip title='Save Layout'>
        <FontAwesomeIcon icon={faFloppyDisk} className={styles.actionIcon} onClick={saveActiveLayout} />
      </Tooltip>
      {!isDefaultLayout && (
        <Tooltip title='Delete Layout'>
          <FontAwesomeIcon icon={faTrashCan} className={styles.actionIcon} onClick={deleteActiveLayout} />
        </Tooltip>
      )}
      <Tooltip title='Save as New Layout'>
        <FontAwesomeIcon icon={faPlus} className={styles.actionIcon} onClick={showNewLayoutModal} />
      </Tooltip>
      <Tooltip title='Reset Layout'>
        <FontAwesomeIcon icon={faRotateLeft} className={styles.actionIcon} onClick={resetLayout} />
      </Tooltip>
      <Tooltip title='Open new Window' data-intercom-target="open-new-window">
        <FontAwesomeIcon icon={faWindowRestore} className={styles.actionIcon} onClick={onNewWindowClick} />
      </Tooltip>
      <Tooltip title='Full Screen'>
        <FontAwesomeIcon icon={faArrowsMaximize} className={styles.actionIcon} onClick={toggleFullscreen} />
      </Tooltip>

      {showModal && (
        <Dialog onClose={() => setShowModal(false)} open={showModal}>
          <Box sx={{ display: 'flex', flexDirection: 'column', padding: '.5em' }}>
            <div className={styles.modalHeader}>Save as New Layout</div>
            <TextField size='small' variant='outlined' label='Layout Name' value={layoutName} onChange={(v) => setLayoutName(v.target.value)} />
            {hasExistingName && <div className={styles.existingName}>A layout by that name already exists. Please select another.</div>}
          </Box>
          <Box sx={{ display: 'flex', flexDirection: 'row', padding: '.5em', justifyContent: 'space-between' }}>
            <StyledButton color='error' onClick={() => setShowModal(false)}>
              Cancel
            </StyledButton>
            <StyledButton color='success' disabled={hasExistingName} onClick={saveAsNewLayout}>
              Save
            </StyledButton>
          </Box>
        </Dialog>
      )}
    </Box>
  );
};

export default React.memo(LayoutSelector);
