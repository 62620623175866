import React from 'react';
import classNames from 'classnames';
import styles from './select.module.scss';

type SelectProps = React.SelectHTMLAttributes<HTMLSelectElement> & {
  children: React.ReactNode;
  className?: string;
  inactive?: boolean;
  selected?: boolean;
};

const Select: React.FC<SelectProps> = ({
  children,
  className,
  selected,
  ...props
}): JSX.Element => {
  return (
    <select className={classNames(styles.select, className)} {...props}>
      {children}
    </select>
  );
};

export default React.memo(Select);
