import classNames from 'classnames';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import styles from './ping.module.scss';
import { Tooltip } from '@mui/material';
import { useCqg } from '@/contexts/CqgContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChartSimple, faWifi } from '@fortawesome/pro-solid-svg-icons';
import { useSettings } from '@contexts/SettingsContext';

const Ping: React.FC = (): JSX.Element => {

  const { getServerTime, getChartsServerTime } = useCqg();
  const { customSettings } = useSettings();

  //////////////
  // User API //
  //////////////

  const [ping, setPing] = useState<number>(0);
  const [speed, setSpeed] = useState<number>(0);

  const requestTime = useRef<number>(Date.now());
  const requesting = useRef<boolean>(false);
  const pingArray = useRef([]);

  const updatePingArray = (ping) => {
    const currentTime = Date.now();
    pingArray.current.push({ ping, time: currentTime });

    // Remove old entries (older than 60 seconds)
    const thirtySecondsAgo = currentTime - 60000;
    pingArray.current = pingArray.current.filter(entry => entry.time >= thirtySecondsAgo);

    // Calculate average
    const totalPing = pingArray.current.reduce((acc, entry) => acc + entry.ping, 0);
    const averagePing = pingArray.current.length > 0 ? Math.round(totalPing / pingArray.current.length) : 0;
    setPing(averagePing);
  };

  useEffect(() => {
    setSpeed(customSettings.networkSpeedNew)
  }, [customSettings.networkSpeedNew])

  useEffect(() => {
    const pingServer = () => {
      if (!requesting.current) {
        try {
          requesting.current = true;
          requestTime.current = new Date().getTime();
          getServerTime().then(() => {
            const ping = Date.now() - requestTime.current;
            updatePingArray(ping);
            requesting.current = false;
          }).catch(() => {
            requesting.current = false;
          });
        } catch (error) {
          requesting.current = false;
          console.error('Error while fetching server time:', error);
        }
      } else {
        const newPing = Date.now() - requestTime.current;
        updatePingArray(newPing);
      }
    };

    const pingInterval = setInterval(pingServer, 5000);

    return () => clearInterval(pingInterval);
  }, []);


  ///////////////
  // Chart API //
  ///////////////

  const [chartPing, setChartPing] = useState<number>(0);

  const chartRequestTime = useRef<number>(Date.now());
  const chartRequesting = useRef<boolean>(false);
  const chartPingArray = useRef([]);

  const updatechartPingArray = (ping) => {
    const currentTime = Date.now();
    chartPingArray.current.push({ ping, time: currentTime });

    // Remove old entries (older than 60 seconds)
    const thirtySecondsAgo = currentTime - 60000;
    chartPingArray.current = chartPingArray.current.filter(entry => entry.time >= thirtySecondsAgo);

    // Calculate average
    const totalPing = chartPingArray.current.reduce((acc, entry) => acc + entry.ping, 0);
    const averagePing = chartPingArray.current.length > 0 ? Math.round(totalPing / chartPingArray.current.length) : 0;
    setChartPing(averagePing);
  };

  useEffect(() => {
    const pingServer = () => {
      if (!chartRequesting.current) {
        try {
          chartRequesting.current = true;
          chartRequestTime.current = new Date().getTime();
          getChartsServerTime().then(() => {
            const ping = Date.now() - chartRequestTime.current;
            updatechartPingArray(ping);
            chartRequesting.current = false;
          }).catch(() => {
            chartRequesting.current = false;
          });
        } catch (error) {
          chartRequesting.current = false;
          console.error('Error while fetching server time:', error);
        }
      } else {
        const newPing = Date.now() - chartRequestTime.current;
        updatechartPingArray(newPing);
      }
    };


    const pingInterval = setInterval(pingServer, 5000);

    return () => clearInterval(pingInterval);
  }, []);

  const getPingColor = (ping: number) => {
    if (ping < 250) {
      return '#6abd74';
    } else if (ping < 400) {
      return '#ced372';
    } else {
      return '#d37572';
    }
  };

  const renderSpeed = () => {
    switch (speed) {
      case 0:
        return 'Fast';
      case 1:
        return 'Medium';
      case 2:
        return 'Slow';
      default:
        return 'Medium';
    }
  }

  return useMemo(() => (
    <div style={{ display: 'flex', alignItems: 'center', marginRight: '1em' }}>
      <Tooltip title='Speed at which data updates (Charts, DOM, etc). Adjust in System Settings.'>
        <div style={{ display: 'flex', marginRight: '.5em', justifyContent: 'center', fontSize: '.8em', color: '#b1b1b1' }}>
          Refresh Rate: {renderSpeed()}
        </div>
      </Tooltip>
      <div className={classNames(styles.ping)}>
        <Tooltip title='Trading API connection status.'>
          <div style={{ color: getPingColor(ping), display: 'flex', marginRight: '.5em', alignItems: 'center' }}>
            <div><FontAwesomeIcon icon={faWifi} /></div>
          </div>
        </Tooltip>

        <Tooltip title='Chart & Data API connection status.'>
          <div style={{ color: getPingColor(ping), display: 'flex', alignItems: 'center' }}>
            <div><FontAwesomeIcon icon={faChartSimple} /></div>
          </div>
        </Tooltip>
      </div>
    </div>
  ), [ping, chartPing, speed]);
};

export default React.memo(Ping);
