import React, { useCallback, useEffect, useMemo, useState } from 'react';
import styles from './mobileWebChartTrade.module.scss';
import { useSymbol } from '../../../../contexts/SymbolContext';
import { OrderPromptType, useOrders, calculatePnl } from '../../../../contexts/OrdersContext';
import { Box, MenuItem, TextField } from '@mui/material';
import { OrderType, SymbolMetadata } from '../../../../api/userApi';
import { roundToNearestTick, roundToTickSize } from 'src/helpers/decimalHelper';
import ContractSelector from '@/components/topstep/contractSelector';
import { LinkedColor } from '@/contexts/LinkedContext';
import CommonOrderOptions from '@/components/topstep/commonOrderOptions';
import { QuoteData } from '@/models/quoteData';
import { useCqg } from '@/contexts/CqgContext';
import { useSettings } from '@contexts/SettingsContext';
import { TsModal } from '@components/modal';
import DomHeaderSettings from '@components/domHeaderSettings';
import AccountSelector from '../../../../components/topstep/accountSelector';
import { StyledButton } from '@/components/styledComponents';
import { ContractPriceSpan } from '@/components/numbers';
import { formatContractPrice } from '@/helpers/formatter';
import { useDeviceContext } from '@/contexts/DeviceContext';
import FractionPriceInput from '@/views/trader/components/ordercard/fractionPriceInput';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAnglesDown, faCircleDown, faTimes } from '@fortawesome/pro-solid-svg-icons';
import { useModal } from '@/contexts/ModalContext';
import ConfirmOrderModal from '@/components/topstep/confirmOrderModal';

interface MobileWebChartProps {
  orderAmount: number;
  minimize: () => void;
  contract: SymbolMetadata;
  onSetContract?: (symbol: SymbolMetadata) => void;
  onSetOrderAmount?: (amount: number) => void;
}

const Styles = {
  contractStyle: {
    marginLeft: '0',
    marginRight: '0'
  }
};
const MobileWebChartTrade: React.FC<MobileWebChartProps> = ({ contract, orderAmount, minimize, onSetContract, onSetOrderAmount }: MobileWebChartProps): JSX.Element => {
  const { isMobile } = useDeviceContext();
  const { placeOrderWithSymbol, activePositions, editSltpSetting } = useOrders();
  const { subscribeQuotesForSymbol, unsubscribeQuotesForSymbol } = useCqg();
  const [limitPrice, setLimitPrice] = useState<number | null>(null);
  const [stopPrice, setStopPrice] = useState<number | null>(null);
  const [fixedLimitPrice, setFixedLimitPrice] = useState<number | null>(null);
  const [fixedStopPrice, setFixedStopPrice] = useState<number | null>(null);
  const [trailDistance, setTrailDistance] = useState<number | null>(null);
  const [orderType, setOrderType] = useState<OrderType>(OrderType.Market);
  const [prevLastPrice, setPrevLastPrice] = useState<number | null>(null); // Keep track of previous price
  const [showFlash, setShowFlash] = useState<string>();
  const [quoteData, setQuoteData] = useState<QuoteData>(undefined);
  const [domSettingsOpen, setDomSettingsOpen] = useState<boolean>(false);
  const { showModal, hideModal } = useModal();
  const { showConfirmations: confirmation } = useSettings();

  const handleSetContract = (symbol: SymbolMetadata) => {
    if (onSetContract) {
      onSetContract(symbol);
    }
  };  

  // Set order amount to local storage for persistence
  useEffect(() => {
    if (!isMobile) return;

    localStorage.setItem('mobile-order-amount', orderAmount.toString());
  }, [orderAmount, isMobile]);

  const { customSettings } = useSettings();

  const activePosition = useMemo(() => {
    const activePosList = activePositions.filter((y) => y.symbolId == contract.symbol);
    return activePosList.length > 0 ? activePosList[0] : null;
  }, [activePositions, contract]);

  useEffect(() => {
    let initialQuote = true;
    const id = subscribeQuotesForSymbol(contract.symbol, (quote) => {
      setQuoteData((prev) => {
        if (initialQuote) {
          setLimitPrice(quote.lastPrice);
          setStopPrice(quote.lastPrice);
          setFixedLimitPrice(quote.lastPrice);
          setFixedStopPrice(quote.lastPrice);

          initialQuote = false;
        }
        return {
          ...prev,
          ...quote
        };
      });
    });

    return () => {
      setQuoteData(undefined);
      unsubscribeQuotesForSymbol(contract.symbol, id);
    };
  }, [contract]);

  const positionEntryPrice = useMemo(() => {
    if (!activePosition) return 0;
    return roundToNearestTick(activePosition.averagePrice, contract);
  }, [activePosition?.averagePrice, contract]);

  const breakEven = useCallback(() => {
    if (activePosition) {
      editSltpSetting(activePosition.id, positionEntryPrice, activePosition.takeProfit);
    }
  }, [activePosition, positionEntryPrice, activePosition?.id, activePosition?.takeProfit]);

  const handleSell = useCallback(async () => {
    const sellCallback = async () => {
      hideModal();
      await placeOrderWithSymbol({
        amount: orderAmount,
        symbol: contract.symbol,
        orderType: OrderPromptType.Sell,
        limitPrice: fixedLimitPrice,
        stopPrice: fixedStopPrice,
        trailDistance: trailDistance,
        type: orderType
      });
      minimize();
    };

    if (confirmation){
      showModal(<ConfirmOrderModal limitPrice={limitPrice} stopPrice={stopPrice} trailDistance={trailDistance} contract={contract} orderAmount={orderAmount} type={orderType} side={OrderPromptType.Sell} onConfirm={sellCallback} />);
    } else {
      sellCallback();
    }
  }, [fixedLimitPrice, fixedStopPrice, orderAmount, placeOrderWithSymbol, contract, trailDistance, orderType]);

  const handleBuy = useCallback(async () => {
    const buyCallback = async () => {
      hideModal();
      await placeOrderWithSymbol({
        amount: orderAmount,
        symbol: contract.symbol,
        orderType: OrderPromptType.Buy,
        limitPrice: fixedLimitPrice,
        stopPrice: fixedStopPrice,
        trailDistance: trailDistance,
        type: orderType
      });
      minimize();
    };

    if (confirmation){
      showModal(<ConfirmOrderModal contract={contract} limitPrice={limitPrice} stopPrice={stopPrice} trailDistance={trailDistance} orderAmount={orderAmount} type={orderType} side={OrderPromptType.Buy} onConfirm={buyCallback} />);
    } else {
      buyCallback();
    }
  }, [fixedLimitPrice, fixedStopPrice, orderAmount, placeOrderWithSymbol, contract, trailDistance, orderType]);

  const updateTrailDistance = useCallback((distance?: number) => {
    if (distance == null) {
      setTrailDistance(null);
      return;
    }

    if (distance < 1) {
      distance = 1;
    } else if (distance > 1000) {
      distance = 1000;
    }
    setTrailDistance(distance);
  }, []);

  const updateFixedLimitPrice = useCallback(() => {
    if (limitPrice) {
      const roundedPrice = roundToTickSize(limitPrice, contract.tickSize);
      setLimitPrice(roundedPrice);
      setFixedLimitPrice(roundedPrice);
    } else {
      setFixedLimitPrice(null);
      setLimitPrice(null);
    }
  }, [contract, limitPrice]);

  const updateFixedStopPrice = useCallback(() => {
    if (stopPrice) {
      const roundedPrice = roundToTickSize(stopPrice, contract.tickSize);
      setFixedStopPrice(roundedPrice);
      setStopPrice(roundedPrice);
    } else {
      setFixedStopPrice(null);
      setStopPrice(null);
    }
  }, [contract, stopPrice]);

  const priceText = useMemo(() => {
    let price = 0;
    switch (orderType) {
      case OrderType.Market:
        price = quoteData?.lastPrice ?? 0;
        break;
      case OrderType.Limit:
        if (limitPrice === null || limitPrice == undefined) {
          return '@ ???';
        }
        price = roundToTickSize(limitPrice, contract.tickSize);
        break;
      case OrderType.Stop:
        if (stopPrice === null || stopPrice === undefined) {
          return '@ ???';
        }
        price = roundToTickSize(stopPrice, contract.tickSize);
        break;
      default:
        price = quoteData?.lastPrice ?? 0;
        break;
    }

    if (orderType == OrderType.Market) {
      return '@ Market';
    } else if (orderType == OrderType.TrailingStop) {
      return 'Trailing Stop';
    } else {
      return '@ ' + formatContractPrice(price, contract);
    }
  }, [stopPrice, trailDistance, orderType, quoteData, limitPrice, contract.tickSize]);

  const showPnl = () => {
    if (quoteData?.lastPrice === undefined) return 0;
    return calculatePnl(activePosition.positionSize, activePosition.averagePrice, quoteData.lastPrice, contract.contractCost);
  };

  // Compare askPrice with the previous value and trigger flashing effect
  useEffect(() => {
    let timerId;

    if (prevLastPrice !== null && quoteData?.bestAsk !== null && quoteData?.lastPrice != null) {
      if (quoteData.lastPrice > prevLastPrice) {
        timerId = setTimeout(() => {
          setShowFlash('#43984d');
          setTimeout(() => {
            setShowFlash('');
          }, 1000);
        }, 0);
      } else if (quoteData.lastPrice < prevLastPrice) {
        timerId = setTimeout(() => {
          setShowFlash('#bd3f3b');
          setTimeout(() => {
            setShowFlash('');
          }, 1000);
        }, 0);
      }
    }
    if (quoteData?.lastPrice) setPrevLastPrice(quoteData.lastPrice);
    return () => clearTimeout(timerId);
  }, [quoteData?.lastPrice, quoteData?.bestAsk]);

  const canPlaceOrder = useMemo(() => {
    if (orderType === OrderType.Limit && limitPrice === null) {
      return false;
    }

    if (orderType === OrderType.Stop && stopPrice === null) {
      return false;
    }

    if (orderType === OrderType.TrailingStop && trailDistance === null) {
      return false;
    }

    return true;
  }, [limitPrice, stopPrice, orderType, trailDistance]);

  const flashStyle = {
    transition: 'color 0.1s ease-in-out'
  };

  const customFontSize = useMemo(() => {
    if (customSettings.domFontSize == 0) {
      return { fontSize: 10 };
    } else if (customSettings.domFontSize == 1) {
      return { fontSize: 12 };
    } else if (customSettings.domFontSize == 2) {
      return { fontSize: 14 };
    } else {
      return { fontSize: 12 };
    }
  }, [customSettings.domFontSize]);

  const contractSelector = useMemo(() => {
    return <Box sx={{ flex: 1 }}>
      <ContractSelector contract={contract} style={Styles.contractStyle} setContract={handleSetContract} linkedColor={null} />
    </Box>
  }, [contract]);

  return (
    <div style={{ display: 'flex', flexDirection: 'column', height: '100%', width: '100%', justifyContent: 'center', alignItems: 'center' }}>
      <div className={styles.order} style={{ background: isMobile && '#1c1e23' }}>
        <Box sx={{ display: 'flex', justifyContent: 'right', width: '100%' }}>
          <StyledButton sx={{ minWidth: '3em', padding: '0.5em' }} onClick={minimize} color='error'>
            <FontAwesomeIcon icon={faTimes} />
          </StyledButton>
        </Box>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            padding: '0.1em 0',
            alignContent: 'center',
            justifyItems: 'center',
            alignItems: 'center'
          }}
        >
          {contractSelector}
        </div>
        <Box>
          <TextField size='small' fullWidth onChange={(e) => setOrderType(parseInt(e.target.value) as OrderType)} value={orderType} select label='Order Type'>
            <MenuItem value={OrderType.Market} key={OrderType.Market} style={{ overflow: 'hidden', textOverflow: 'ellipsis' }}>
              Market
            </MenuItem>
            <MenuItem value={OrderType.Limit} key={OrderType.Limit} style={{ overflow: 'hidden', textOverflow: 'ellipsis' }}>
              Limit
            </MenuItem>
            <MenuItem value={OrderType.Stop} key={OrderType.Stop} style={{ overflow: 'hidden', textOverflow: 'ellipsis' }}>
              Stop Market
            </MenuItem>
            <MenuItem value={OrderType.TrailingStop} key={OrderType.TrailingStop} style={{ overflow: 'hidden', textOverflow: 'ellipsis' }}>
              Trailing Stop
            </MenuItem>
          </TextField>
        </Box>
        {orderType == OrderType.Limit && (
          <Box sx={{ marginTop: '1em' }}>
            {contract.fractionalPrice && <FractionPriceInput contract={contract} value={limitPrice} onChange={(e) => setLimitPrice(e)} onBlur={updateFixedLimitPrice}></FractionPriceInput>}
            {!contract.fractionalPrice && (
              <TextField
                label='Limit Price'
                fullWidth
                required
                variant='outlined'
                size='small'
                value={limitPrice || ''}
                type='number'
                InputProps={{ inputProps: { min: 0, step: contract.tickSize + '' } }}
                onChange={(e) => setLimitPrice(e.target.value ? parseFloat(e.target.value) : null)}
                onBlur={updateFixedLimitPrice}
              />
            )}
          </Box>
        )}
        {orderType == OrderType.Stop && (
          <Box sx={{ marginTop: '1em' }}>
            {contract.fractionalPrice && <FractionPriceInput contract={contract} value={stopPrice} onChange={(e) => setStopPrice(e)} onBlur={updateFixedStopPrice}></FractionPriceInput>}
            {!contract.fractionalPrice && (
              <TextField
                label='Stop Price'
                fullWidth
                required
                variant='outlined'
                size='small'
                value={stopPrice || ''}
                type='number'
                InputProps={{ inputProps: { min: 0, step: contract.tickSize + '' } }}
                onChange={(e) => setStopPrice(e.target.value ? parseFloat(e.target.value) : null)}
                onBlur={updateFixedStopPrice}
              />
            )}
          </Box>
        )}
        {orderType == OrderType.TrailingStop && (
          <Box sx={{ marginTop: '1em' }}>
            <TextField
              label='Trail Distance (Ticks)'
              fullWidth
              required
              size='small'
              variant='outlined'
              value={trailDistance || ''}
              type='number'
              InputProps={{ inputProps: { min: 1, max: 1000, step: 1 } }}
              onChange={(e) => updateTrailDistance(e.target.value ? parseInt(e.target.value) : null)}
            />
          </Box>
        )}

        <Box style={{ marginTop: '1em' }}>
          <TextField
            label='# of Contracts'
            fullWidth
            variant='outlined'
            size='small'
            value={orderAmount}
            type='number'
            InputProps={{ inputProps: { min: 1, step: '1' } }}
            onChange={(v) => onSetOrderAmount(parseInt(v.target.value) || 1)}
          />
        </Box>
        {orderType == OrderType.Market && (
          <Box style={{ fontSize: customFontSize.fontSize }}>
            <div className={styles.marketOrderPriceContainer}>
              <div className={styles.marketOrderPriceContainerBB}>
                Bid: <ContractPriceSpan contract={contract}>{quoteData?.bestBid ?? 0}</ContractPriceSpan>
              </div>
              <div style={{ display: 'block', marginLeft: 5, marginRight: 5 }}>
                <div className={styles.marketOrderPriceContainerLP} style={{ color: showFlash, ...flashStyle }}>
                  <ContractPriceSpan contract={contract}>{quoteData?.lastPrice ?? 0}</ContractPriceSpan>
                </div>
                <div className={styles.marketOrderPriceContainerSpread}>
                  <ContractPriceSpan contract={contract}>{(quoteData?.bestAsk ?? 0) - (quoteData?.bestBid ?? 0)}</ContractPriceSpan>
                </div>
              </div>
              <div className={styles.marketOrderPriceContainerBA}>
                Ask: <ContractPriceSpan contract={contract}>{quoteData?.bestAsk ?? 0}</ContractPriceSpan>
              </div>
            </div>
          </Box>
        )}
        <Box
          style={{
            justifyContent: 'center',
            display: 'flex',
            alignItems: 'center',
            fontSize: customFontSize.fontSize
          }}
        >
          <div
            style={{
              justifyContent: 'center',
              display: 'block',
              flexWrap: 'wrap',
              alignItems: 'center',
              textAlign: 'center',
              margin: '0.25em 0'
            }}
          >
            {activePosition && activePosition.positionSize != 0 && (
              <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                <div style={{ marginRight: 10 }}>
                  {activePosition.positionSize > 0 ? '+' + activePosition.positionSize : activePosition.positionSize} @{' '}
                  <ContractPriceSpan contract={contract}>{activePosition.averagePrice}</ContractPriceSpan>
                </div>
                <div style={{ marginRight: 10 }}>
                  <span style={{ color: showPnl() < 0 ? '#bd3f3b' : '#43984d' }}>UP&L ${showPnl().toFixed(2)}</span>
                </div>
                <div style={{ marginRight: 10 }}>
                  <StyledButton color='neutral' disabled={showPnl() < 0} onClick={breakEven} sx={{ padding: '2px 4px', margin: '4px', fontSize: '.9em', minWidth: 20 }}>
                    B/E
                  </StyledButton>
                </div>
              </div>
            )}
            {(!activePosition || activePosition.positionSize == 0) && <div>No Active Position</div>}
          </div>
        </Box>

        <CommonOrderOptions
          contract={contract}
          onBuy={handleBuy}
          onSell={handleSell}
          setSize={onSetOrderAmount}
          size={orderAmount}
          priceText={priceText}
          hideJoinButton={false}
          disableBuySell={!canPlaceOrder}
          hideCancelAll={true}
          hideCancelOrders={false}
          hideClosePosition={false}
          hideFlattenAll={false}
          hideReversePosition={true}
          onAction={minimize}
        />
      </div>
    </div>
  );
};

export default React.memo(MobileWebChartTrade);
