import React, { PropsWithChildren, useEffect } from 'react';
import 'react-loading-skeleton/dist/skeleton.css';
import { Outlet, useNavigate } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';
import Loading from '../components/Loading';
import { NavRoutes } from '../data/NavRoutes';
import { Navbar } from './Navbar';
import { Sidebar } from './Sidebar';
import { Footer } from './Footer';
import TradingAccountContextProvider from '../contexts/TradingAccountContext';
import CqgProvider from '../contexts/CqgContext';
import LayoutsContextProvider from '../contexts/LayoutsContext';
import SymbolContextProvider from '../contexts/SymbolContext';
import OrderContextProvider from '../contexts/OrdersContext';
import TiltContextProvider from '../contexts/TiltContext';
import { Column, Page } from './LayoutComponents';
import { Box, styled } from '@mui/material';
import SettingsContextProvider from '@contexts/SettingsContext';
import MarketStatusContextProvider from '@contexts/MarketStatusContext';
import LinkedContextProvider from '@/contexts/LinkedContext';
import UserNotificationsContextProvider from '@/contexts/UserNotificationsContext';
import { NotificationContainer } from '@components/common/react-notifications';
import ModalContextProvider from '@contexts/ModalContext';
import { useDeviceContext } from '@/contexts/DeviceContext';

export const SharedProviders: React.FC<PropsWithChildren> = ({ children }): JSX.Element => {
    return (
        <SymbolContextProvider>
            <ModalContextProvider>
                <TiltContextProvider>{children}</TiltContextProvider>
            </ModalContextProvider>
        </SymbolContextProvider>
    );
  };

const SharedLayout: React.FC = (): JSX.Element => {

    return (
            <SharedProviders>
            <Page>
                <Box sx={{ display: 'flex', flexDirection: 'column', flex: 1, minHeight: 0 }}>
                    <Outlet />
                    <Footer />
                </Box>
            </Page>
        </SharedProviders>
    );
};

export default SharedLayout;