import React, { useMemo } from 'react';
import styles from './balance.module.scss';
import { formatPrice } from '../../helpers/formatter';
import { useOrders } from '../../contexts/OrdersContext';
import { useTradingAccount } from '../../contexts/TradingAccountContext';
import { LinearProgress, Tooltip, styled, linearProgressClasses } from '@mui/material';
import { useSettings } from '@contexts/SettingsContext';
import { PersonalDailyAction } from '@/api/userApi';
import { useDeviceContext } from '@/contexts/DeviceContext';

type BalanceProps = {
  padding?: number;
  fontSize?: number;
  variant?: 'default' | 'dark';
};

const DailyLoss: React.FC<BalanceProps> = (props: BalanceProps): JSX.Element => {
  const { activeTradingAccount } = useTradingAccount();
  const { activePositions } = useOrders();
  const { customSettings } = useSettings();
  const { isMobile } = useDeviceContext();

  const dll = useMemo(() => {
    if (activeTradingAccount.personalDailyLossLimitAction !== PersonalDailyAction.None) {
      return activeTradingAccount.personalDailyLossLimit;
    }
    return activeTradingAccount.dailyLoss;
  }, [activeTradingAccount.personalDailyLossLimit, activeTradingAccount.dailyLoss, activeTradingAccount.personalDailyLossLimitAction]);

  const unrealizedPnl = useMemo(() => {
    return activePositions.reduce((acc, pos) => {
      return acc + pos.profitAndLoss;
    }, 0);
  }, [activeTradingAccount, activePositions]);

  const lossAmount = useMemo(() => {
    const totalDayPnl = (activeTradingAccount.realizedDayPnl || 0) + unrealizedPnl;
    if (totalDayPnl >= 0) return 0;
    return totalDayPnl * -1;
  }, [activeTradingAccount.realizedDayPnl, unrealizedPnl]);

  const lossPercent = useMemo(() => {
    if (lossAmount == 0) return 0;
    if (dll == 0) return 0;

    return Math.min(100, (lossAmount / dll) * 100);
  }, [dll, lossAmount]);

  const dllOrPdll = useMemo(() => {
    if (activeTradingAccount.personalDailyLossLimitAction !== PersonalDailyAction.None) {
      return 'PDLL';
    }
    return 'DLL';
  }, [activeTradingAccount.personalDailyLossLimit, activeTradingAccount.personalDailyLossLimitAction]);

  const dllOrPdllTooltip = useMemo(() => {
    if (activeTradingAccount.personalDailyLossLimitAction !== PersonalDailyAction.None) {
      return 'Personal Daily Loss Limit: Your custom personal daily loss limit.';
    }
    return 'Daily Loss Limit: The maximum amount an account can lose in a single day.';
  }, [activeTradingAccount.personalDailyLossLimit, activeTradingAccount.personalDailyLossLimitAction]);

  const renderFontSize = useMemo(() => {
    switch (customSettings.topNavTextSize) {
      case 0:
        return '12px';
      case 1:
        return '14px';
      case 2:
        return '16px';
      default:
        return '12px';
    }
  }, [customSettings.topNavTextSize]);

  const backgroundColor = useMemo(() => {
    switch (props.variant) {
      case 'dark':
        return '#1c1e23';
      default:
        return '#2a292f';
    }
  }, [props.variant]);

  return useMemo(
    () =>
      (dll > 0 && (
        <div className={isMobile ? styles.dllMobile : styles.dll} style={{ padding: props.padding, backgroundColor: backgroundColor }}>
          <Tooltip title={dllOrPdllTooltip}>
            <div className={styles.balanceAmount} style={{ display: 'flex', alignItems: 'center', fontSize: props.fontSize ?? renderFontSize }}>
              <span>{dllOrPdll}:</span>
              {!customSettings.streamerModeDLL ? (
                <>
                  <span className={styles.balanceAmount}>${formatPrice(lossAmount)}</span>
                  <BorderLinearProgress variant='determinate' value={lossPercent} />
                  <span className={styles.balanceAmount}>${formatPrice(dll)}</span>
                </>
              ) : (
                <span> ******</span>
              )}
            </div>
          </Tooltip>
        </div>
      )) ||
      null,
    [lossAmount, dll, lossPercent, customSettings.streamerModeDLL, customSettings.topNavTextSize, dllOrPdllTooltip, isMobile]
  );
};

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 10,
  width: '6em',
  borderRadius: 5,
  marginLeft: '0.5em',
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: theme.palette.grey[theme.palette.mode === 'light' ? 200 : 800]
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: theme.palette.red
  }
}));

export default React.memo(DailyLoss);
