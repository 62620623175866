import { faQuestionCircle } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Tooltip } from '@mui/material';
import React from 'react';
import { useDeviceContext } from '@/contexts/DeviceContext';

interface HelpMessageProps {
  message: string;
  style?: React.CSSProperties;
}
const HelpMessage: React.FC<HelpMessageProps> = ({ message, style }) => {
  const { isMobile } = useDeviceContext();
  return (
    <Tooltip title={message} enterTouchDelay={isMobile ? 0 : 700}>
      <FontAwesomeIcon style={{ cursor: 'pointer', marginLeft: '0.5em', ...style }} icon={faQuestionCircle}></FontAwesomeIcon>
    </Tooltip>
  );
};

export default HelpMessage;
