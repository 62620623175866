import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import Button, { ButtonType } from '../topstep/button';
import styles from './supportResources.module.scss';
import { StyledButton } from '@/components/styledComponents';
import { TradingPlatforms, config } from '@/config';

type Props = {};

type SupportResourcesProps = {
  selectView: (view: string) => void;
  selectedView: string;
};

var wnd = window as any;

const SupportResources: React.FC<SupportResourcesProps> = ({ selectView, selectedView }: SupportResourcesProps): JSX.Element => {
  const startLiveChat = useCallback(() => {
    if (wnd.Intercom) wnd.Intercom('show');
  }, []);

  return (
    <div className={styles.support}>
      {!!config.intercomId && (
        <StyledButton color='info' className={styles.liveChatButton} onClick={startLiveChat}>
          Live Chat
        </StyledButton>
      )}
      <StyledButton color='neutral' className={styles.supportButton} style={{ marginTop: '1em', background: selectedView === 'featurebase' && '#2b2b2b' }} onClick={() => selectView('featurebase')}>
        Feature Voting
      </StyledButton>
      {config.platform == TradingPlatforms.TopstepX && (
        <StyledButton color='neutral' className={styles.supportButton} style={{ marginTop: '1em', background: selectedView === '5min' && '#2b2b2b' }} onClick={() => selectView('5min')}>
          {config.company} 5 Min Tutorials
        </StyledButton>
      )}
      <StyledButton color='neutral' className={styles.supportButton} style={{ marginTop: '1em' }} onClick={() => window.open('https://help.projectx.com', '_blank')}>
        Platform Help Center
      </StyledButton>
      {config.supportLinks.supportResources && (
        <StyledButton color='neutral' className={styles.supportButton} style={{ marginTop: '1em' }} onClick={() => window.open(config.supportLinks.supportResources, '_blank')}>
          {config.company} Support Resources
        </StyledButton>
      )}
      {config.supportLinks.helpCenter && (
        <StyledButton color='neutral' className={styles.supportButton} style={{ marginTop: '1em' }} onClick={() => window.open(config.supportLinks.helpCenter, '_blank')}>
          {config.company} Help Center
        </StyledButton>
      )}
      {config.supportLinks.discord && (
        <StyledButton color='neutral' className={styles.supportButton} style={{ marginTop: '1em' }} onClick={() => window.open(config.supportLinks.discord, '_blank')}>
          {config.company} Discord
        </StyledButton>
      )}
    </div>
  );
};

export default SupportResources;
