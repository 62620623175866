import { useApi } from '@/contexts/ApiContext';
import React, { useCallback, useEffect } from 'react';
import { useQuill } from 'react-quilljs';
import 'quill/dist/quill.snow.css';
import { toast } from 'react-toastify';
import QuillImageDropAndPaste from 'quill-image-drop-and-paste'

type JournalEditorProps = {
  initialValue?: string;
  onChange?: (value: string) => void;
};

const JournalEditor = (props: JournalEditorProps) => {
  const { userDataUploadApi } = useApi();

  const wrapperRef = React.useRef<HTMLDivElement>(null);

  const dropPasteHandler = (imageDataUrl, type, imageData) => {
    const file = imageData.toFile()

    // make sure it's an image
    if (file.type.indexOf('image') === -1) {
      toast.error('File must be an image');
      return false;
    }

    // ensure file is less than 5MB
    if (file.size > 5 * 1024 * 1024) {
      toast.error('Image must be < 5MB');
      return false;
    }

    saveToServer(file);
  };


  const { quill, quillRef, Quill } = useQuill({
    modules: {
      imageDropAndPaste: {
        // add an custom image handler
        handler: dropPasteHandler,
      },
    }
  });

  if (Quill && !quill) {
    // Register the custom module with Quill
    Quill.register('modules/imageDropAndPaste', QuillImageDropAndPaste);
  }

  useEffect(() => {
    if (quill) {
      quill.clipboard.dangerouslyPasteHTML(props.initialValue ?? '');
      (wrapperRef as any).quill = quill;
    }
  }, [quill]);

  useEffect(() => {
    if (quill) {
      quill.on('text-change', (delta, oldDelta, source) => {
        if (props.onChange) {
          props.onChange(quill.root.innerHTML);
        }
      });
    }
  }, [quill]);

  // Insert Image(selected by user) to quill
  const insertToEditor = useCallback((url) => {

    let q = quill;
    if (!q) q = (wrapperRef as any).quill;

    let index = (q.getSelection() || {}).index
    if (index === undefined || index < 0) index = q.getLength()
    q.insertEmbed(index, 'image', url);
  }, [quill]);

  // Upload Image to Image Server such as AWS S3, Cloudinary, Cloud Storage, etc..
  const saveToServer = async (file: File) => {

    const promise = new Promise<void>((resolve, reject) => {
      userDataUploadApi
        .uploadUserData(file.type, file.name, [], file.size, file.name, file.name, {
          data: file,
          fileName: file.name
        })
        .then((response) => {
          if (response.success) {
            insertToEditor(response.url);
            resolve();
          } else {
            reject();
            console.error('Error uploading file', response.errorMessage);
          }
        })
        .catch((error) => {
          reject();
          console.error('Error uploading file', error);
        });
    });

    toast.promise(promise, {
      pending: 'Uploading image...',
      success: 'Image uploaded',
      error : {render({data}){
        // When the promise reject, data will contain the error
        return <span>{data?.toString() ?? 'Error uploading image'}</span>
      }}
    });
  };

  // Open Dialog to select Image File
  const selectLocalImage = () => {
    const input = document.createElement('input');
    input.setAttribute('type', 'file');
    input.setAttribute('accept', 'image/*');
    input.click();

    input.onchange = (event: any) => {
      const file = input.files[0];

      // make sure it's an image
      if (file.type.indexOf('image') === -1) {
        toast.error('File must be an image');
        return false;
      }

      // ensure file is less than 5MB
      if (file.size > 5 * 1024 * 1024) {
        toast.error('Image must be < 5MB');
        return false;
      }

      saveToServer(file);
    };
  };

  React.useEffect(() => {
    if (quill) {
      // Add custom handler for Image Upload
      (quill.getModule('toolbar') as any).addHandler('image', selectLocalImage);
      quill.getModule
    }
  }, [quill]);

  return (
    <div ref={wrapperRef} style={{ width: '100%', height: 400, border: '1px solid lightgray' }}>
      <div ref={quillRef} />
    </div>
  );
};

export default JournalEditor;
