import React, { useMemo, useEffect, useRef, useCallback, useState } from 'react';
import {
  ForgotPasswordClient,
  LayoutsClient,
  LoginClient,
  MetadataClient,
  OrderClient,
  PositionClient,
  RegisterClient,
  TradeClient,
  TradingAccountClient,
  UserAgreementClient,
  UserClient,
  UserSettingsClient,
  MarketStatusClient,
  StatisticsClient,
  SessionClient,
  TiltClient,
  UserNotificationClient,
  ChartDataClient,
  ChartsClient,
  ChartTemplatesClient,
  StudyTemplatesClient,
  DrawingTemplatesClient,
  LineToolsClient,
  ViolationsClient,
  LinkedOrderClient,
  JournalLogClient,
  UserDataUploadClient,
} from '../api/userApi';
import { useAuth } from './AuthContext';
import axios from 'axios';
import config from '../config';
import { StrapiClient, TradeLogClient } from '../api/dataApi';
import FingerprintJS from '@fingerprintjs/fingerprintjs';

export const AuthorizationHeaders = {
  Authorization: localStorage.getItem('token') ?? undefined
};

export interface IApiContext {
  userApi: UserClient;
  agreementsApi: UserAgreementClient;
  userSettingsApi: UserSettingsClient;
  tradingAccountApi: TradingAccountClient;
  orderApi: OrderClient;
  positionApi: PositionClient;
  metadataApi: MetadataClient;
  tradeLogApi: TradeLogClient;
  loginApi: LoginClient;
  layoutApi: LayoutsClient;
  tradeApi: TradeClient;
  registerApi: RegisterClient;
  forgotPasswordApi: ForgotPasswordClient;
  marketStatusApi: MarketStatusClient;
  statisticsApi: StatisticsClient;
  sessionApi: SessionClient;
  tiltApi: TiltClient;
  userNotificationApi: UserNotificationClient;
  chartsApi: ChartsClient;
  chartTemplatesApi: ChartTemplatesClient;
  studyTemplatesApi: StudyTemplatesClient;
  drawingTemplatesApi: DrawingTemplatesClient;
  lineToolsApi: LineToolsClient;
  violationsApi: ViolationsClient;
  linkedOrderApi: LinkedOrderClient;
  journalLogApi: JournalLogClient;
  token: string | null;
  strapiApi: StrapiClient;
  userDataUploadApi: UserDataUploadClient;
  setToken: (token: string) => void;
}

export const ApiContext = React.createContext<IApiContext>({} as IApiContext);
export const useApi = () => React.useContext(ApiContext);

export default function ApiContextProvider({ children }: any) {
  const [token, setToken] = useState<string | null>(new URLSearchParams(window.location.search).get('token') ?? localStorage.getItem('token'));

  const axiosRef = useRef(
    axios.create({
      timeout: 60000,
      headers: !!token ? { Authorization: `Bearer ${token}` } : undefined
    })
  );

  // if token is present in query string, set it
  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const token = urlParams.get('token');
    if (token) {
      // Remove token from query string
      urlParams.delete('token');
      localStorage.setItem('token', token);
      window.history.replaceState({}, '', `${window.location.pathname}?${urlParams}`);
    }

    // check if accountid is also present
    const accountId = urlParams.get('account');
    if (accountId) {
      // Remove accountid from query string
      urlParams.delete('account');
      window.history.replaceState({}, '', `${window.location.pathname}?${urlParams}`);

      // set accountid in local storage
      localStorage.setItem('activeAccount:sim', accountId);
    }
  }, []);

  const updateToken = useCallback((token: string) => {
    try {
      FingerprintJS.load()
        .then((fp) => {
          fp.get().then((result) => {
            axiosRef.current.defaults.headers['x-toeprint'] = result.visitorId;
          });
        })
        .catch((e) => {
          console.error('Toeprint error', e);
        });
    } catch (e) {
      console.error('Toeprint error', e);
    }
    if (!token) {
      axiosRef.current.defaults.headers.Authorization = undefined;
      AuthorizationHeaders.Authorization = undefined;
      localStorage.removeItem('token');
    } else {
      axiosRef.current.defaults.headers.Authorization = `Bearer ${token}`;
      AuthorizationHeaders.Authorization = `Bearer ${token}`;
      localStorage.setItem('token', token);
    }

    // get fingerprint and set x-fingerprint header

    setToken(token);
  }, []);

  useEffect(() => {
    axiosRef.current.interceptors.response.use(
      (response) => {
        return response;
      },
      (error) => {
        if (error?.response?.status === -401) {
          updateToken(null);
        }
        return error;
      }
    );
  }, []);

  const services = useMemo<IApiContext>(() => {
    const axiosInstance = axiosRef.current;
    const data: IApiContext = {
      userApi: new UserClient(config.userApi, axiosInstance),
      strapiApi: new StrapiClient(config.chartApi, axiosInstance),
      positionApi: new PositionClient(config.userApi, axiosInstance),
      orderApi: new OrderClient(config.userApi, axiosInstance),
      metadataApi: new MetadataClient(config.userApi, axiosInstance),
      tradeLogApi: new TradeLogClient(config.chartApi, axiosInstance),
      loginApi: new LoginClient(config.userApi, axiosInstance),
      layoutApi: new LayoutsClient(config.userApi, axiosInstance),
      tradingAccountApi: new TradingAccountClient(config.userApi, axiosInstance),
      registerApi: new RegisterClient(config.userApi, axiosInstance),
      forgotPasswordApi: new ForgotPasswordClient(config.userApi, axiosInstance),
      tradeApi: new TradeClient(config.userApi, axiosInstance),
      userSettingsApi: new UserSettingsClient(config.userApi, axiosInstance),
      marketStatusApi: new MarketStatusClient(config.userApi, axiosInstance),
      agreementsApi: new UserAgreementClient(config.userApi, axiosInstance),
      statisticsApi: new StatisticsClient(config.userApi, axiosInstance),
      sessionApi: new SessionClient(config.userApi, axiosInstance),
      tiltApi: new TiltClient(config.userApi, axiosInstance),
      userNotificationApi: new UserNotificationClient(config.userApi, axiosInstance),
      chartsApi: new ChartsClient(config.userApi, axiosInstance),
      chartTemplatesApi: new ChartTemplatesClient(config.userApi, axiosInstance),
      studyTemplatesApi: new StudyTemplatesClient(config.userApi, axiosInstance),
      drawingTemplatesApi: new DrawingTemplatesClient(config.userApi, axiosInstance),
      lineToolsApi: new LineToolsClient(config.userApi, axiosInstance),
      violationsApi: new ViolationsClient(config.userApi, axiosInstance),
      linkedOrderApi: new LinkedOrderClient(config.userApi, axiosInstance),
      journalLogApi: new JournalLogClient(config.userApi, axiosInstance),
      userDataUploadApi: new UserDataUploadClient(config.userApi, axiosInstance),
      token,
      setToken: updateToken
    };

    return data;
  }, [token]);
  return <ApiContext.Provider value={services}>{children}</ApiContext.Provider>;
}
